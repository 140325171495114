import { get, pick } from 'lodash';
import { EMPTY_OBJECT } from '../../constants/utils';

export const areEntitiesLoading = (state, { entityName }) => get(state, `dbEntities.${entityName}.loading`, false);
export const getAllEntities = (state, { entityName }) => get(state, `dbEntities.${entityName}.data`, EMPTY_OBJECT);
export const getEntity = (state, { entityName, id }) => get(getAllEntities(state, { entityName }), id);

export const getAllLots = state => get(state, 'dbEntities.lots.data', EMPTY_OBJECT);
export const getAllSales = state => get(state, 'dbEntities.sales.data', EMPTY_OBJECT);
export const getAllArtists = state => get(state, 'dbEntities.artists.data', EMPTY_OBJECT);
export const getAllClassifieds = state => get(state, 'dbEntities.classifieds.data', EMPTY_OBJECT);
export const getAllStores = state => get(state, 'dbEntities.stores.data', EMPTY_OBJECT);
export const getAllAuctioneers = state => get(state, 'dbEntities.auctioneers.data', EMPTY_OBJECT);
export const getAllCategories = state => get(state, 'dbEntities.categories.data', EMPTY_OBJECT);
export const getAllTechniques = state => get(state, 'dbEntities.techniques.data', EMPTY_OBJECT);
export const getAllCountries = state => get(state, 'dbEntities.countries.data', EMPTY_OBJECT);
export const getAllLotartistassociationtypes = state => get(state, 'dbEntities.lotartistassociationtypes.data', EMPTY_OBJECT);

export const areSalesLoading = state => get(state, 'dbEntities.sales.loading', false);
export const areArtistsLoading = state => get(state, 'dbEntities.artists.loading', false);
export const isFreeArtistLoading = state => get(state, 'dbEntities.free.artist.loading', false);
export const areCategoriesLoading = state => get(state, 'dbEntities.categories.loading', false);
export const areTechniquesLoading = state => get(state, 'dbEntities.techniques.loading', false);
export const areCountriesLoading = state => get(state, 'dbEntities.countries.loading', false);
export const areLotartistassociationtypesLoading = state => get(state, 'dbEntities.countries.loading', false);
export const areLotsLoading = state => get(state, 'dbEntities.lots.loading', false);
export const isFreeLotLoading = state => get(state, 'dbEntities.free.lot.loading', false);
export const areClassifiedsLoading = state => get(state, 'dbEntities.classifieds.loading', false);

export const getCountries = (state, { ids }) => pick(getAllCountries(state), ids);

export const getIdFreeLot = state => get(state, 'dbEntities.free.lot.id');
export const getLot = (state, { id }) => get(getAllLots(state), id);
export const getClassified = (state, { id }) => get(getAllClassifieds(state), id);
export const getSale = (state, { id }) => get(getAllSales(state), id);
export const getIdFreeArtist = state => get(state, 'dbEntities.free.artist.id');
export const getArtist = (state, { id }) => get(getAllArtists(state), id);
export const getStore = (state, { id }) => get(getAllStores(state), id);
export const getAuctioneer = (state, { id }) => get(getAllAuctioneers(state), id);
export const getCategory = (state, { id }) => get(getAllCategories(state), id);
export const getTechnique = (state, { id }) => get(getAllTechniques(state), id);
export const getCountry = (state, { id }) => get(getAllCountries(state), id);
export const getLotartistassociationtype = (state, { id }) => get(getAllLotartistassociationtypes(state), id);

export const areClassifiedsError = state => get(state, 'dbEntities.classifieds.error', null);

export const getMessage = (state, { id }) => get(getAllEntities(state, { entityName: 'messages' }), id);
export const getAllMessagetype = state => getAllEntities(state, { entityName: 'messagetypes' });
