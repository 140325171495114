import { createNextState } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { CHOOSE_PERIOD, UPDATE } from '../../../actions/marketplace/stores/stats';
import { BEST_ARTISTS, BEST_CLASSIFIEDS, COUNTRY_REPARTITION_KEY, INQUIRIES, TOTAL_COUNT_KEY, PUBLISHED_CLASSIFIEDS, VIEWED_CLASSIFIEDS } from '../../../constants/marketplace/store/stats';

function getInitialState() {
  return {};
}

const authorizedKeys = [TOTAL_COUNT_KEY, COUNTRY_REPARTITION_KEY, BEST_CLASSIFIEDS, BEST_ARTISTS, INQUIRIES, PUBLISHED_CLASSIFIEDS, VIEWED_CLASSIFIEDS];
export default function statsReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case CHOOSE_PERIOD: {
        const { start, end } = payload;
        draft.displayedPeriod = {
          start,
          end,
        };
        break;
      }
      case UPDATE: {
        const { key, value, error, loading } = payload;
        if (!authorizedKeys.includes(key.split('.')[0])) {
          throw new Error(`Unknown marketplace/store/stats key : ${key}`);
        }
        if (value !== undefined) {
          set(draft, `${key}.data`, value);
        }
        if (error !== undefined) {
          set(draft, `${key}.error`, error);
        }
        if (loading !== undefined) {
          set(draft, `${key}.loading`, loading);
        }
        break;
      }
      default:
        return draft;
    }
  });
}
