import { TAB_CA, TAB_SOLD, TAB_UNSOLD, TAB_AVERAGE } from '../../constants/indexes/chronological';
import { internalAxios } from '../../utils/axios/internal';

export const chronologicalDataService = async ({ chartTab, idartist, idcurrency, idcountry, idcategory, minPrice, maxPrice }) => {
  let paramsUrl = '';
  if (idartist) paramsUrl += `idartist=${idartist}&`;
  else console.error('ERROR: idartist =', idartist);

  if (idcurrency) paramsUrl += `idcurrency=${idcurrency}&`;
  else console.error('ERROR: idcurrency =', idcurrency);

  if (idcountry) paramsUrl += `idcountry=${idcountry}&`;
  if (idcategory) paramsUrl += `idcategory=${idcategory}&`;

  if (maxPrice !== undefined) paramsUrl += `max_price=${maxPrice}&`;
  if (minPrice !== undefined) paramsUrl += `min_price=${minPrice}&`;

  paramsUrl += 'group_by=year';

  let apiAction = '';
  switch (chartTab) {
    case TAB_CA:
      apiAction = 'chronological_progression_ca';
      break;
    case TAB_SOLD:
      apiAction = 'chronological_progression_sold';
      break;
    case TAB_UNSOLD:
      apiAction = 'chronological_progression_unsold';
      break;
    case TAB_AVERAGE:
      apiAction = 'chronological_progression_average';
      break;
    default:
      throw new Error(`Unknown chartTab : ${chartTab}`);
  }

  const response = await internalAxios.get(`/api/artdbweb/indexes/${apiAction}?${paramsUrl}`);
  return response.data;
};
