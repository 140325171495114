import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getCurrency } from '../preferences';

export const getArtist = state => state.indexes.artist;

export const getIdartist = state => state.indexes.artist.idartist;

export const isArtistInfosLoading = (state, { idartist = getIdartist(state) } = {}) => get(getArtist(state), `${idartist}.infos.loading`);
export const getArtistInfos = (state, { idartist = getIdartist(state) } = {}) => get(getArtist(state), `${idartist}.infos.data`);

export const getArtistName = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.infos.data.name`));

export const getArtistURL = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.infos.data.url`));

// artist options
export const isArtistOptionsLoading = createSelector(getArtist, getIdartist, getCurrency, (artist, idartist, currency) => get(artist, `${idartist}.${currency}.options.loading`));

export const getArtistOptions = createSelector(getArtist, getIdartist, getCurrency, (artist, idartist, currency) => get(artist, `${idartist}.${currency}.options.data`));

// artist' categories
export const isCategoriesLoading = isArtistOptionsLoading;

export const getCategories = createSelector(getArtistOptions, artistOptions => get(artistOptions, 'categories'));

// artist' countries
export const isCountriesLoading = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.countries.loading`));

export const getCountries = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.countries.data`));

// artist' visible years
// isVariableLoading ou areVariablesLoading ?
export const isVisibleYearsLoading = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.visibleYears.loading`));

export const getVisibleYears = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.visibleYears.data`));

export const getMaxVisibleYear = createSelector(getArtist, getIdartist, (artist, idartist) => {
  const maxYear = get(artist, `${idartist}.visibleYears.data.maxYear`);
  return maxYear ? parseInt(maxYear, 10) + 1 : undefined;
});

// shouldDisplay
export const isDisplayLoading = (state, { idartist = getIdartist(state) } = {}) => get(getArtist(state), `${idartist}.shouldDisplay.loading`);

export const getAllShouldDisplay = (state, { idartist = getIdartist(state) } = {}) => get(getArtist(state), `${idartist}.shouldDisplay.data`);

export const isSubscriptionNeeded = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.shouldDisplay.data.subscriptionNeeded`));

export const hasIndexesSubscription = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.shouldDisplay.data.hasIndexesSubscription`));
