export const FUTURE = 'events/FUTURE';
export const FUTURE_NOT_PARTNER = 'events/FUTURE_NOT_PARTNER';
export const PAST = 'events/PAST';
export const PAST_NOT_PARTNER = 'events/PAST_NOT_PARTNER';
export const HOME_PAGE = 'events/HOME_PAGE';

export const DT_START = 'events/dtStart';
export const DT_EXPIRE = 'events/dtExpire';

export const DEFAULT_COUNTRY_FILTER = 'country#all';
export const DEFAULT_DATE_START_FILTER = 'from#unset';
export const DEFAULT_DATE_EXPIRE_FILTER = 'to#unset';
export const DEFAULT_PAGE_FILTER = 'page#1';
export const DEFAULT_FILTERS = `${DEFAULT_COUNTRY_FILTER}&${DEFAULT_DATE_START_FILTER}&${DEFAULT_DATE_EXPIRE_FILTER}&${DEFAULT_PAGE_FILTER}`;

export const PER_PAGE = 15;
export const NOT_PARTNERS_PER_PAGE = 18;

// La clé c'est nbAuction et nbFocusOn concaténés
// desktopItems Correspondants aux nombre d'élements affichés sur desktop
export const EVENTS_DISPLAY_OPTIONS = {
  '01': { sm: 6, md: 8, desktopItems: 2 },
  '00': { sm: 12, md: 12, desktopItems: 3 },
  11: { md: 4, desktopItems: 1 },
  10: { sm: 6, md: 8, desktopItems: 2 },
  21: { md: 4, desktopItems: 1 },
  20: { sm: 12, md: 4, desktopItems: 1 },
  31: { sm: 6, md: 8, desktopItems: 2 },
  30: { sm: 12, md: 4, desktopItems: 1 },
  41: { sm: 6, md: 8, desktopItems: 2 },
  40: { sm: 12, md: 4, desktopItems: 1 },
  51: { sm: 6, md: 8, desktopItems: 2 },
  50: { sm: 12, md: 4, desktopItems: 1 },
  61: { sm: 6, md: 8, desktopItems: 2 },
  60: { sm: 12, md: 4, desktopItems: 1 },
  71: { sm: 6, md: 8, desktopItems: 2 },
  70: { sm: 12, md: 4, desktopItems: 1 },
  81: { sm: 6, md: 8, desktopItems: 2 },
  80: { sm: 12, md: 4, desktopItems: 1 },
  91: { sm: 6, md: 8, desktopItems: 2 },
  90: { sm: 12, md: 4, desktopItems: 1 },
};
