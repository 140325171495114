import { createNextState } from '@reduxjs/toolkit';
import { set, merge } from 'lodash';
import { UPDATE_SETTINGS_PREFERENCES, FETCH_SETTINGS, UPDATE_CATEGORIES } from '../../actions/myartprice/settings';
import { SHORTCUT_SETTINGS_CATEGORIES, SHORTCUT_SETTINGS_SECTIONS } from '../../../constants/myartprice';

function getInitialState() {
  return {
    // keep informations if want to disable alerts for a short time
    ended: undefined,
    futures: undefined,
    auctions: undefined,
    fixedPrice: undefined,
  };
}

export default function settingsReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_SETTINGS_PREFERENCES: {
        const { section, value, params } = payload;
        if (section === SHORTCUT_SETTINGS_SECTIONS) {
          set(draft, params.section, value);
        } else if (section === SHORTCUT_SETTINGS_CATEGORIES) {
          set(draft, `categories.data.${params.idcategory}`, value);
        }
        break;
      }

      case FETCH_SETTINGS: {
        const { ended, futures, auctions, fixedPrice } = payload;
        draft.ended = ended !== undefined ? ended : draft.ended;
        draft.futures = futures !== undefined ? futures : draft.futures;
        draft.auctions = auctions !== undefined ? auctions : draft.auctions;
        draft.fixedPrice = fixedPrice !== undefined ? fixedPrice : draft.fixedPrice;
        break;
      }

      case UPDATE_CATEGORIES: {
        const { data, loading, error } = payload;
        merge(draft, {
          categories: {
            data,
            loading,
            error,
          },
        });
        break;
      }

      default:
        return draft;
    }
  });
}
