import { createNextState } from '@reduxjs/toolkit';
import { ACCOUNT_MESSAGES, DELETE_FROM_CURRENT_FORLDER, UPDATE_MESSAGE_COUNT } from '../../actions/account/messages';

function getInitialState() {
  return {};
}

export default function switchesReducer(state = getInitialState(), action) {
  return createNextState(state, draft => {
    switch (action.type) {
      case UPDATE_MESSAGE_COUNT: {
        const { count } = action;
        draft.count = parseInt(count, 10);
        break;
      }
      case DELETE_FROM_CURRENT_FORLDER: {
        const { id } = action;
        delete draft.idmessages[id];
        break;
      }
      case ACCOUNT_MESSAGES: {
        const { result, loading, error } = action;
        draft.error = error;
        draft.loading = loading;

        if (result) {
          draft.perPage = parseInt(result.perPage, 10);
          draft.count = parseInt(result.count, 10);
          draft.idmessages = result.idmessages;
        }
        break;
      }
      default:
        return draft;
    }
  });
}
