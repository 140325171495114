import { createSelector } from 'reselect';
import { get } from 'lodash';
import { getAllCategories } from '../dbEntities';

export const getSettings = state => get(state, 'myartprice.settings');
export const areCategoriesLoading = state => get(state, 'myartprice.settings.categories.loading');
export const getCategoriesParams = state => get(state, 'myartprice.settings.categories.data');

export const getParam = (state, { param }) => get(state, `myartprice.settings.${param}`);

export const getCategories = createSelector(getCategoriesParams, getAllCategories, (categoriesParams, allCategories) => {
  if (!categoriesParams || !allCategories) return [];
  const toReturn = [];
  Object.entries(categoriesParams).forEach(([idcategory, setting]) => toReturn.push({ ...allCategories[idcategory], include_in_push: setting }));
  return toReturn;
});
