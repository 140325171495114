export const BEGIN = 'ui/asyncStatus/BEGIN';
export const begin = ({ id }) => ({
  type: BEGIN,
  payload: {
    id,
  },
});

export const END = 'ui/asyncStatus/END';
export const end = ({ id, error }) => ({
  type: END,
  payload: {
    id,
    error,
  },
});
