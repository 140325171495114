/*  eslint-disable */
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getIdartist } from './artist';
import { getCurrency } from '../preferences';

export const createChartKey = ({ chartTab, idcategory = '', minYear, maxYear, minPrice, maxPrice }) =>
  `${chartTab}-${idcategory}-${Number.parseInt(minYear, 10) || ''}-${Number.parseInt(maxYear, 10) || ''}-${Number.parseInt(minPrice, 10) || ''}-${Number.parseInt(maxPrice, 10) || ''}`;

const getGeographical = state => state.indexes.geographical;

export const getGeographicalDisplayedChartTab = state => state.indexes.geographical.displayedChartTab;
export const getGeographicalDisplayedChartType = state => state.indexes.geographical.displayedChartType;
export const getGeographicalOptions = state => state.indexes.geographical.options;

// Price segments data
export const isGeographicalDataLoading = createSelector(
  getGeographical,
  getIdartist,
  getCurrency,
  getGeographicalDisplayedChartTab,
  getGeographicalOptions,
  (geographical, idartist, currency, chartTab, options) => get(geographical, `${idartist}.${currency}.${createChartKey({ chartTab, ...options })}.loading`),
);

export const getGeographicalData = createSelector(
  getGeographical,
  getIdartist,
  getCurrency,
  getGeographicalDisplayedChartTab,
  getGeographicalOptions,
  (geographical, idartist, currency, chartTab, options) => get(geographical, `${idartist}.${currency}.${createChartKey({ chartTab, ...options })}.data`),
);
