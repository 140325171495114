/* eslint-disable function-paren-newline */

import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getIdartist, getArtistOptions, getCategories, getArtist } from './artist';
import { getCurrency } from '../preferences';
import { normalizeData, valueAt } from '../../../utils/indexes/priceIndex';
import { formatFns } from '../../../utils/dates/format';

export const defaultMinYear = 2000;

export const createChartKey = ({ minYear, state }) => {
  if (!minYear && state) {
    const { minYearIndex } = getArtistOptions(state) || {};
    if (minYearIndex) return `${parseInt(minYearIndex, 10) + 1}`;
  }
  return `${minYear || defaultMinYear}`;
};

const getPriceIndex = state => state.indexes.priceIndex;
export const getPriceIndexOptions = state => state.indexes.priceIndex.options;

// should display
export const shouldDisplayPriceIndex = createSelector(getArtist, getIdartist, (artist, idartist) => get(artist, `${idartist}.shouldDisplay.data.priceIndex`));

export const getValuesWithArtistAndCurrency = createSelector(getPriceIndex, getIdartist, getCurrency, (priceIndex, idartist, currency) => get(priceIndex, `${idartist}.${currency}`));

// indexed categories
export const getIndexedCategories = createSelector(getCategories, categories => categories && categories.filter(category => category.isIndexed));

// artist data
export const isArtistIndexDataLoading = createSelector(getValuesWithArtistAndCurrency, artistCurrencyState => get(artistCurrencyState, 'artist.loading'));
export const getArtistIndexData = createSelector(getValuesWithArtistAndCurrency, artistCurrencyState => get(artistCurrencyState, 'artist.data'));

// compared artist
export const isComparedArtistIndexDataLoading = createSelector(getPriceIndex, getPriceIndexOptions, getCurrency, (priceIndex, options, currency) =>
  get(priceIndex, `${options && options.idartist}.${currency}.artist.loading`),
);

export const getComparedArtistIndexData = createSelector(getPriceIndex, getPriceIndexOptions, getCurrency, (priceIndex, options, currency) => get(priceIndex, `${options && options.idartist}.${currency}.artist.data`));

export const getComparedArtistName = createSelector(getArtist, getPriceIndexOptions, (artist, options) => get(artist, `${options && options.idartist}.infos.data.name`));

export const shouldDisplayComparedArtist = createSelector(getArtist, getPriceIndexOptions, (artist, options) => get(artist, `${options && options.idartist}.shouldDisplay.data.subscriptionNeeded`));

// stock index data
export const isStockIndexDataLoading = createSelector(getValuesWithArtistAndCurrency, getPriceIndexOptions, (artistCurrencyState, options) => get(artistCurrencyState, `${options && options.stockIndex}.loading`));
export const getStockIndexData = createSelector(getValuesWithArtistAndCurrency, getPriceIndexOptions, (artistCurrencyState, options) => get(artistCurrencyState, `${options && options.stockIndex}.data`));

// stock index data
export const isCategoryDataLoading = createSelector(getValuesWithArtistAndCurrency, getPriceIndexOptions, (artistCurrencyState, options) =>
  get(artistCurrencyState, `categories.${options && options.idcategory}.loading`),
);
export const getCategoryData = createSelector(getValuesWithArtistAndCurrency, getPriceIndexOptions, (artistCurrencyState, options) => get(artistCurrencyState, `categories.${options && options.idcategory}.data`));

// merged index loading
export const isMergedIndexDataLoading = createSelector(
  isArtistIndexDataLoading,
  isComparedArtistIndexDataLoading,
  isStockIndexDataLoading,
  isCategoryDataLoading,
  (artistLoading, comparedArtistLoading, stockLoading, categoryLoading) => artistLoading || comparedArtistLoading || stockLoading || categoryLoading,
);
export const getMergedData = createSelector(
  getPriceIndexOptions,
  getArtistIndexData,
  getComparedArtistIndexData,
  getStockIndexData,
  getCategoryData,
  (options, artistData, comparedArtistData, stockData, categoryData) => {
    if (!Array.isArray(artistData)) {
      return null;
    }

    const normalizedArtistData = normalizeData(artistData, options);
    const normalizedStockIndexData = Array.isArray(stockData) ? normalizeData(stockData, options) : null;
    const normalizedCategoryData = Array.isArray(categoryData) ? normalizeData(categoryData, options) : null;
    const normalizedComparedArtistData = Array.isArray(comparedArtistData) ? normalizeData(comparedArtistData, options) : null;

    return normalizedArtistData.map(item => {
      const toReturn = {
        date: formatFns(new Date(item.date), 'P'),
        artist: Number.parseFloat(item.value.toFixed(2)),
      };

      if (options) {
        const { stockIndex, idcategory, idartist } = options;
        if (stockIndex && normalizedStockIndexData) {
          toReturn.stockIndex = valueAt({
            values: normalizedStockIndexData,
            date: item.date,
          });
        }
        if (idcategory && normalizedCategoryData) {
          toReturn[`category-${idcategory}`] = valueAt({
            values: normalizedCategoryData,
            date: item.date,
          });
        }
        if (idartist && normalizedComparedArtistData) {
          toReturn[`artist-${idartist}`] = valueAt({
            values: normalizedComparedArtistData,
            date: item.date,
          });
        }
      }

      return toReturn;
    });
  },
);
export const getMergedVisibleData = createSelector(
  getPriceIndexOptions,
  getArtistIndexData,
  getComparedArtistIndexData,
  getStockIndexData,
  getCategoryData,
  (options = {}, artistData, comparedArtistData, stockData, categoryData) => ({
    artist: Array.isArray(artistData),
    comparedArtist: Boolean(options.idartist) && Array.isArray(comparedArtistData),
    stockIndex: Boolean(options.stockIndex) && Array.isArray(stockData),
    category: Boolean(options.idcategory) && Array.isArray(categoryData),
  }),
);
