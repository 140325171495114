import { createNextState } from '@reduxjs/toolkit';
import { UPDATE_TOP_STORES } from '../../../actions/marketplace/stores/top';

function getInitialState() {
  return {};
}

export default function topReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_TOP_STORES: {
        const { loading, result, error } = payload;
        draft.loading = loading;
        draft.result = result;
        draft.error = error;
        break;
      }
      default:
        return draft;
    }
  });
}
