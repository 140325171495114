import { internalAxios } from '../../utils/axios/internal';

export const getLists = async ({ sort }) => {
  const response = await internalAxios.get(`/api/wallet/customerlotslist/get.json`, { params: { sort } });
  return response.data;
};

export const createList = async ({ label }) => {
  const response = await internalAxios.post(`/api/wallet/customerlotslist/create.json`, { label });
  return response.data;
};

export const renameList = async ({ id, label }) => {
  const response = await internalAxios.post(`/api/wallet/customerlotslist/rename.json`, { id, label });
  return response.data;
};

export const deleteLists = async ({ ids }) => {
  const response = await internalAxios.post(`/api/wallet/customerlotslist/delete.json`, { ids });
  return response.data;
};

export const addLots = async ({ id, idlots }) => {
  const response = await internalAxios.post(`/api/wallet/customerlotslist/add_lots.json`, { id, idlots });
  return response.data;
};

export const deleteLots = async ({ id, idlots }) => {
  const response = await internalAxios.post(`/api/wallet/customerlotslist/delete_lots.json`, { id, idlots });
  return response.data;
};

export const moveLots = async ({ idfrom, idto, idlots }) => {
  const response = await internalAxios.post(`/api/wallet/customerlotslist/move_lots.json`, { idfrom, idto, idlots });
  return response.data;
};

export const copyLots = async ({ idfrom, idto, idlots }) => {
  const response = await internalAxios.post(`/api/wallet/customerlotslist/copy_lots.json`, { idfrom, idto, idlots });
  return response.data;
};

export const getDataList = async ({ id, sort, page, perPage, paginate = true }) => {
  const response = await internalAxios.get(`/api/wallet/customerlotslist/get_data_list`, { params: { id, sort, page, per_page: perPage, paginate } });
  return response.data;
};

export const getData = async ({ id, idlot }) => {
  const response = await internalAxios.get(`/api/wallet/customerlotslist/get_data`, { params: { id, idlot } });
  return response.data;
};
