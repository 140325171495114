import {
  CLASSIFIEDS,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FUTURE_NEW,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
  STORES,
  MYARTPRICE_MARKETPLACE,
  MARKETPLACE_MANAGE_OFFLINE,
  MARKETPLACE_MANAGE_MODIFICATION_REQUIRED,
  MARKETPLACE_MANAGE_WAITING,
  MARKETPLACE_MANAGE_ONLINE,
  MARKETPLACE_MANAGE_WITH_OFFERS,
  MARKETPLACE_MANAGE_WINNING_AUCTIONS,
  MARKETPLACE_MANAGE_MY_OFFERS,
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  CLASSIFIEDS_IN_STORE,
  MYARTPRICE_STORE,
  AUCTIONEER_FUTURE_SALES,
  AUCTIONEER_PAST_SALES,
  AUCTIONEER_SALE,
  AUCTIONEER_SALE_LOTS,
  MYARTPRICE_LOTS_MARKETPLACE,
} from './scope';

const base = [
  'idartist',
  'idcategory',
  'idtechnique',
  'nbdays',
  'dt_from',
  'dt_to',
  'keyword',
  'limit_keywords_on_title',
  'exact_match',
  'idcountry',
  'idcity',
  'idstate',
  'year_from',
  'year_to',
  'signed',
  'price_from',
  'price_to',
  'price_idcurrency',
  'length',
  'height',
  'width',
  'idlotartistassociationtype',
];

const myartpriceBase = [...base, 'estimation_from', 'estimation_to', 'estimation_idcurrency', 'idsale', 'idlotstatus', 'reproduction', 'indicator'];
const baseAuctioneer = ['idartist', 'idcategory', 'dt_from', 'dt_to', 'keyword', 'exact_match', 'idcountry', 'idcity', 'idstate'];

export const SEARCH_DIMENSIONS = ['cm', 'in'];

export const ACTIVE_SEARCH_PARAMS = {
  [CLASSIFIEDS]: [...base, 'idclassifiedauctionmode', 'terms', 'idstoretype', 'idprofessionaltype', 'price_max', 'price_min', 'shape', 'size', 'in_fav'],
  [STORES]: ['idcountry', 'terms', 'idstoretype', 'idprofessionaltype', 'idartist'],
  [MYARTPRICE_LOTS_FOLLOW_MARKETPLACE]: [...myartpriceBase],
  [MYARTPRICE_LOTS_FOLLOW_FUTURE]: [...myartpriceBase],
  [MYARTPRICE_LOTS_FOLLOW_PAST]: [...myartpriceBase],
  [MYARTPRICE_LOTS_FUTURE_NEW]: [...myartpriceBase],
  [MYARTPRICE_LOTS_FUTURE_ALL]: [...myartpriceBase],
  [MYARTPRICE_LOTS_AUCTIONED_OFF]: [...myartpriceBase],
  [MYARTPRICE_LOTS_MARKETPLACE]: [...myartpriceBase],
  [MYARTPRICE_MARKETPLACE]: [...myartpriceBase],
  [MARKETPLACE_MANAGE_OFFLINE]: [...base],
  [MARKETPLACE_MANAGE_MODIFICATION_REQUIRED]: [...base],
  [MARKETPLACE_MANAGE_WAITING]: [...base],
  [MARKETPLACE_MANAGE_ONLINE]: [...base],
  [MARKETPLACE_MANAGE_WITH_OFFERS]: [...base],
  [MARKETPLACE_MANAGE_MY_OFFERS]: [...base],
  [MARKETPLACE_MANAGE_WINNING_AUCTIONS]: [...base],
  [AUCTIONEER_SALE_LOTS]: [...base],
  [AUCTIONEER_FUTURE_SALES]: [...baseAuctioneer],
  [AUCTIONEER_PAST_SALES]: [...baseAuctioneer],
  [AUCTIONEER_SALE]: [...base],
  [CLASSIFIEDS_IN_STORE]: [...base],
  [MYARTPRICE_STORE]: ['idcountry', 'terms', 'idstoretype', 'idprofessionaltype', 'idartist'],
};
