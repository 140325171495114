import { sources } from '../../../services/auctioneers/medias';
import { isSourcesLoading, getSources } from '../../selectors/auctioneers/medias';

export const AUCTIONEERS_MEDIA_SOURCES = 'auctioneers/AUCTIONEERS_MEDIA_SOURCES';
export const updateSources = ({ data, error, loading = false }) => ({
  type: AUCTIONEERS_MEDIA_SOURCES,
  data,
  error,
  loading,
});

export function fetchSources() {
  return async (dispatch, getState) => {
    const state = getState();

    if (isSourcesLoading(state) || Object.keys(getSources(state)).length > 0) return;
    dispatch(updateSources({ loading: true }));

    try {
      const data = await sources();
      dispatch(updateSources({ data, loading: false }));
    } catch (err) {
      dispatch(
        updateSources({
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}
