/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { get, uniq } from 'lodash';
import {
  deleteLots as deleteLotsService,
  moveLots as moveLotsService,
  renameList as renameListService,
  getLists as getListsService,
  createList as createListService,
  addLots as addLotsService,
  deleteLists as deleteListsService,
  copyLots as copyLotsService,
  getDataList,
} from '../../../services/wallet/customerlotslist';
import { updateSales } from '../../actions/dbEntities/sale';
import { updateLots } from '../../actions/dbEntities/lot';
import { updateArtists } from '../../actions/dbEntities/artist';
import { updateAuctioneers } from '../../actions/dbEntities/auctioneer';
import { updateTechniques } from '../../actions/dbEntities/technique';
import { updateCategories } from '../../actions/dbEntities/category';
import { setSessionStorage, getSessionStorage } from '../../../utils/storage';
import { onClient } from '../../../utils/ssr';

const CURRENT_SELECTION = 'LotsListsCurrentSelection';
const CURRENT_SELECTION_KEY = 'LotsListsCurrentSelectionKey';

let currentSelectionKey;
let currentSelection = {};
if (onClient()) {
  currentSelection = getSessionStorage(CURRENT_SELECTION, {});
  currentSelectionKey = getSessionStorage(CURRENT_SELECTION_KEY, undefined);
}

const accountLotsListsSlice = createSlice({
  name: 'accountLotsLists',
  initialState: {
    lists: {},
    listsOrderedIds: [],
    listsLoading: false,
    dataListLoading: false,
    currentSelectionKey,
    currentSelection,
    addAndCreateButtonsVisibility: false,
  },
  reducers: {
    clear(state) {
      const key = state.currentSelectionKey;
      if (!key) return;

      state.currentSelection[key] = [];
      setSessionStorage(CURRENT_SELECTION, state.currentSelection);
    },
    addLots(state, action) {
      const { ids } = action.payload;
      const key = state.currentSelectionKey;

      if (!key) return;

      state.currentSelection[key] = uniq([...state.currentSelection[key], ...ids]);
      setSessionStorage(CURRENT_SELECTION, state.currentSelection);
    },
    removeLot(state, action) {
      const key = state.currentSelectionKey;
      if (!key) return;

      const { id } = action.payload;
      state.currentSelection[key] = state.currentSelection[key].filter(selectedId => id !== selectedId);
      setSessionStorage(CURRENT_SELECTION, state.currentSelection);
    },
    removeLots(state, action) {
      const key = state.currentSelectionKey;
      if (!key) return;

      const { ids } = action.payload;
      state.currentSelection[key] = state.currentSelection[key].filter(selectedId => !ids.includes(selectedId));
      setSessionStorage(CURRENT_SELECTION, state.currentSelection);
    },
    setCurrentSelectionKey(state, action) {
      const { key } = action.payload;
      state.currentSelectionKey = key;
      setSessionStorage(CURRENT_SELECTION_KEY, key);
    },
    setListsLoading(state, action) {
      const { loading } = action.payload;
      state.listsLoading = loading;
    },
    setAddAndCreateButtonsVisibility(state, action) {
      state.addAndCreateButtonsVisibility = action.payload;
    },
    setDataListLoading(state, action) {
      const { loading } = action.payload;
      state.dataListLoading = loading;
    },
    updateList(state, action) {
      const { list } = action.payload;
      state.lists[list.id] = list;
    },
    setLists(state, action) {
      const { lists } = action.payload;
      state.lists = lists;
    },
    setListsOrderedId(state, action) {
      const { orderedIds } = action.payload;
      state.listsOrderedIds = orderedIds;
    },
    appendList(state, action) {
      const { list } = action.payload;
      state.lists = {
        ...state.lists,
        [list.id]: list,
      };
    },
    updateListLotCount(state, action) {
      const { lotsCount, id } = action.payload;
      state.lists[id].lotsCount = lotsCount;
    },
    setListLotsOrder(state, action) {
      const { sortedIdlots } = action.payload;
      state.sortedIdlots = sortedIdlots;
    },
  },
});

export const {
  clear,
  removeLot,
  removeLots,
  addLots,
  setListsLoading,
  setDataListLoading,
  setLists,
  appendList,
  updateListLotCount,
  setListLotsOrder,
  updateList,
  setCurrentSelectionKey,
  setListsOrderedId,
  setAddAndCreateButtonsVisibility,
} = accountLotsListsSlice.actions;

/* Call to services */

export const initializeWithKey =
  ({ newSelectionKey }) =>
  (dispatch, getState) => {
    const currentKey = getCurrentSelectionKey(getState());

    if (newSelectionKey !== currentKey) {
      dispatch(setCurrentSelectionKey({ key: newSelectionKey }));
      if (!getCurrentSelection(getState())) dispatch(clear());
    }
  };

export const fetchDataList =
  ({ id, sort, page, perPage }) =>
  async dispatch => {
    dispatch(setDataListLoading({ loading: true }));

    const { sortedIdlots, list, lots, artists, sales, auctioneers, techniques, categories } = await getDataList({ id, sort, page, perPage });

    dispatch(updateSales({ sales }));
    dispatch(updateLots({ lots, forceUpdate: true }));
    dispatch(updateArtists({ artists }));
    dispatch(updateAuctioneers({ auctioneers }));
    dispatch(updateTechniques({ techniques }));
    dispatch(updateCategories({ categories }));
    dispatch(updateList({ list }));
    dispatch(setListLotsOrder({ sortedIdlots }));
    dispatch(setDataListLoading({ loading: false }));
  };

export const fetchLists =
  ({ sort = 'label-asc' } = {}) =>
  async (dispatch, getState) => {
    const loading = getState().accountLotsLists.listsLoading;

    if (loading) return;

    dispatch(setListsLoading({ loading: true }));
    const { orderedIds, lists } = await getListsService({ sort });
    dispatch(setLists({ lists }));
    dispatch(setListsOrderedId({ orderedIds }));
    dispatch(setListsLoading({ loading: false }));
  };

export const renameList =
  ({ id, label }) =>
  async dispatch => {
    await renameListService({ id, label });
    dispatch(fetchLists());
  };

export const deleteLists =
  ({ ids }) =>
  async dispatch => {
    await deleteListsService({ ids });
    dispatch(fetchLists());
  };

export const appendToList =
  ({ idlist }) =>
  async (dispatch, getState) => {
    const idlots = getCurrentSelection(getState());

    if (idlots.length > 0) {
      await addLotsService({ id: idlist, idlots });
      dispatch(clear());
      dispatch(fetchLists());
    }
  };

export const deleteLots =
  ({ id, idlots, sort }) =>
  async dispatch => {
    await deleteLotsService({ id, idlots });
    dispatch(fetchDataList({ id, sort }));
  };

export const moveLots =
  ({ idfrom, idto, idlots, sort }) =>
  async dispatch => {
    const { listFrom, listTo } = await moveLotsService({ idfrom, idto, idlots });

    dispatch(updateListLotCount({ id: listFrom.id, lotsCount: listFrom.lotsCount }));
    dispatch(updateListLotCount({ id: listTo.id, lotsCount: listTo.lotsCount }));
    dispatch(fetchDataList({ id: idfrom, sort }));
  };

export const copyLots =
  ({ idfrom, idto, idlots }) =>
  async dispatch => {
    const { listFrom, listTo } = await copyLotsService({ idfrom, idto, idlots });

    dispatch(updateListLotCount({ id: listFrom.id, lotsCount: listFrom.lotsCount }));
    dispatch(updateListLotCount({ id: listTo.id, lotsCount: listTo.lotsCount }));
  };

export const createAndMoveLots =
  ({ idfrom, label, idlots, sort }) =>
  async dispatch => {
    const list = await createListService({ label });
    dispatch(appendList({ list }));
    dispatch(moveLots({ idfrom, idlots, idto: list.id, sort }));
  };

export const createAndCopyLots =
  ({ idfrom, label, idlots }) =>
  async dispatch => {
    const list = await createListService({ label });
    dispatch(appendList({ list }));
    dispatch(copyLots({ idfrom, idlots, idto: list.id }));
  };

export const createAndAppendToList =
  ({ label }) =>
  async dispatch => {
    const list = await createListService({ label });
    dispatch(fetchLists());
    dispatch(appendToList({ idlist: list.id }));
  };

/* Selectors */
export const getAddAndCreateButtonsVisibility = state => get(state.accountLotsLists, 'addAndCreateButtonsVisibility');
export const getCurrentSelectionKey = state => get(state.accountLotsLists, 'currentSelectionKey');
export const getCurrentSelection = state => {
  const key = getCurrentSelectionKey(state);
  if (key) {
    return state.accountLotsLists.currentSelection[key];
  }

  return undefined;
};
export const getLists = state => get(state.accountLotsLists, 'lists');
export const getListsLoading = state => get(state.accountLotsLists, 'listsLoading');
export const getListsOrderedIds = state => get(state.accountLotsLists, 'listsOrderedIds');
export const getList = (state, { id }) => get(state.accountLotsLists, `lists.${id}`);
export const getListLotsOrder = state => get(state.accountLotsLists, 'sortedIdlots');
export const getListDataLoading = state => get(state.accountLotsLists, 'dataListLoading');
export const isSelected = (state, { id }) => {
  const selection = getCurrentSelection(state);
  return selection?.filter(selectedId => id === selectedId).length > 0;
};
export const isInLists = (state, { id }) => {
  const inLists = [];
  Object.values(state.accountLotsLists.lists).forEach(list => {
    if (list.idlots.includes(id)) {
      inLists.push(list.id);
    }
  });

  return inLists;
};

export default accountLotsListsSlice.reducer;
