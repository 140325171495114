import { progress, sale, top5, geo, push, show } from '../../../services/auctioneers/sales';
import { updateSales } from '../dbEntities/sale';
import { updateLots } from '../dbEntities/lot';
import { isShowLoading, isPushLoading, isProgressLoading } from '../../selectors/auctioneers/sales';
import { updateArtists } from '../dbEntities/artist';

export const AUCTIONEERS_SALE_GEO = 'auctioneers/AUCTIONEERS_SALE_GEO';
export const updateSaleGeo = ({ id, result, error, loading = false }) => ({
  type: AUCTIONEERS_SALE_GEO,
  result,
  id,
  error,
  loading,
});

export function fetchSaleGeo({ id }) {
  return async dispatch => {
    dispatch(updateSaleGeo({ id, loading: true }));
    try {
      const result = await geo({ id });
      dispatch(updateSaleGeo({ id, result, loading: false }));
    } catch (err) {
      dispatch(
        updateSaleGeo({
          id,
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}

export const AUCTIONEERS_SALE_TOP_5 = 'auctioneers/AUCTIONEERS_SALE_TOP_5';
export const updateSaleTop5 = ({ id, idlots, error, loading = false }) => ({
  type: AUCTIONEERS_SALE_TOP_5,
  idlots,
  id,
  error,
  loading,
});

export function fetchTop5Lots({ id }) {
  return async dispatch => {
    dispatch(updateSaleTop5({ id, loading: true }));
    try {
      const result = await top5({ id });
      dispatch(updateSaleTop5({ id, idlots: result.idlots, loading: false }));
      dispatch(updateLots({ lots: result.lots, forceUpdate: true }));
      dispatch(updateArtists({ artists: result.artists }));
    } catch (err) {
      dispatch(
        updateSaleTop5({
          id,
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}

export const AUCTIONEERS_SALES_PROGRESS = 'auctioneers/AUCTIONEERS_SALES_PROGRESS';
export const updateSalesProgress = ({ result, error, loading = false }) => ({
  type: AUCTIONEERS_SALES_PROGRESS,
  result,
  error,
  loading,
});

export function getSalesProgress({ ids }) {
  return async (dispatch, getState) => {
    const state = getState();
    if (isProgressLoading(state)) return;

    dispatch(updateSalesProgress({ loading: true }));
    try {
      const result = await progress({ ids });
      dispatch(updateSalesProgress({ result, loading: false }));
    } catch (err) {
      dispatch(updateSalesProgress({ loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const AUCTIONEERS_SALES_PUSH = 'auctioneers/AUCTIONEERS_SALES_PUSH';
export const updateSalesPush = ({ idsale, result, error, loading = false }) => ({
  type: AUCTIONEERS_SALES_PUSH,
  idsale,
  result,
  error,
  loading,
});

export function getSalesPush({ ids }) {
  return async (dispatch, getState) => {
    const state = getState();
    if (isPushLoading(state) || ids.filter(id => Object.keys(state.auctioneers.sales.push).includes(id.toString())).length === ids.length) return;

    dispatch(updateSalesPush({ loading: true }));
    try {
      const result = await push({ ids });
      dispatch(updateSalesPush({ result, loading: false }));
    } catch (err) {
      dispatch(
        updateSalesPush({
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}

export const AUCTIONEERS_SALES_SHOW = 'auctioneers/AUCTIONEERS_SALES_SHOW';
export const updateSalesShow = ({ result, error, loading = false }) => ({
  type: AUCTIONEERS_SALES_SHOW,
  result,
  error,
  loading,
});

export function getSalesShow({ ids }) {
  return async (dispatch, getState) => {
    const state = getState();

    if (isShowLoading(state) || ids.filter(id => Object.keys(state.auctioneers.sales.show).includes(id.toString())).length === ids.length) return;

    dispatch(updateSalesShow({ loading: true }));
    try {
      const result = await show({ ids });
      dispatch(updateSalesShow({ result, loading: false }));
    } catch (err) {
      dispatch(
        updateSalesShow({
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}

export function fetch({ id }) {
  return async dispatch => {
    dispatch(updateSales({ loading: true }));
    try {
      const result = await sale({ id });
      dispatch(updateSales({ sales: { [id]: result }, loading: false }));
    } catch (err) {
      dispatch(
        updateSales({
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}
