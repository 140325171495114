export const TOTAL_COUNT_KEY = 'totalCount';

export const COUNTRY_REPARTITION_KEY = 'countryRepartition';

export const BEST_CLASSIFIEDS = 'bestClassifieds';

export const BEST_ARTISTS = 'bestArtists';

export const INQUIRIES = 'inquiries';

export const PUBLISHED_CLASSIFIEDS = 'publishedClassifieds';

export const VIEWED_CLASSIFIEDS = 'viewedClassifieds';
