import { HOSTNAME_PREFIXES, LOCALE_BY_PREFIX } from '../../constants/locales';

export function createNewUrlForLocale(locale, newLocale) {
  // we have to use decodeURIComponent here because of SSR (in artprice_js_renderer we sometimes set an encoded url instead of a correct one)
  return `${window.location.origin.replace(HOSTNAME_PREFIXES[locale], HOSTNAME_PREFIXES[newLocale])}/ul?p=${encodeURIComponent(decodeURIComponent(window.location.pathname))}`;
}

export function guessNewUrlForLocale(newLocale) {
  const match = window.location.host.match(/^(\w+)\.\w+\./);
  if (Array.isArray(match) && match[1] in LOCALE_BY_PREFIX) {
    return createNewUrlForLocale(LOCALE_BY_PREFIX[match[1]], newLocale);
  }

  return createNewUrlForLocale('__UNKNOWN__', newLocale);
}
