import { internalAxios } from '../../utils/axios/internal';

export const getSome = async (id = [], withDates = false) => {
  const params = { id };
  if (!withDates) params.dates = 0;
  const response = await internalAxios.get('/api/artdbweb/artist/get', { params });
  return response.data;
};

export const free = async () => {
  const response = await internalAxios.get('/api/artdbweb/artist/free');
  return response.data;
};
