import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { begin, end } from '../../actions/ui/asyncStatus';
import { getEstimateContext, getMarketplaceContext, getReduxContext, updateIdprofessionaltype as updateIdprofessionaltypeService, updateTvaIntra as updateTvaIntraService } from '../../../services/userContext';
import { isEstimateLoaded, isEstimateLoading, isLoaded, isLoading, isMarketplaceLoaded, isMarketplaceLoading } from './selectors';

const userContextSlice = createSlice({
  name: 'userContext',
  initialState: {
    loading: false,
    loaded: false,
  },
  reducers: {
    update(state, action) {
      merge(state, action.payload);
    },
  },
});

/* Actions */

export const { update } = userContextSlice.actions;

export const ASYNC_UPDATE_TVA_INTRA_ID = 'UPDATE_TVA_INTRA';
export function updateTvaIntra({ tvaintra }) {
  return async dispatch => {
    dispatch(begin({ id: ASYNC_UPDATE_TVA_INTRA_ID }));
    try {
      const { tvaintra: newTvaintra } = await updateTvaIntraService({ tvaintra });
      dispatch(update({ tvaintra: newTvaintra }));
      dispatch(end({ id: ASYNC_UPDATE_TVA_INTRA_ID }));
    } catch (err) {
      console.error(err);
      dispatch(end({ id: ASYNC_UPDATE_TVA_INTRA_ID, error: err.toString() }));
    }
  };
}

export const ASYNC_ID = 'UPDATE_IDPROFESSIONALTYPE';
export function updateIdprofessionaltype({ idprofessionaltype }) {
  return async dispatch => {
    dispatch(begin({ id: ASYNC_ID }));
    try {
      const { idprofessionaltype: newType } = await updateIdprofessionaltypeService({ idprofessionaltype });
      dispatch(update({ idprofessionaltype: newType }));
      dispatch(end({ id: ASYNC_ID }));
    } catch (err) {
      console.error(err);
      dispatch(end({ id: ASYNC_ID, error: err.toString() }));
    }
  };
}

export function fetchUserContext(force = false) {
  return async (dispatch, getState) => {
    const state = getState();
    if (force || (!isLoaded(state) && !isLoading(state))) {
      dispatch(update({ loading: true }));
      try {
        const context = await getReduxContext();
        if (context?.idcustomer) {
          Sentry.setUser({ id: context.idcustomer, ip_address: '{{auto}}' });
        }
        dispatch(update({ ...context, loaded: true }));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(update({ loading: false }));
      }
    }
  };
}

export function fetchMarketplaceContext() {
  return async (dispatch, getState) => {
    const state = getState();
    if (!isMarketplaceLoaded(state) && !isMarketplaceLoading(state)) {
      dispatch(update({ marketplace: { loading: true } }));
      try {
        const marketplaceContext = await getMarketplaceContext();
        dispatch(update({ marketplace: { ...marketplaceContext, loaded: true } }));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(update({ marketplace: { loading: false } }));
      }
    }
  };
}

export function fetchEstimateContext() {
  return async (dispatch, getState) => {
    const state = getState();
    if (!isEstimateLoaded(state) && !isEstimateLoading(state)) {
      dispatch(update({ estimate: { loading: true } }));
      try {
        const estimateContext = await getEstimateContext();
        dispatch(update({ estimate: { ...estimateContext, loaded: true } }));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(update({ estimate: { loading: false } }));
      }
    }
  };
}

export default userContextSlice.reducer;
