export const UPDATE_LOTS = 'dbEntities/UPDATE_LOTS';
export const updateLots = ({ lots, loading, error, forceUpdate }) => ({
  type: UPDATE_LOTS,
  payload: {
    data: lots,
    loading,
    error,
    forceUpdate,
  },
});
