// Fallback storage
import { isUndefined } from 'lodash';

const IN_MEMORY_STORAGE = {
  store: {},
  setItem(key, value) {
    this.store[key] = value;
  },
  getItem(key) {
    return this.store[key];
  },
  removeItem(key) {
    delete this.store[key];
  },
};

// Testing support
// https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
function isSupported(storage) {
  try {
    const testKey = '__random_key_we_are_not_going_to_use__';
    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

const set = (storage, key, value) => {
  if (!isUndefined(value)) {
    if (isSupported(storage)) {
      storage.setItem(key, JSON.stringify(value));
    } else {
      IN_MEMORY_STORAGE.setItem(key, value);
    }
  }
};

const get = (storage, key, defaultValue) => {
  let value;
  if (isSupported(storage)) {
    value = JSON.parse(storage.getItem(key));
  } else {
    value = IN_MEMORY_STORAGE.getItem(key);
  }
  if (!value) return defaultValue;

  return value;
};

const remove = (storage, key) => {
  if (isSupported(storage)) {
    storage.removeItem(key);
  } else {
    IN_MEMORY_STORAGE.removeItem(key);
  }
};

// Exported functions -----------------------------------------------------------------------------

// Session storage
export const setSessionStorage = (key, value) => {
  set(sessionStorage, key, value);
};

export const getSessionStorage = (key, defaultValue) => get(sessionStorage, key, defaultValue);

export const removeSessionStorage = key => {
  remove(sessionStorage, key);
};

// Local storage

export const setLocaleStorage = (key, value) => {
  set(localStorage, key, value);
};

export const getLocaleStorage = (key, defaultValue) => get(localStorage, key, defaultValue);

export const removeLocaleStorage = key => {
  remove(localStorage, key);
};
