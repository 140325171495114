import { createMessage as createMessageService, retrieveMessage as retrieveMessageService } from '../../../../services/marketplace/classifieds/index';

export const RETRIEVE_MESSAGES = 'marketplace/classifieds/RETRIEVE_MESSAGES';
const retrieveMessage = messages => ({
  type: RETRIEVE_MESSAGES,
  payload: messages,
});

export const dispatchRetrieveMessage = idclassified => async dispatch => {
  const resp = await retrieveMessageService(idclassified);
  if (resp) dispatch(retrieveMessage(resp));
};

export const CREATE_MESSAGE = 'marketplace/classifieds/CREATE_MESSAGE';
/* const createMessage = ({ message, idclassified, idcustomerInterlocutor }) => ({
  type: CREATE_MESSAGE,
  payload: { message, idclassified, idcustomerInterlocutor },
}); */

export const dispatchCreateMessage =
  ({ message, idclassified, idcustomerInterlocutor }) =>
  async dispatch => {
    /* const resp = */ await createMessageService({ message, idclassified, idcustomerInterlocutor });
    // dispatch(createMessage({ message, idclassified, idcustomerInterlocutor }));
    dispatch(dispatchRetrieveMessage(idclassified));
  };
