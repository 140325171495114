import { updateCategories as updateCategoriesEntities } from '../dbEntities/category';
import { getParam, getCategoriesParams, areCategoriesLoading } from '../../selectors/myartprice';
import { getArtistParam } from '../../selectors/myartprice/datas';
import { updatePreferences as updatePreferencesService, fetchCategoriesParams as fetchCategoriesParamsService, fetchSettings as fetchSettingsService } from '../../../services/myartprice/settings';
import { SHORTCUT_SETTINGS_ARTISTS } from '../../../constants/myartprice';

export const UPDATE_ARTIST_PREFERENCES = 'myartprice/settings/UPDATE_ARTIST_PREFERENCES';
export const UPDATE_SETTINGS_PREFERENCES = 'myartprice/settings/UPDATE_SETTINGS_PREFERENCES';
export const updatePreferences =
  (section, value, params = {}) =>
  async (dispatch, getState) => {
    let oldValue;
    const action = {
      type: UPDATE_SETTINGS_PREFERENCES,
      payload: {
        section,
        params,
        value,
      },
    };

    if (section === SHORTCUT_SETTINGS_ARTISTS) {
      action.type = UPDATE_ARTIST_PREFERENCES;
      oldValue = getArtistParam(getState(), { idartist: params.idartist, params });
    } else {
      oldValue = getParam(getState(), { idartist: params.idartist, params });
    }
    dispatch(action);

    try {
      const response = await updatePreferencesService(section, value, params);
      if (value !== response.result) {
        action.payload.value = response.result;
        dispatch(action);
      }
    } catch (e) {
      console.error(e);
      action.payload.value = oldValue;
      dispatch(action);
    }
  };

export const UPDATE_CATEGORIES = 'myartprice/settings/UPDATE_CATEGORIES';
export const updateCategories = ({ data, loading, error }) => ({
  type: UPDATE_CATEGORIES,
  payload: {
    data,
    loading,
    error,
  },
});

export const fetchCategories = () => async (dispatch, getState) => {
  const state = getState();

  if (areCategoriesLoading(state) || getCategoriesParams(state)) {
    return;
  }
  try {
    dispatch(updateCategories({ loading: true }));
    const { categories, settings } = (await fetchCategoriesParamsService()) || {};
    dispatch(updateCategoriesEntities({ loading: false, categories }));
    dispatch(updateCategories({ loading: false, data: settings }));
  } catch (error) {
    dispatch(updateCategories({ loading: false, error }));
  }
};

export const FETCH_SETTINGS = 'myartprice/settings/FETCH_SETTINGS';
export const fetchSettings = () => async dispatch => {
  const settings = (await fetchSettingsService()) || {};
  const { ended, futures, auctions, fixedPrice } = settings;
  dispatch({
    type: FETCH_SETTINGS,
    payload: {
      ended,
      futures,
      auctions,
      fixedPrice,
    },
  });
};
