export const UPDATE_SEARCH_SCOPE = 'search/UPDATE_SEARCH_SCOPE';
export const updateSearchScope = ({ searchScope }) => ({
  type: UPDATE_SEARCH_SCOPE,
  payload: {
    searchScope,
  },
});

export const UPDATE_LOADING_FACETS = 'search/UPDATE_LOADING_FACETS';
export const updateLoadingFacets = ({ loadingFacets, searchScope }) => ({
  type: UPDATE_LOADING_FACETS,
  payload: {
    loadingFacets,
    searchScope,
  },
});

export const UPDATE_DRAFT_COUNT = 'search/UPDATE_DRAFT_COUNT';
export const updateDraftCount = ({ count, searchScope }) => ({
  type: UPDATE_DRAFT_COUNT,
  payload: {
    count,
    searchScope,
  },
});

export const UPDATE_COUNT = 'search/UPDATE_COUNT';
export const updateCount = ({ count, searchScope }) => ({
  type: UPDATE_COUNT,
  payload: {
    count,
    searchScope,
  },
});

export const UPDATE_PAGE = 'search/UPDATE_PAGE';
export const updatePage = ({ page, searchScope }) => ({
  type: UPDATE_PAGE,
  payload: {
    page,
    searchScope,
  },
});

export const UPDATE_SORT = 'search/UPDATE_SORT';
export const updateSort = ({ sort, searchScope }) => ({
  type: UPDATE_SORT,
  payload: {
    sort,
    searchScope,
  },
});

export const UPDATE_INFINITE_SCROLL = 'search/UPDATE_INFINITE_SCROLL';
export const updateInfiniteScroll = ({ infiniteScroll, searchScope }) => ({
  type: UPDATE_INFINITE_SCROLL,
  payload: {
    infiniteScroll,
    searchScope,
  },
});
