import { createNextState } from '@reduxjs/toolkit';
import { merge, get } from 'lodash';
import { UPDATE_DRAFT_FACETS } from '../../actions/search/draftFacets';
import { ensureScopedAction } from '../../../utils/search/reducer';

function getInitialState() {
  return {};
}

export default function draftFacetsReducer(state = getInitialState(), action) {
  const { type, payload } = action;
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_DRAFT_FACETS: {
        ensureScopedAction(action);
        const facets = { ...payload.facets };
        Object.keys(facets).forEach(facetKey => {
          if (Object.keys(payload.params).includes(facetKey) && (!Array.isArray(payload.params[facetKey]) || payload.params[facetKey].length > 0)) {
            facets[facetKey] = merge({}, get(state, `${payload.searchScope}.${facetKey}`), facets[facetKey]);
          }
        });
        draft[payload.searchScope] = facets;
        break;
      }
      default:
        return draft;
    }
  });
}
