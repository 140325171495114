/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { get, merge } from 'lodash';
import { getAddresses as getAddressesService, setAddress, updateOrCreate as updateOrCreateService } from '../../../services/wallet/customer';
import { getCart as getCartService } from '../../../services/wallet/physicproduct';
import { EMPTY_OBJECT } from '../../../constants/utils';

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    navigationStep: 1,
    cart: {},
    params: null,
    shipAddress: null,
    shipAddressLoading: false,
    billAddress: null,
    billAddressLoading: false,
    customerMutationRunning: false,
  },
  reducers: {
    updateNavigationStep(state, action) {
      state.navigationStep = action.payload;
    },
    updateCart(state, action) {
      state.cart = action.payload;
    },
    updateParams(state, action) {
      if (state?.params) {
        merge(state.params, action.payload);
      } else {
        state.params = action.payload;
      }
    },
    updateBillAddress(state, action) {
      state.billAddress = action.payload;
    },
    updateBillAddressLoading(state, action) {
      state.billAddressLoading = action.payload;
    },
    updateShipAddress(state, action) {
      state.shipAddress = action.payload;
    },
    updateShipAddressLoading(state, action) {
      state.shipAddressLoading = action.payload;
    },
    updateCustomerMutationRunning(state, action) {
      state.customerMutationRunning = action.payload;
    },
  },
});

/* Selectors */

export const navigationStep = state => get(state.payments, 'navigationStep', 1);
export const getCart = state => get(state.payments, 'cart', EMPTY_OBJECT);
export const getParams = state => get(state.payments, 'params', EMPTY_OBJECT);
export const getPromocode = state => get(state.payments, 'params.promocode', EMPTY_OBJECT);
export const getCartLoading = state => get(state.payments, 'cart.loading', false);
export const getShipAddress = state => get(state.payments, 'shipAddress', null);
export const getBillAddress = state => get(state.payments, 'billAddress', null);
export const getShipAddressLoading = state => get(state.payments, 'shipAddressLoading', false);
export const getBillAddressLoading = state => get(state.payments, 'billAddressLoading', false);
export const getCustomerMutationRunning = state => get(state.payments, 'customerMutationRunning', false);
export const getCartUnavailable = state => get(state.payments, 'cart.cart_unavailable_for_product', false);

/* Actions */
const {
  updateCustomerMutationRunning: updateCustomerMutationRunningAction,
  updateBillAddress: updateBillAddressAction,
  updateBillAddressLoading,
  updateShipAddress: updateShipAddressAction,
  updateShipAddressLoading,
} = paymentsSlice.actions;
export const { updateParams, updateNavigationStep, updateCart } = paymentsSlice.actions;

export const updateOrCreateCustomer = values => async (dispatch, getState) => {
  const loading = getCustomerMutationRunning(getState());

  if (!loading) {
    dispatch(updateCustomerMutationRunningAction(true));
    try {
      await updateOrCreateService(values);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(updateCustomerMutationRunningAction(false));
    }
  }
};

export const updateShipAddress = values => async (dispatch, getState) => {
  const loading = getShipAddressLoading(getState());

  if (!loading) {
    dispatch(updateShipAddressLoading(true));

    try {
      const data = await setAddress({ ...values, type_address: 'ship' });
      await dispatch(updateShipAddressAction(data));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(updateShipAddressLoading(false));
    }
  }
};

export const updateBillAddress = values => async (dispatch, getState) => {
  const loading = getBillAddressLoading(getState());

  if (!loading) {
    dispatch(updateBillAddressLoading(true));

    try {
      const data = await setAddress({ ...values, type_address: 'bill' });
      await dispatch(updateBillAddressAction(data));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(updateBillAddressLoading(false));
    }
  }
};

export const fetchAddress = () => async (dispatch, getState) => {
  const isLoading = getBillAddressLoading(getState()) || getShipAddressLoading(getState());

  if (!isLoading) {
    try {
      dispatch(updateBillAddressLoading(true));
      dispatch(updateShipAddressLoading(true));

      const { bill, ship } = await getAddressesService();

      dispatch(updateBillAddressAction(bill));
      dispatch(updateShipAddressAction(ship));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(updateBillAddressLoading(false));
      dispatch(updateShipAddressLoading(false));
    }
  }
};

export const fetchCart =
  ({ type, image, promocode, idphysicproducts, idphysicsale }) =>
  async (dispatch, getState) => {
    const isLoading = getCartLoading(getState());
    if (!isLoading) {
      try {
        dispatch(updateCart({ loading: true }));
        const data = await getCartService({ type, image, promocode, idphysicproducts, idphysicsale });
        dispatch(updateCart({ ...data, loading: false }));
      } catch (e) {
        console.error(e);
        dispatch(updateCart({ loading: false }));
      }
    }
  };

export default paymentsSlice.reducer;
