import { internalAxios } from '../../utils/axios/internal';

export const followLot = async idlot => {
  const response = await internalAxios.post('/api/myartprice/settings/follow_lot', { id: idlot });
  return response.data;
};

export const unfollowLot = async idlot => {
  const response = await internalAxios.post('/api/myartprice/settings/unfollow_lot', { id: idlot });
  return response.data;
};

export const similarLot = async idlot => {
  const response = await internalAxios.post('/api/myartprice/lots/similar', { id: idlot });
  return response.data;
};
