import { createNextState } from '@reduxjs/toolkit';
import { ON, OFF, TOGGLE } from '../../actions/ui/switches';

function getInitialState() {
  return {};
}

export default function switchesReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case ON: {
        draft[payload.id] = true;
        break;
      }
      case OFF: {
        draft[payload.id] = false;
        break;
      }
      case TOGGLE: {
        if (draft.hasOwnProperty(payload.id)) {
          draft[payload.id] = !draft[payload.id];
        } else {
          draft[payload.id] = true;
        }
        break;
      }
      default:
        return draft;
    }
  });
}
