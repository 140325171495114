import { internalAxios } from '../../utils/axios/internal';

export const storesForArtist = async idartist => {
  const response = await internalAxios.get(`/api/wallet/store/stores_for_artist/${idartist}.json`);
  return response.data;
};

export const save = async (idstore, values) => {
  const response = await internalAxios.post(`/api/wallet/store/save/${idstore}.json`, { store: values });
  return response.data;
};

export const sitenameExists = async (idstore, sitename) => {
  const response = await internalAxios.post(`/api/wallet/store/sitename_exists/${idstore}.json`, { q: sitename });
  return response.data;
};

export const removeArtist = async idartist => {
  const response = await internalAxios.post(`/api/wallet/store/remove_artist/${idartist}`, {});
  return response.data;
};

export const addArtist = async idartist => {
  const response = await internalAxios.post(`/api/wallet/store/add_artist/${idartist}.json`, {});
  return response.data;
};

export const getTop = async () => {
  const response = await internalAxios.get('/api/wallet/store/top');
  return response.data;
};

export const get = async id => {
  const response = await internalAxios.get(`/api/wallet/store/get/${id}.json`);
  return response.data;
};

const getImageSizeAsPromise = src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ height: img.naturalHeight, width: img.naturalWidth });
    img.onerror = reject;
    img.src = src;
  });

const getSizeFromImageAsPromise = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = async () => {
      resolve(await getImageSizeAsPromise(reader.result));
    };
    reader.readAsDataURL(file);
  });

export const uploadImage = async (file, overridedImageId) => {
  let url = '/api/wallet/store/create_image';
  if (overridedImageId) {
    url = `${url}/${overridedImageId}`;
  }

  if (!/\/(jpg|png|jpeg)$/i.test(file.type)) {
    return 404;
  }

  const result = await getSizeFromImageAsPromise(file);
  if (result.height < 250 && result.width < 250) {
    // Not Acceptable
    return 406;
  }

  const data = new FormData();
  data.append('blob', file, 'image.jpg');

  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return internalAxios
    .post(url, data, config)
    .then(response => response.status)
    .catch(error => error.response.status);
};

export const deleteImage = async id => {
  const response = await internalAxios.post(`/api/wallet/store/delete_image/${id}.json`, {});
  return response.data;
};

export const setPrimaryImage = async id => {
  const response = await internalAxios.post(`/api/wallet/store/set_primary_image/${id}.json`, {});
  return response.data;
};
