import { createNextState } from '@reduxjs/toolkit';
import { UPDATE_URL_PARAMS } from '../../actions/search/urlParams';
import { ensureScopedAction } from '../../../utils/search/reducer';

function getInitialState() {
  return {};
}

export default function urlParamsReducer(state = getInitialState(), action) {
  const { type, payload } = action;
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_URL_PARAMS:
        ensureScopedAction(action);
        draft[payload.searchScope] = payload.params;
        break;
      default:
        return draft;
    }
  });
}
