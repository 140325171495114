import { internalAxios } from '../../utils/axios/internal';

export const getAll = async () => {
  const response = await internalAxios.get('/api/artdbweb/country/get');
  return response.data;
};

export const getSome = async (ids = []) => {
  const response = await internalAxios.post('/api/artdbweb/country/get', { ids });
  return response.data;
};
