import { createNextState } from '@reduxjs/toolkit';
import { UPDATE_PHYSICPRODUCTS } from '../../actions/subscriptions/physicproduct';

function getInitialState() {
  return {};
}

export default function switchesReducer(state = getInitialState(), { type, payload }) {
  const { data, error, loading } = payload || {};
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_PHYSICPRODUCTS: {
        return {
          data,
          error,
          loading,
        };
      }
      default:
        return draft;
    }
  });
}
