import { createNextState } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { AUCTIONEERS_UPDATE_SENDING_STATUS, AUCTIONEERS_CLEAR_SENDING_STATUS } from '../../actions/auctioneers/contact';

function getInitialState() {
  return {};
}

export default function statsReducer(state = getInitialState(), action) {
  return createNextState(state, draft => {
    switch (action.type) {
      case AUCTIONEERS_CLEAR_SENDING_STATUS: {
        return {};
      }
      case AUCTIONEERS_UPDATE_SENDING_STATUS: {
        const { sending, data, error } = action;
        merge(draft, { sending, data, error });
        break;
      }
      default:
        return draft;
    }
  });
}
