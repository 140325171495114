import { flow } from 'lodash';

import { CURRENCIES as CURRENCIES_FROM_RAILS } from './rails';

export const CURRENCIES = CURRENCIES_FROM_RAILS;
export const DEFAULT_CURRENCIES = flow([Object.entries, arr => arr.filter(([_, { defaultCurrency }]) => defaultCurrency), Object.fromEntries])(CURRENCIES);
export const OPTIONAL_CURRENCIES = flow([Object.entries, arr => arr.filter(([_, { defaultCurrency }]) => !defaultCurrency), Object.fromEntries])(CURRENCIES);

export const LANGUAGES = {
  FRENCH: { iso2: 'fr' },
  ENGLISH: { iso2: 'en' },
  ITALIAN: { iso2: 'it' },
  SPANISH: { iso2: 'es' },
  GERMAN: { iso2: 'de' },
  CHINESE: { iso2: 'zh' },
};
