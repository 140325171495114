import { getIdartist } from '../../selectors/indexes/artist';

import { mediumDataService } from '../../../services/indexes/medium';
import { createChartKey, isMediumDataLoading, getMediumData, getMediumDisplayedChartTab, getMediumOptions } from '../../selectors/indexes/medium';

import { getCurrency } from '../../selectors/preferences';
import { CURRENCIES } from '../../../constants/preferences';

export const UPDATE_MEDIUM_OPTIONS = 'indexes/medium/UPDATE_OPTIONS';
export const updateMediumOptions = ({ options }) => ({
  type: UPDATE_MEDIUM_OPTIONS,
  payload: {
    options,
  },
});

export const UPDATE_MEDIUM_DISPLAYED_CHART_TAB = 'indexes/medium/UPDATE_DISPLAYED_CHART_TAB';
export const updateMediumDisplayedChartTab = ({ chartTab }) => ({
  type: UPDATE_MEDIUM_DISPLAYED_CHART_TAB,
  payload: {
    chartTab,
  },
});

export const UPDATE_MEDIUM_DATA = 'indexes/medium/UPDATE_DATA';
export const updateMediumData = ({ idartist, currency, chartKey, loading, data, error }) => ({
  type: UPDATE_MEDIUM_DATA,
  payload: {
    idartist,
    currency,
    chartKey,
    loading,
    data,
    error,
  },
});
export function fetchMediumData() {
  return async (dispatch, getState) => {
    const state = getState();

    if (isMediumDataLoading(state) || getMediumData(state) !== undefined) return;

    const chartTab = getMediumDisplayedChartTab(state);
    const idartist = getIdartist(state);
    const currency = getCurrency(state);

    if (!idartist || !currency || !chartTab) return;

    const { idcountry, minYear, maxYear, minPrice, maxPrice, ...rest } = getMediumOptions(state) || {};

    if (Object.keys(rest).length > 0) console.error('ERROR: UNUSED OPTIONS {', rest, '}');

    const chartKey = createChartKey({ chartTab, idcountry, minYear, maxYear, minPrice, maxPrice });

    dispatch(updateMediumData({ idartist, currency, chartKey, loading: true }));
    try {
      const data = await mediumDataService({
        idcountry,
        minPrice,
        maxPrice,
        minYear,
        maxYear,
        chartTab,
        idartist,
        idcurrency: CURRENCIES[currency].id,
      });

      dispatch(updateMediumData({ idartist, currency, chartKey, data, loading: false }));
    } catch (err) {
      dispatch(updateMediumData({ idartist, currency, chartKey, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}
