import { createNextState } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { UPDATE_LOADING_FACETS, UPDATE_DRAFT_COUNT, UPDATE_COUNT, UPDATE_PAGE, UPDATE_SORT, UPDATE_SEARCH_SCOPE, UPDATE_INFINITE_SCROLL } from '../../actions/search/info';
import { ensureScopedAction } from '../../../utils/search/reducer';

function getInitialState() {
  return {
    currentScope: null,
  };
}

export default function searchReducer(state = getInitialState(), action) {
  const { type, payload } = action;
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_INFINITE_SCROLL:
        ensureScopedAction(action);
        if (!draft[payload.searchScope]) {
          draft[payload.searchScope] = {};
        }
        set(draft, `${payload.searchScope}.infiniteScroll`, payload.infiniteScroll);
        break;
      case UPDATE_SEARCH_SCOPE:
        ensureScopedAction(action);
        draft.currentScope = payload.searchScope;
        break;
      case UPDATE_LOADING_FACETS:
        ensureScopedAction(action);
        set(draft, `${payload.searchScope}.loadingFacets`, payload.loadingFacets);
        break;
      case UPDATE_DRAFT_COUNT:
        ensureScopedAction(action);
        set(draft, `${payload.searchScope}.draftCount`, payload.count);
        break;
      case UPDATE_COUNT:
        ensureScopedAction(action);
        set(draft, `${payload.searchScope}.count`, payload.count);
        break;
      case UPDATE_PAGE:
        ensureScopedAction(action);
        set(draft, `${payload.searchScope}.page`, payload.page);
        break;
      case UPDATE_SORT:
        ensureScopedAction(action);
        set(draft, `${payload.searchScope}.sort`, payload.sort);
        break;
      default:
        return draft;
    }
  });
}
