import { createNextState } from '@reduxjs/toolkit';
import _ from 'lodash';
import { UPDATE_SUMMARY_DATA } from '../../actions/indexes/summary';

function getInitialState() {
  return {};
}

export default function summaryReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_SUMMARY_DATA: {
        const { idartist, currency, loading, error, data } = payload;
        _.set(draft, `${idartist}.${currency}`, {
          loading,
          error,
          data,
        });
        break;
      }

      default:
        return draft;
    }
  });
}
