import { getTop as getTopService } from '../../../../services/wallet/store';
import { getTopStores as getTopStoresSelector } from '../../../selectors/marketplace/stores';

export const UPDATE_TOP_STORES = 'marketplace/stores/UPDATE_TOP_STORES';
export const updateTopStores = ({ result, error, loading = false }) => ({
  type: UPDATE_TOP_STORES,
  payload: {
    result,
    error,
    loading,
  },
});

export const fetchTopStores =
  ({ refresh = false } = {}) =>
  async (dispatch, getState) => {
    if (!refresh) {
      const topStores = getTopStoresSelector(getState());
      if (Array.isArray(topStores) && topStores.length > 0) {
        return;
      }
    }
    dispatch(updateTopStores({ loading: true }));
    try {
      const result = await getTopService();
      dispatch(updateTopStores({ loading: false, result }));
    } catch (err) {
      dispatch(
        updateTopStores({
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
