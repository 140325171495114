import { createNextState } from '@reduxjs/toolkit';
import { RESET_DRAFT_PARAMS, UPDATE_DRAFT_PARAMS } from '../../actions/search/draftParams';
import { ensureScopedAction } from '../../../utils/search/reducer';

function getInitialState() {
  return {};
}

export default function draftParamsReducer(state = getInitialState(), action) {
  const { type, payload } = action;
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_DRAFT_PARAMS:
        ensureScopedAction(action);
        draft[payload.searchScope] = payload.params;
        break;
      case RESET_DRAFT_PARAMS:
        ensureScopedAction(action);
        draft[payload.searchScope] = payload.initialParams;
        break;
      default:
        return draft;
    }
  });
}
