import axios from 'axios';
import xRequestIdManager from '../xRequestIdManager';

/*
  This is the instance of Axios used to make internal calls
  - with credentials for CORS
  - X-Trace-Request-Id header
*/
export const internalAxios = axios.create({
  withCredentials: true,
});

internalAxios.interceptors.request.use(config => {
  if (!xRequestIdManager.isEmpty()) {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-Trace-Request-Id'] = xRequestIdManager.get();
  }
  return config;
});

function manageRefreshSession(response) {
  if (response?.status === 449) {
    window.location.reload();
  }
}

internalAxios.interceptors.response.use(
  response => {
    manageRefreshSession(response);
    return response;
  },
  error => {
    if (!axios.isCancel(error)) {
      manageRefreshSession(error.response);
    }
    return Promise.reject(error);
  },
);
