import isUndefined from 'lodash/isUndefined';
import i18next from 'i18next';
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import zh from 'date-fns/locale/zh-CN';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import { addSeconds, formatDistance } from 'date-fns';

const FNS_LOCALES = { fr, en, de, zh, it, es };

/*
  Format a string using date-fns;
 */
export const formatFns = (date, newFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", opts = {}) => {
  const locale = FNS_LOCALES[opts.locale || i18next.language || 'en'];

  if (isUndefined(date)) {
    return undefined;
  }

  try {
    if (isDate(date)) {
      return format(date, newFormat, { locale });
    }

    const parsedDate = parseISO(date);
    if (isValid(parsedDate)) {
      return format(parsedDate, newFormat, { locale });
    }

    return format(new Date(date), newFormat, { locale });
  } catch (e) {
    throw new Error(`Error while using formatFns on "${date}" : ${e}`);
  }
};

/*
  Humanize a duration in seconds using date-fns;
 */
export const humanizeDurationFns = (seconds, opts = {}) => {
  const locale = FNS_LOCALES[opts.locale || i18next.language || 'en'];

  const dt2 = Date.now();
  const dt1 = addSeconds(dt2, -seconds);
  return formatDistance(dt1, dt2, { locale });
};
