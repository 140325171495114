import * as Sentry from '@sentry/react';
import { onClient } from './ssr';

const xRequestIdManager = {
  xRequestId: null,
  isEmpty() {
    return !this.xRequestId;
  },
  get() {
    return this.xRequestId;
  },
  set(xRequestId) {
    this.xRequestId = xRequestId;
  },
};

function fetchAndSetXRequestId() {
  try {
    const requestId = document.querySelector('meta[name=x-request-id]')?.content?.trim();
    if (requestId && requestId !== '') {
      xRequestIdManager.set(requestId);
      Sentry.setTag('request_id', requestId);
    } else {
      // fallback on js global value
      const fallbackRequestId = window.artprice?.requestId;
      if (fallbackRequestId && fallbackRequestId !== '') {
        xRequestIdManager.set(fallbackRequestId);
        Sentry.setTag('request_id', requestId);
      }
    }
  } catch (e) {
    console.error(e);
  }
}

/*
First run, we populate the xRequestIdManager with the meta=x-request-id
 */
if (onClient()) {
  fetchAndSetXRequestId();
  setTimeout(fetchAndSetXRequestId, 100); // ceinture/bretelle
}

export default xRequestIdManager;
