import { areCategoriesLoading, getCategory } from '../../selectors/dbEntities';
import { getSome } from '../../../services/dbEntities/category';

export const UPDATE_CATEGORIES = 'dbEntities/UPDATE_CATEGORIES';
export const updateCategories = ({ categories, loading, error }) => ({
  type: UPDATE_CATEGORIES,
  payload: {
    data: categories,
    loading,
    error,
  },
});

export const fetchCategory =
  ({ id }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (areCategoriesLoading(state) || getCategory(state, { id })) return;

    try {
      dispatch(updateCategories({ loading: true }));

      const categories = await getSome([id]);

      dispatch(updateCategories({ loading: false, categories }));
    } catch (error) {
      console.error(error);
      dispatch(updateCategories({ loading: false, error }));
    }
  };
