import { combineReducers } from 'redux';
import statsReducer from './stats';
import salesReducer from './sales';
import mediasReducer from './medias';
import contactReducer from './contact';

export default combineReducers({
  stats: statsReducer,
  sales: salesReducer,
  medias: mediasReducer,
  contact: contactReducer,
});
