/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import createRootReducer from './reducers';
import sliceReducers from './slices';
import { getIdcustomer } from './slices/userContext/selectors';

const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: state => {
    if (state?.userContext) {
      return {
        userContext: state.userContext,
      };
    }
    return null;
  },
});

function makeStore(initialState = {}, onServer = false) {
  return configureStore({
    reducer: createRootReducer({ ...sliceReducers }),
    devTools: !onServer,
    preloadedState: initialState,
    enhancers: [sentryReduxEnhancer],
  });
}

let memoizedStore = null;
export default function getConfiguredStore(onServer = false) {
  if (memoizedStore) {
    return memoizedStore;
  }

  let initialState = {};
  if (typeof window !== 'undefined' && window.__PRELOADED_STATE__) {
    initialState = window.__PRELOADED_STATE__;
    delete window.__PRELOADED_STATE__;
  }

  memoizedStore = makeStore(initialState, onServer);

  if (!onServer) {
    const idcustomer = getIdcustomer(memoizedStore.getState());
    if (idcustomer) {
      Sentry.setUser({ id: idcustomer, ip_address: '{{auto}}' });
    }
  }

  return memoizedStore;
}
