import { indicatorService } from '../../services/indicator';

export const ARTDBWEB_LOT_INDICATOR = 'ARTDBWEB_LOT_INDICATOR';
export const updateLotIndicator = ({ idlot, currency, result, error, loading = false }) => ({
  type: ARTDBWEB_LOT_INDICATOR,
  currency,
  idlot,
  result,
  error,
  loading,
});

export function indicatorAction({ idlot, currency }) {
  return async dispatch => {
    dispatch(updateLotIndicator({ currency, idlot, loading: true }));
    try {
      const result = await indicatorService({ idlot, currency });
      dispatch(updateLotIndicator({ currency, idlot, loading: false, result }));
    } catch (err) {
      dispatch(
        updateLotIndicator({
          currency,
          idlot,
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}
