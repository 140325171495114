export const UPDATE_DEFAULT_FACETS = 'search/UPDATE_DEFAULT_FACETS';
/**
 * Dispatch du store des facets initiales
 * @param facets -  array des facetes
 * @param params - params de la recherche
 * @param searchScope - scope de la recherche (lots, ...)
 * @returns {{payload: {facets: *, params: *, searchScope: string}, type: string}}
 */
export const updateDefaultFacets = ({ facets, params, searchScope }) => ({
  type: UPDATE_DEFAULT_FACETS,
  payload: {
    facets,
    params,
    searchScope,
  },
});
