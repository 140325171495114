import { noFalsy } from '../../utils/common/filters';
import { internalAxios } from '../../utils/axios/internal';

const filtersToQueryString = ({ idcountry, from, to, page, perPage, offset }) => {
  const filters = [];
  if (idcountry) filters.push(`idcountry=${idcountry}`);
  if (from) filters.push(`from=${from}`);
  if (to) filters.push(`to=${to}`);
  if (page) filters.push(`page=${page}`);
  if (perPage) filters.push(`perPage=${perPage}`);
  if (offset) filters.push(`offset=${offset}`);

  if (filters.length <= 0) return '';
  return `?${filters.filter(noFalsy).join('&')}`;
};

export const getFutures = async filters => {
  const response = await internalAxios.get(`/api/wallet/exposition/futures${filtersToQueryString(filters)}`);
  return response.data;
};

export const getNotPartners = async filters => {
  const response = await internalAxios.get(`/api/wallet/exposition/not_partner${filtersToQueryString(filters)}`);
  return response.data;
};

export const getPasts = async filters => {
  const response = await internalAxios.get(`/api/wallet/exposition/past${filtersToQueryString(filters)}`);
  return response.data;
};

export const getPastsNotPartners = async filters => {
  const response = await internalAxios.get(`/api/wallet/exposition/past_not_partner${filtersToQueryString(filters)}`);
  return response.data;
};

export const getHPEvents = async () => {
  const response = await internalAxios.get('/api/wallet/exposition/homepage_events');
  return response.data;
};

export const get = async ({ idevent }) => {
  if (!idevent) return null;

  const response = await internalAxios.get(`/api/wallet/exposition/get?idevent=${idevent}`);
  return response.data;
};

export const getCountryLabels = async ({ idcountry }) => {
  if (!idcountry) return null;

  const response = await internalAxios.get(`/api/wallet/exposition/get_country_labels?idcountry=${idcountry}`);
  return response.data;
};

export const getWPData = async ({ slug, lang }) => {
  if (!slug || !lang) return null;

  const response = await internalAxios.get(`/api/wallet/exposition/get_wordpress_data?slug=${slug}&lang=${lang}`);
  return response.data;
};

export const getWPMedia = async ({ idmedia }) => {
  if (!idmedia) return null;

  const response = await internalAxios.get(`/api/wallet/exposition/get_wordpress_media?idmedia=${idmedia}`);
  return response.data;
};

export const getThumbnailFromSlug = async ({ slug }) => {
  if (!slug) return null;

  const response = await internalAxios.get(`/api/wallet/exposition/get_wordpress_thumbnail?slug=${slug}`);
  return response.data;
};
