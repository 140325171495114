/*  eslint-disable */
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getIdartist } from './artist';
import { getCurrency } from '../preferences';

export const createChartKey = ({ chartTab, idcountry = '', minYear, maxYear, minPrice, maxPrice }) =>
  `${chartTab}-${idcountry}-${Number.parseInt(minYear, 10) || ''}-${Number.parseInt(maxYear, 10) || ''}-${Number.parseInt(minPrice, 10) || ''}-${Number.parseInt(maxPrice, 10) || ''}`;

const getMedium = state => state.indexes.medium;

export const getMediumDisplayedChartTab = state => state.indexes.medium.displayedChartTab;
export const getMediumDisplayedChartType = state => state.indexes.medium.displayedChartType;
export const getMediumOptions = state => state.indexes.medium.options;

// Price segments data
export const isMediumDataLoading = createSelector(
  getMedium,
  getIdartist,
  getCurrency,
  getMediumDisplayedChartTab,
  getMediumOptions,
  (medium, idartist, currency, chartTab, options) => get(medium, `${idartist}.${currency}.${createChartKey({ chartTab, ...options })}.loading`),
);

export const getMediumData = createSelector(
  getMedium,
  getIdartist,
  getCurrency,
  getMediumDisplayedChartTab,
  getMediumOptions,
  (medium, idartist, currency, chartTab, options) => get(medium, `${idartist}.${currency}.${createChartKey({ chartTab, ...options })}.data`),
);
