import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/utils';

const homepageSlice = createSlice({
  name: 'header',
  initialState: {
    ails: {},
    articles: [],
    classifieds: [],
    events: [],
    reports: { items: [], main: null },
    stores: [],
    promotion: undefined,
  },
  reducers: {},
});

/* Selectors */

export const getStores = state => get(state, 'homepage.stores', EMPTY_OBJECT);
export const getEvents = state => get(state, 'homepage.events', EMPTY_OBJECT);
export const getClassifieds = state => get(state, 'homepage.classifieds', EMPTY_OBJECT);
export const getPromotion = state => get(state, 'homepage.promotion', EMPTY_OBJECT);
export const getMainReport = state => get(state, 'homepage.reports.main', EMPTY_OBJECT);
export const getReports = state => get(state, 'homepage.reports.items', EMPTY_OBJECT);
export const getArticles = state => get(state, 'homepage.articles', EMPTY_OBJECT);
export const getSaleAils = state => get(state, 'homepage.ails.sales', EMPTY_ARRAY);
export const getMegaBanAils = state => get(state, 'homepage.ails.megaBan');
export const getFocusOnAils = state => get(state, 'homepage.ails.focusOn');

/* default export */

export default homepageSlice.reducer;
