export const ON = 'ui/switched/on';
export const on = id => ({
  type: ON,
  payload: {
    id,
  },
});

export const OFF = 'ui/switched/off';
export const off = id => ({
  type: OFF,
  payload: {
    id,
  },
});

export const TOGGLE = 'ui/switched/toggle';
export const toggle = id => ({
  type: TOGGLE,
  payload: {
    id,
  },
});
