/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { getPhysicproducts as getPhysicproductsService } from '../../../services/wallet/estimate';

const estimatesSlice = createSlice({
  name: 'estimates',
  initialState: {
    physicproductsLoading: false,
    products: null,
    customerCurrency: null,
    promotion: null,
  },
  reducers: {
    updatePhysicproductsLoading(state, action) {
      state.physicproductsLoading = action.payload;
    },
    updatePhysicproducts(state, action) {
      state.products = action.payload;
    },
    updateCustomerCurrency(state, action) {
      state.customerCurrency = action.payload;
    },
    updatePromotion(state, action) {
      state.promotion = action.payload;
    },
  },
});

export const { updatePhysicproductsLoading, updateCustomerCurrency, updatePhysicproducts, updatePromotion } = estimatesSlice.actions;

/* Selectors */

export const getCustomerCurrency = state => get(state.estimates, 'customerCurrency', {});
export const getPhysicproducts = state => get(state.estimates, 'products', []);
export const getPhysicproductsLoading = state => get(state.estimates, 'physicproductsLoading', false);
export const getPromotion = state => get(state.estimates, 'promotion');

export const retrievePhysicproducts =
  ({ promocode }) =>
  async (dispatch, getState) => {
    const loading = getPhysicproductsLoading(getState());

    if (!loading) {
      dispatch(updatePhysicproductsLoading(true));
      try {
        const { items, customerCurrency, promotion_from_promocode: promotionFromPromocode } = await getPhysicproductsService({ promocode });
        dispatch(updatePhysicproducts(items));
        dispatch(updateCustomerCurrency(customerCurrency));
        dispatch(updatePromotion(promotionFromPromocode));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(updatePhysicproductsLoading(false));
      }
    }
  };

export default estimatesSlice.reducer;
