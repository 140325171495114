import { createNextState } from '@reduxjs/toolkit';
import { concat, uniq } from 'lodash';
import { CLEAR_RESULTS_DATA, SET_LOADING_RESULTS, SET_RESULTS_DATA } from '../../actions/search/results';
import { ensureScopedAction } from '../../../utils/search/reducer';

function getInitialState() {
  return {};
}

export default function resultsReducer(state = getInitialState(), action) {
  const { type, payload } = action;
  return createNextState(state, draft => {
    switch (type) {
      case SET_LOADING_RESULTS:
        ensureScopedAction(action);
        if (!draft[payload.searchScope]) {
          draft[payload.searchScope] = {};
        }
        draft[payload.searchScope].loading = payload.loading;
        break;
      case SET_RESULTS_DATA:
        ensureScopedAction(action);
        if (!draft[payload.searchScope]) {
          draft[payload.searchScope] = {};
        }
        if (payload.infiniteScroll && state[payload.searchScope].data) {
          Object.entries(state[payload.searchScope].data).forEach(([key, value]) => {
            if (Array.isArray(value) && Array.isArray(payload.results[key])) {
              draft[payload.searchScope].data[key] = uniq(concat(state[payload.searchScope].data[key], payload.results[key]));
            }
          });
        } else {
          draft[payload.searchScope].data = payload.results;
        }
        if (payload.loading !== undefined) {
          draft[payload.searchScope].loading = payload.loading;
        }
        break;
      case CLEAR_RESULTS_DATA:
        ensureScopedAction(action);
        if (!draft[payload.searchScope]) {
          draft[payload.searchScope] = {};
        }
        delete draft[payload.searchScope].data;
        break;
      default:
        return draft;
    }
  });
}
