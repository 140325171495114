import { combineReducers } from 'redux';
import viewportReducer from './viewport';
import scrollReducer from './scroll';
import switchesReducer from './switches';
import dictionaryReducer from './dictionary';
import asyncStatusReducer from './asyncStatus';

export default combineReducers({
  scroll: scrollReducer,
  viewport: viewportReducer,
  switches: switchesReducer,
  dictionary: dictionaryReducer,
  asyncStatus: asyncStatusReducer,
});
