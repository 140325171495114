import { combineReducers } from 'redux';
import top from './top';
import list from './list';
import stats from './stats';

export default combineReducers({
  top,
  list,
  stats,
});
