import { intersection, get, isEqual, pick } from 'lodash';
import { ACTIVE_SEARCH_PARAMS } from '../../../constants/search';

const defaultUrlParams = {};
const defaultDraftParams = {};
const defaultDefaultFacets = {};
const defaultDraftFacets = {};
const defaultUrlFacets = {};

export const getDraftFacets = (state, { searchScope }) => get(state.search, `draftFacets.${searchScope}`, defaultDraftFacets);
export const getDefaultFacets = (state, { searchScope }) => get(state.search, `defaultFacets.${searchScope}`, defaultDefaultFacets);
export const getDraftParams = (state, { searchScope }) => get(state.search, `draftParams.${searchScope}`, defaultDraftParams);
export const getUrlParams = (state, { searchScope }) => get(state.search, `urlParams.${searchScope}`, defaultUrlParams);
export const getUrlFacets = (state, { searchScope }) => get(state.search, `urlFacets.${searchScope}`, defaultUrlFacets);
export const getLoadingFacets = (state, { searchScope }) => get(state.search, `info.${searchScope}.loadingFacets`);

export const isSearchPerformed = (state, { searchScope, excludedParams = [] }) =>
  intersection(
    Object.keys(getUrlParams(state, { searchScope })),
    get(ACTIVE_SEARCH_PARAMS, searchScope, []).filter(param => !excludedParams.includes(param)),
  ).length > 0;

export const isDraftDirty = (state, { searchScope }) => {
  const urlSearchParams = pick(getUrlParams(state, { searchScope }), get(ACTIVE_SEARCH_PARAMS, searchScope, []));
  const draftSearchParams = pick(getDraftParams(state, { searchScope }), get(ACTIVE_SEARCH_PARAMS, searchScope, []));
  return !isEqual(urlSearchParams, draftSearchParams);
};

export const getDraftTotalCount = (state, { searchScope, facetName }) => {
  const facets = getDraftFacets(state, { searchScope });
  if (facets[facetName]) {
    return Object.values(facets[facetName]).reduce((a, b) => a + b.count, 0);
  }

  return 0;
};

export const getDefaultTotalCount = (state, { searchScope, facetName }) => {
  const facets = getDefaultFacets(state, { searchScope });
  if (facets[facetName]) {
    return Object.values(facets[facetName]).reduce((a, b) => a + b.count, 0);
  }

  return 0;
};

export const getUrlTotalCount = (state, { searchScope, facetName }) => {
  const facets = getUrlFacets(state, { searchScope });
  if (facets[facetName]) {
    return Object.values(facets[facetName]).reduce((a, b) => a + b.count, 0);
  }

  return 0;
};

export const getCurrentScope = state => get(state.search, 'info.currentScope', null);

export const getCurrentLoadingFacets = state => {
  const searchScope = getCurrentScope(state);
  return getLoadingFacets(state, { searchScope });
};
export const getCurrentDraftFacets = state => {
  const searchScope = getCurrentScope(state);
  return getDraftFacets(state, { searchScope });
};
export const getCurrentDefaultFacets = state => {
  const searchScope = getCurrentScope(state);
  return getDefaultFacets(state, { searchScope });
};
export const getCurrentDraftParams = state => {
  const searchScope = getCurrentScope(state);
  return getDraftParams(state, { searchScope });
};
export const getCurrentUrlParams = state => {
  const searchScope = getCurrentScope(state);
  return getUrlParams(state, { searchScope });
};
export const getCurrentUrlFacets = state => {
  const searchScope = getCurrentScope(state);
  return getUrlFacets(state, { searchScope });
};
export const isCurrentSearchPerformed = (state, excludedParams = []) => {
  const searchScope = getCurrentScope(state);
  return isSearchPerformed(state, { searchScope, excludedParams });
};
export const isCurrentDraftDirty = state => {
  const searchScope = getCurrentScope(state);
  return isDraftDirty(state, { searchScope });
};

export const getCurrentDefaultTotalCount = (state, facetName) => {
  const searchScope = getCurrentScope(state);
  return getDefaultTotalCount(state, { searchScope, facetName });
};
export const getCurrentUrlTotalCount = (state, facetName) => {
  const searchScope = getCurrentScope(state);
  return getUrlTotalCount(state, { searchScope, facetName });
};
export const getCurrentDraftTotalCount = (state, facetName) => {
  const searchScope = getCurrentScope(state);
  return getDraftTotalCount(state, { searchScope, facetName });
};
