import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const artpriceSlice = createSlice({
  name: 'artprice',
  initialState: {
    isCustomerServiceOpened: undefined,
    returnAfterLogin: undefined,
  },
  reducers: {},
});

/* Selectors */

export const getIsCustomerServiceOpened = state => get(state.artprice, 'isCustomerServiceOpened', false);
export const getReturnAfterLogin = state => get(state.artprice, 'returnAfterLogin', true);
export default artpriceSlice.reducer;
