import { get } from 'lodash';

import { getLang } from '../../selectors/preferences';

// service
import * as service from '../../../services/events';

// selector
import {
  getCountryLabels,
  areCountryLabelsLoading,
  getSelectedEventId,
  getSelectedEvent,
  isSelectedEventLoading,
  getWPData,
  getWPMedia,
  isWPMediaLoading,
  isWPMediaFromSlugLoading,
  getHPEventsIds,
  areHPEventsLoading,
  isWPDataLoading,
  isStoreLoading,
  getCountryFilter,
  getStore,
  getFuturesIds,
  getNotPartnersIds,
  getPastsNotPartnersIds,
  getPastsIds,
  areFuturesLoading,
  areNotPartnersLoading,
  arePastsLoading,
  arePastsNotPartnersLoading,
  getDatesFilter,
  getPageFilter,
  getIdmediaFromSlug,
} from '../../selectors/events';

import { FUTURE, HOME_PAGE, FUTURE_NOT_PARTNER, PAST, PAST_NOT_PARTNER, PER_PAGE, NOT_PARTNERS_PER_PAGE } from '../../../constants/events';

export const UPDATE_SELECTED_EVENT = 'events/UPDATE_SELECTED_EVENT';
export const updateSelectedEventAction = ({ id }) => ({
  type: UPDATE_SELECTED_EVENT,
  payload: {
    id,
  },
});

export const STORE_EVENTS = 'events/STORE_EVENTS';
export const storeEvents = ({ loading, data, error }) => ({
  type: STORE_EVENTS,
  payload: {
    loading,
    data,
    error,
  },
});

export const SAVE_TOTAL_COUNT = 'events/SAVE_TOTAL_COUNT';
export const saveTotalCount = ({ eventsType, totalCount }) => ({
  type: SAVE_TOTAL_COUNT,
  payload: {
    eventsType,
    totalCount,
  },
});

export const SAVE_EVENT = 'events/SAVE_EVENT';
export const saveEvent = ({ idevent, loading, data, error }) => ({
  type: SAVE_EVENT,
  payload: {
    idevent,
    loading,
    data,
    error,
  },
});

export function fetchSelectedEventAction() {
  return async (dispatch, getState) => {
    const state = getState();
    const idevent = getSelectedEventId(state);
    if (!idevent || getSelectedEvent(state) || isSelectedEventLoading(state)) return;

    dispatch(saveEvent({ idevent, loading: true }));

    try {
      const data = await service.get({ idevent });

      dispatch(saveEvent({ idevent, loading: false, data }));
    } catch (err) {
      dispatch(saveEvent({ idevent, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const SELECT_COUNTRY_FILTER = 'events/SELECT_COUNTRY_FILTER';
export const selectCountryFilterAction = ({ idcountry }) => ({
  type: SELECT_COUNTRY_FILTER,
  payload: { idcountry },
});

export const SELECT_DATES_FILTER = 'events/SELECT_DATES_FILTER';
export const selectDatesFilterAction = ({ from, to }) => ({
  type: SELECT_DATES_FILTER,
  payload: {
    from,
    to,
  },
});

export const SELECT_PAGE_FILTER = 'events/SELECT_PAGE_FILTER';
export const selectPageFilterAction = ({ page }) => ({
  type: SELECT_PAGE_FILTER,
  payload: {
    page,
  },
});

export const UPDATE_WP_DATA = 'events/UPDATE_WP_DATA';
export const updateWPData = ({ lang, loading, data, error }) => ({
  type: UPDATE_WP_DATA,
  payload: {
    lang,
    loading,
    data,
    error,
  },
});

export function fetchWordpressDataAction() {
  return async (dispatch, getState) => {
    const state = getState();
    const { slug } = getSelectedEvent(state) || {};
    if (!slug) return;
    const lang = getLang(state);
    if (!lang) return;
    if (getWPData(state) || isWPDataLoading(state)) return;

    dispatch(updateWPData({ lang, loading: true }));

    try {
      const data = await service.getWPData({ slug, lang });

      if (lang !== data.language) {
        dispatch(updateWPData({ lang, loading: false }));
      }

      dispatch(updateWPData({ lang: data.language || lang, loading: false, data }));
    } catch (err) {
      dispatch(updateWPData({ lang, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const UPDATE_WP_MEDIA = 'events/UPDATE_WP_MEDIA';
export const updateWPMedia = ({ idmedia, loading, data, error }) => ({
  type: UPDATE_WP_MEDIA,
  payload: {
    idmedia,
    loading,
    data,
    error,
  },
});

export function fetchWordpressMediaAction() {
  return async (dispatch, getState) => {
    const state = getState();
    const { main_image: idmedia } = getWPData(state) || {};
    if (!idmedia) return;
    if (getWPMedia(state, { idmedia }) || isWPMediaLoading(state, { idmedia })) return;

    dispatch(updateWPMedia({ idmedia, loading: true }));

    try {
      const data = await service.getWPMedia({ idmedia });

      dispatch(updateWPMedia({ idmedia, loading: false, data }));
    } catch (err) {
      dispatch(updateWPMedia({ idmedia, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const UPDATE_WP_THUMBNAIL = 'events/UPDATE_WP_THUMBNAIL';
export const updateWPThumbnail = ({ slug, loading, idmedia, error }) => ({
  type: UPDATE_WP_THUMBNAIL,
  payload: { slug, loading, error, idmedia },
});

export function fetchThumbnailAction({ slug }) {
  return async (dispatch, getState) => {
    const state = getState();
    if (!slug) return;

    try {
      if (getIdmediaFromSlug(state, { slug }) !== undefined || isWPMediaFromSlugLoading(state, { slug })) return;

      dispatch(updateWPThumbnail({ slug, loading: true }));

      const data = await service.getThumbnailFromSlug({ slug });
      const { id: idmedia = null } = data || {};

      dispatch(updateWPThumbnail({ slug, loading: false, idmedia }));

      if (getWPMedia(state, { idmedia })) return;
      dispatch(updateWPMedia({ idmedia, loading: false, data }));
    } catch (err) {
      dispatch(updateWPThumbnail({ slug, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const SAVE_COUNTRY_LABELS = 'events/SAVE_COUNTRY_LABELS';
export const saveCountryLabels = ({ idcountry, loading, data, error }) => ({
  type: SAVE_COUNTRY_LABELS,
  payload: {
    idcountry,
    loading,
    data,
    error,
  },
});

export function fetchCountryLabelsAction({ idcountry }) {
  return async (dispatch, getState) => {
    const state = getState();
    if (!idcountry || getCountryLabels(state, { idcountry }) || areCountryLabelsLoading(state, { idcountry })) return;

    dispatch(saveCountryLabels({ idcountry, loading: true }));

    try {
      const data = await service.getCountryLabels({ idcountry });

      dispatch(saveCountryLabels({ idcountry, loading: false, data }));
    } catch (err) {
      dispatch(saveCountryLabels({ idcountry, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const SAVE_EVENTS_IDS_OF_TYPE = 'events/SAVE_EVENTS_IDS_OF_TYPE';
export const saveEventsIdsOfType = ({ ids, eventsType, loading, error }) => ({
  type: SAVE_EVENTS_IDS_OF_TYPE,
  payload: {
    ids,
    eventsType,
    loading,
    error,
  },
});

export const formatAndStoreEvents = (dispatch, { state, events }) => {
  if (!dispatch || !events || events.length === 0) return;
  const store = getStore(state) || {};

  const data = {};
  events.forEach(event => {
    if (!store[event.id]) data[event.id] = event;
  });

  if (Object.keys(data).length > 0) {
    dispatch(storeEvents({ loading: false, data }));
  }
};

export const saveIds = (dispatch, { events, eventsType }) => {
  if (!dispatch || !eventsType) return;

  const ids = events && events.length > 0 ? events.map(event => event.id) : [];
  dispatch(saveEventsIdsOfType({ ids, eventsType, loading: false }));
};

export function fetchFutureEventsAction() {
  return async (dispatch, getState) => {
    const state = getState();

    if (get(getFuturesIds(state), 'length') > 0 || areFuturesLoading(state)) return;

    const idcountry = getCountryFilter(state);
    const { from, to } = getDatesFilter(state) || {};
    const page = getPageFilter(state);
    const perPage = PER_PAGE;

    dispatch(saveEventsIdsOfType({ eventsType: FUTURE, loading: true }));

    try {
      const { total_count: totalCount, events } = await service.getFutures({ idcountry, from, to, page, perPage });

      formatAndStoreEvents(dispatch, { state, events });
      saveIds(dispatch, { events, eventsType: FUTURE });

      dispatch(saveTotalCount({ eventsType: FUTURE, totalCount }));
    } catch (err) {
      dispatch(saveEventsIdsOfType({ eventsType: FUTURE, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export function fetchNotPartnerEventsAction({ offset = 0 } = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    if (get(getNotPartnersIds(state), 'length') > offset || areNotPartnersLoading(state)) return;

    const idcountry = getCountryFilter(state);
    const { from, to } = getDatesFilter(state) || {};
    const perPage = NOT_PARTNERS_PER_PAGE;

    dispatch(saveEventsIdsOfType({ eventsType: FUTURE_NOT_PARTNER, loading: true }));

    try {
      // if offset given get page 2 with perPage = offset, else take page 1
      const { total_count: totalCount, events } = await service.getNotPartners({ idcountry, from, to, perPage, offset });

      formatAndStoreEvents(dispatch, { state, events });
      saveIds(dispatch, { events, eventsType: FUTURE_NOT_PARTNER });

      dispatch(saveTotalCount({ eventsType: FUTURE_NOT_PARTNER, totalCount }));
    } catch (err) {
      dispatch(saveEventsIdsOfType({ eventsType: FUTURE_NOT_PARTNER, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export function fetchPastEventsAction() {
  return async (dispatch, getState) => {
    const state = getState();

    if (get(getPastsIds(state), 'length') > 0 || arePastsLoading(state)) return;

    const idcountry = getCountryFilter(state);
    const { from, to } = getDatesFilter(state) || {};
    const page = getPageFilter(state);
    const perPage = PER_PAGE;

    dispatch(saveEventsIdsOfType({ eventsType: PAST, loading: true }));

    try {
      const { total_count: totalCount, events } = await service.getPasts({ idcountry, from, to, page, perPage });

      formatAndStoreEvents(dispatch, { state, events });
      saveIds(dispatch, { events, eventsType: PAST });

      dispatch(saveTotalCount({ eventsType: PAST, totalCount }));
    } catch (err) {
      dispatch(saveEventsIdsOfType({ eventsType: PAST, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export function fetchPastNotPartnerEventsAction({ offset = 0 } = {}) {
  return async (dispatch, getState) => {
    const state = getState();

    if (get(getPastsNotPartnersIds(state), 'length') > offset || arePastsNotPartnersLoading(state)) return;

    const idcountry = getCountryFilter(state);
    const { from, to } = getDatesFilter(state) || {};
    const perPage = NOT_PARTNERS_PER_PAGE;

    dispatch(saveEventsIdsOfType({ eventsType: PAST_NOT_PARTNER, loading: true }));

    try {
      // if offset given get page 2 with perPage = offset, else take page 1
      const { total_count: totalCount, events } = await service.getPastsNotPartners({ idcountry, from, to, perPage, offset });

      formatAndStoreEvents(dispatch, { state, events });
      saveIds(dispatch, { events, eventsType: PAST_NOT_PARTNER });

      dispatch(saveTotalCount({ eventsType: PAST_NOT_PARTNER, totalCount }));
    } catch (err) {
      dispatch(saveEventsIdsOfType({ eventsType: PAST_NOT_PARTNER, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export function fetchEventsAction() {
  return async (dispatch, getState) => {
    const state = getState();
    if (isStoreLoading(state)) return;

    dispatch(storeEvents({ loading: true }));

    dispatch(fetchFutureEventsAction());
    dispatch(fetchNotPartnerEventsAction());
    dispatch(fetchPastEventsAction());
    dispatch(fetchPastNotPartnerEventsAction());

    dispatch(storeEvents({ loading: false }));
  };
}

export function fetchHPEventsAction() {
  return async (dispatch, getState) => {
    const state = getState();

    if (get(getHPEventsIds(state), 'length') > 0 || areHPEventsLoading(state)) return;
    dispatch(saveEventsIdsOfType({ eventsType: HOME_PAGE, loading: true }));

    try {
      const events = await service.getHPEvents();

      formatAndStoreEvents(dispatch, { state, events });
      saveIds(dispatch, { events, eventsType: HOME_PAGE });
    } catch (err) {
      dispatch(saveEventsIdsOfType({ eventsType: HOME_PAGE, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}
