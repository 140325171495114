import { createNextState } from '@reduxjs/toolkit';
import { ARTDBWEB_LOT_INDICATOR } from '../actions/indicator';

function getInitialState() {
  return {};
}

export default function indicatorReducer(state = getInitialState(), action) {
  return createNextState(state, draft => {
    switch (action.type) {
      case ARTDBWEB_LOT_INDICATOR: {
        const { idlot, currency, loading, result, error } = action;
        if (!Object.prototype.hasOwnProperty.call(draft, idlot)) {
          draft[idlot] = {};
        }
        if (!Object.prototype.hasOwnProperty.call(draft[idlot], currency)) {
          draft[idlot][currency] = {};
        }
        draft[idlot][currency].loading = loading;
        draft[idlot][currency].result = result;
        draft[idlot][currency].error = error;
        break;
      }
      default:
        return draft;
    }
  });
}
