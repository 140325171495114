import { pickBy, isNull, memoize, pick } from 'lodash';
import queryString from 'query-string';
import { MANAGED_LOCALES } from '../constants/locales';

export const queryStringToObject = params => {
  const paramsObject = queryString.parse(params);

  Object.keys(paramsObject).forEach(key => {
    if (key.match(/.*\[\]$/)) {
      paramsObject[key.replace('[]', '')] = paramsObject[key];
      delete paramsObject[key];
    }
  });

  // queryString parse returns a weird Object (without prototype)
  // The redux-toolkit dev validator want a real object.
  return { ...paramsObject };
};

/* memoized function returning the current value of the query string (with freezing to prevent modifying the memoized object */
const memoizedParamsFromUrl = memoize(url => Object.freeze(queryStringToObject(url)));
export const getCurrentQueryObject = () => memoizedParamsFromUrl(window.location.search);

export const objectToQueryString = params => {
  const paramsForQuery = { ...params };

  Object.keys(pickBy(paramsForQuery, value => !isNull(value))).forEach(key => {
    if (Array.isArray(paramsForQuery[key]) && !key.match(/.*\[\]$/)) {
      paramsForQuery[`${key}[]`] = paramsForQuery[key];
      delete paramsForQuery[key];
    }
  });

  return queryString.stringify(paramsForQuery);
};

export const domain = () => window.location.hostname.split('.').slice(-2).join('.');

export const localeFromUrl = () => {
  const fromUrl = window.location.hostname.split('.')[0] === 'www' ? 'en' : window.location.hostname.split('.')[0];
  if (!MANAGED_LOCALES.includes(fromUrl)) {
    // when accessing with site-artp.artprice.xxx for example
    console.warn('Unmanaged locale:', fromUrl);
    return 'en';
  }
  return fromUrl;
};

export const cleanPathname = pathname => {
  if (pathname?.startsWith('//')) {
    return pathname.replace('//', '/');
  }
  return pathname;
};

const QUERY_PARAMS_TO_KEEP = ['i18n_mode'];
export const updateUrlQuery = params => {
  // const qs = objectToQueryString({ ...getCurrentQueryObject(), ...params });
  const qo = getCurrentQueryObject();
  const qs = objectToQueryString({ ...pick(qo, QUERY_PARAMS_TO_KEEP), ...params });

  if (qs !== '' && !qs.startsWith('?')) {
    window.history.replaceState(window.history.state, '', `?${qs}`);
  } else {
    window.history.replaceState(window.history.state, '', cleanPathname(window.location.pathname));
  }
};
