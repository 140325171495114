import { TAB_CA, TAB_SOLD } from '../../constants/indexes/priceSegments';
import { internalAxios } from '../../utils/axios/internal';

export const priceSegmentsDataService = async ({ chartTab, idartist, idcurrency, idcountry, idcategory, minYear, maxYear, minPrice, maxPrice }) => {
  let paramsUrl = '';
  if (idartist) paramsUrl += `idartist=${idartist}&`;
  else console.error('ERROR: idartist =', idartist);

  if (idcurrency) paramsUrl += `idcurrency=${idcurrency}&`;
  else console.error('ERROR: idcurrency =', idcurrency);

  if (idcountry) paramsUrl += `idcountry=${idcountry}&`;
  if (idcategory) paramsUrl += `idcategory=${idcategory}&`;

  if (minYear !== undefined) paramsUrl += `min_year=${minYear}&`;
  if (maxYear !== undefined) paramsUrl += `max_year=${maxYear}&`;

  if (minPrice !== undefined) paramsUrl += `min_price=${minPrice}&`;
  if (maxPrice !== undefined) paramsUrl += `max_price=${maxPrice}&`;

  paramsUrl += 'group_by=price_segment';

  let apiAction = '';
  switch (chartTab) {
    case TAB_CA:
      apiAction = 'price_segments_ca';
      break;
    case TAB_SOLD:
      apiAction = 'price_segments_sold';
      break;
    default:
      throw new Error(`Unknown chartTab : ${chartTab}`);
  }

  const response = await internalAxios.get(`/api/artdbweb/indexes/${apiAction}?${paramsUrl}`);
  return response.data;
};
