import { get } from 'lodash';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/utils';

export const progress = (state, { id }) => get(state, `auctioneers.sales.progress.${id}`, EMPTY_OBJECT);
export const isProgressLoading = state => get(state, 'auctioneers.sales.progress.loading', false);

export const top5 = (state, { id }) => get(state, `auctioneers.sales.top5.${id}.idlots`, EMPTY_ARRAY);
export const isTop5Loading = (state, { id }) => get(state, `auctioneers.sales.top5.${id}.loading`, false);

export const geo = (state, { id }) => get(state, `auctioneers.sales.geo.${id}.data`, EMPTY_ARRAY);
export const isGeoLoading = (state, { id }) => get(state, `auctioneers.sales.geo.${id}.loading`, false);

export const push = (state, { id }) => get(state, `auctioneers.sales.push.${id}`, 0);
export const isPushLoading = state => get(state, 'auctioneers.sales.push.loading', false);

export const show = (state, { id }) => get(state, `auctioneers.sales.show.${id}`, 0);
export const isShowLoading = state => get(state, 'auctioneers.sales.show.loading', false);
