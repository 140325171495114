import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getLang } from '../preferences';
import {
  FUTURE,
  HOME_PAGE,
  FUTURE_NOT_PARTNER,
  PAST,
  PAST_NOT_PARTNER,
  DEFAULT_COUNTRY_FILTER,
  DEFAULT_DATE_START_FILTER,
  DEFAULT_DATE_EXPIRE_FILTER,
  DEFAULT_FILTERS,
  DEFAULT_PAGE_FILTER,
  PER_PAGE,
} from '../../../constants/events';

import { noFalsy } from '../../../utils/common/filters';
import { EMPTY_ARRAY } from '../../../constants/utils';

const getEvents = state => state.events;
export const getStore = state => get(state, 'events.store.data');
export const isStoreLoading = state => get(state, 'events.store.loading');

export const getFilters = state => get(state, 'events.filters');
export const getCountriesStore = state => get(state, 'events.countries');

export const getEventDetails = state => get(state, 'events.details');
export const getMediasStore = state => get(state, 'events.medias');

export const populateIdsFromStore = ({ ids, store }) => {
  if (!ids || ids.length <= 0) return EMPTY_ARRAY;
  return ids.map(id => get(store, `${id}`)).filter(noFalsy);
};

// Filter on country
export const getCountryFilter = createSelector(getFilters, filters => get(filters, 'country'));
export const getDatesFilter = createSelector(getFilters, filters => get(filters, 'dates'));
export const getPageFilter = createSelector(getFilters, filters => get(filters, 'page') || 1);

export const getEventsFilters = createSelector(
  getCountryFilter,
  getDatesFilter,
  (store, { page } = {}) => page || getPageFilter(store),
  (country, dates = {}, page) => {
    let filters = '';
    const { from, to } = dates;
    filters += country ? `country#${country}` : DEFAULT_COUNTRY_FILTER;
    filters += '&';
    filters += from ? `from#${from}` : DEFAULT_DATE_START_FILTER;
    filters += '&';
    filters += to ? `to#${to}` : DEFAULT_DATE_EXPIRE_FILTER;
    filters += '&';
    filters += page ? `page#${page}` : DEFAULT_PAGE_FILTER;
    return filters;
  },
);

// Events
// // Futures
export const getFuturesStore = createSelector(getEvents, getEventsFilters, (events, filters) => get(events, `${FUTURE}.${filters}`));
export const areFuturesLoading = createSelector(getFuturesStore, store => get(store, 'loading'));
export const getFuturesIds = createSelector(getFuturesStore, store => get(store, 'ids') || EMPTY_ARRAY);
export const getFuturesTotalCount = createSelector(getFuturesStore, store => get(store, 'totalCount'));
export const getFuturesIdsWithoutFilters = createSelector(getEvents, events => get(events, `${FUTURE}.${DEFAULT_FILTERS}.ids`));
export const getFutures = createSelector(getFuturesIds, getStore, (ids, store) => populateIdsFromStore({ ids, store }));

export const getCurrents = createSelector(getFutures, futures => {
  if (!futures || futures.length <= 0) return [];
  const today = new Date();
  return futures.filter(({ slug, dt_event_start: dtEventStart, dt_event_expire: dtEventExpire }) => !!slug && new Date(dtEventStart) <= today && new Date(dtEventExpire) >= today).slice();
});
export const getUpcomings = createSelector(getFutures, futures => {
  if (!futures || futures.length <= 0) return [];
  const today = new Date();
  return futures.filter(({ slug, dt_event_start: dtEventStart }) => !!slug && new Date(dtEventStart) > today);
});

export const getFuturesEventsPagesCount = createSelector(getFuturesTotalCount, futures => Math.ceil(futures / PER_PAGE));

// // Not partner
export const getNotPartnersStore = createSelector(
  getEvents,
  store => getEventsFilters(store, { page: 1 }),
  (events, filters) => get(events, `${FUTURE_NOT_PARTNER}.${filters}`),
);
export const areNotPartnersLoading = createSelector(getNotPartnersStore, store => get(store, 'loading'));
export const getNotPartnersIds = createSelector(getNotPartnersStore, store => get(store, 'ids'));
export const getNotPartnersTotalCount = createSelector(getNotPartnersStore, store => get(store, 'totalCount'));
export const getNotPartnersIdsWithoutFilters = createSelector(getEvents, events => get(events, `${FUTURE_NOT_PARTNER}.${DEFAULT_FILTERS}.ids`));
export const getNotPartners = createSelector(getNotPartnersIds, getStore, (ids, store) => populateIdsFromStore({ ids, store }));

// // Past
export const getPastsStore = createSelector(getEvents, getEventsFilters, (events, filters) => get(events, `${PAST}.${filters}`));
export const arePastsLoading = createSelector(getPastsStore, store => get(store, 'loading'));
export const getPastsIds = createSelector(getPastsStore, getPageFilter, (store, page) => {
  const ids = get(store, 'ids') || [];
  if (ids.length > (page - 1) * PER_PAGE) {
    return ids.slice((page - 1) * PER_PAGE, PER_PAGE);
  }
  return ids;
});
export const getPastsTotalCount = createSelector(getPastsStore, store => get(store, 'totalCount'));
export const getPastsIdsWithoutFilters = createSelector(getEvents, events => get(events, `${PAST}.${DEFAULT_FILTERS}.ids`));
export const getPasts = createSelector(getPastsIds, getStore, (ids, store) => populateIdsFromStore({ ids, store }));

export const getPastsEventsPagesCount = createSelector(getPastsTotalCount, pasts => Math.ceil(pasts / PER_PAGE));

// // Past not partner
export const getPastsNotPartnersStore = createSelector(
  getEvents,
  store => getEventsFilters(store, { page: 1 }),
  (events, filters) => get(events, `${PAST_NOT_PARTNER}.${filters}`),
);
export const arePastsNotPartnersLoading = createSelector(getPastsNotPartnersStore, store => get(store, 'loading'));
export const getPastsNotPartnersIds = createSelector(getPastsNotPartnersStore, store => get(store, 'ids'));
export const getPastsNotPartnersTotalCount = createSelector(getPastsNotPartnersStore, store => get(store, 'totalCount'));
export const getPastsNotPartnersIdsWithoutFilters = createSelector(getEvents, events => get(events, `${PAST_NOT_PARTNER}.${DEFAULT_FILTERS}.ids`));
export const getPastsNotPartners = createSelector(getPastsNotPartnersIds, getStore, (ids, store) => populateIdsFromStore({ ids, store }));

// // Homepage
export const getHPEventsStore = createSelector(getEvents, events => get(events, `${HOME_PAGE}.${DEFAULT_FILTERS}`));
export const areHPEventsLoading = createSelector(getHPEventsStore, store => get(store, 'loading'));
export const getHPEventsIds = createSelector(getHPEventsStore, store => get(store, 'ids'));
export const getHPEvents = createSelector(getHPEventsIds, getStore, (ids, store) => populateIdsFromStore({ ids, store }));

// Event details
export const getSelectedEventId = createSelector(getEventDetails, details => get(details, 'selectedEvent'));

export const getSelectedEvent = createSelector(getEventDetails, getSelectedEventId, (details, selectedEventId) => get(details, `${selectedEventId}.data`));

export const isSelectedEventLoading = createSelector(getEventDetails, getSelectedEventId, (details, selectedEventId) => get(details, `${selectedEventId}.loading`));

// Wordpress
// // Data
export const getWPData = createSelector(
  getEventDetails,
  getSelectedEventId,
  getLang,
  (details, selectedEventId, lang) => get(details, `${selectedEventId}.WP/${lang}.data`) || get(details, `${selectedEventId}.WP/en.data`),
  // if WP/{lang} is not populated, we try WP/en as default
);
export const isWPDataLoading = createSelector(getEventDetails, getSelectedEventId, getLang, (details, selectedEventId, lang) => get(details, `${selectedEventId}.WP/${lang}.loading`));

// // Media
export const getWPMedia = (state, { idmedia } = {}) => get(getMediasStore(state), `${idmedia}.data`);
export const isWPMediaLoading = (state, { idmedia } = {}) => get(getMediasStore(state), `${idmedia}.loading`);

// // // Media from slug
export const getIdmediaFromSlug = (state, { slug } = {}) => get(getMediasStore(state), `bySlug.${slug}.idmedia`);

export const getWPMediaFromSlug = (state, { slug } = {}) => {
  const idmedia = getIdmediaFromSlug(state, { slug });
  if (!idmedia) return;
  return get(getEvents(state), `medias.${idmedia}.data`);
};
export const isWPMediaFromSlugLoading = (state, { slug } = {}) => {
  const idmedia = getIdmediaFromSlug(state, { slug });
  if (!idmedia) return;
  return get(getEvents(state), `medias.${idmedia}.loading`);
};

export const getFormatThumbnailLink = (state, { slug } = {}) => {
  const media = getWPMediaFromSlug(state, { slug });
  return get(media, 'formats.thumbnail.url');
};
export const getFormatMediumLink = (state, { slug } = {}) => {
  const media = getWPMediaFromSlug(state, { slug });
  return get(media, 'formats.full.url');
};
export const getOriginalSizeLink = (state, { slug } = {}) => {
  const media = getWPMediaFromSlug(state, { slug });
  return get(media, 'source');
};

// Country label(s)
export const getCountryLabels = (state, { idcountry } = {}) => get(getCountriesStore(state), `${idcountry}.data`);
export const getCountryLabel = (state, { idcountry } = {}) => {
  const lang = getLang(state);
  return get(getCountriesStore(state), `${idcountry}.data.${lang}`);
};
export const areCountryLabelsLoading = (state, { idcountry } = {}) => get(getCountriesStore(state), `${idcountry}.loading`);
const compareIds = (id1, id2) => parseInt(id1, 10) === parseInt(id2, 10);

export const getCountriesOptions = (state, { isPastList = false } = {}) => {
  const countries = getCountriesStore(state);
  const lang = getLang(state);

  const store = getStore(state);

  if (!countries) return EMPTY_ARRAY;
  const countriesArray = Object.entries(countries);
  if (countriesArray.length <= 0) return EMPTY_ARRAY;

  let ids = [];

  if (isPastList) {
    const pasts = getPastsIdsWithoutFilters(state) || [];
    const pastsNotPartners = getPastsNotPartnersIdsWithoutFilters(state) || [];
    ids = [...pasts, ...pastsNotPartners];
  } else {
    const futures = getFuturesIdsWithoutFilters(state) || [];
    const notPartners = getNotPartnersIdsWithoutFilters(state) || [];
    ids = [...futures, ...notPartners];
  }

  const events = populateIdsFromStore({ ids, store });

  return countriesArray
    .filter(([id]) => events.find(({ idcountry }) => compareIds(idcountry, id)))
    .map(([key, { data }]) => ({
      value: key,
      label: get(data, lang),
    }));
};
