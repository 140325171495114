import { Cookies } from 'react-cookie';
import { domain } from './http';

// Client side : memoized at the beginning then always reused ; server side : this just happens once at the start of the server (so no real performance problems here)
let memoizedCookie = new Cookies();
export const getCookies = () => memoizedCookie;

// This is used exclusively server side; it will setup a new Cookie instance for each new request.
// If the incoming request does not have cookies, we must ensure that a new Cookies instance is created (other the new request will use the previous Cookie instance)
export const setCookies = cookiesString => {
  if (cookiesString) {
    memoizedCookie = new Cookies(cookiesString);
  } else {
    memoizedCookie = new Cookies();
  }
};

export const get = name => getCookies().get(name);

export const set = (name, value, options = {}) => {
  getCookies().set(name, value, { domain: domain(), path: '/', ...options });
};
