/* eslint-disable no-return-await */
import { debounce } from 'lodash';
import { getCSRFParam, getCSRFToken } from '../../utils/csrf';
import { internalAxios } from '../../utils/axios/internal';
import xRequestIdManager from '../../utils/xRequestIdManager';

/*
aapi stands for Analytic Api -> it's the analytic system of Artprice (not using Google etc).
-> The associated controller is the following: api/analytics_controller.rb
-> It will send events to kafka, which will be used to create the "logstash-analytics" index


Literature:
https://css-tricks.com/send-an-http-request-on-page-exit/
 */

async function post(url, data) {
  if (window.fetch) {
    const headers = {
      'Content-Type': 'application/json',
    };
    if (!xRequestIdManager.isEmpty()) {
      headers['X-Trace-Request-Id'] = xRequestIdManager.get();
    }

    return await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
      keepalive: true,
    });
  }

  return await internalAxios.post(url, data, {
    headers: { 'content-type': 'application/json' },
  });
}

async function beacon(url, data) {
  if (navigator.sendBeacon) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    /* add x_trace_request_id when present */
    if (!xRequestIdManager.isEmpty()) {
      formData.append('X-Trace-Request-Id', xRequestIdManager.get());
    }

    /* probably useless since protect_from_forgery is disabled */
    const csrfParam = getCSRFParam();
    const csrfToken = getCSRFToken();
    if (csrfParam && csrfToken) {
      formData.append(csrfParam, csrfToken);
    }

    try {
      const sent = navigator.sendBeacon(url, formData);
      if (sent) {
        // if not sent, will continue to the fallback
        return true;
      }
    } catch (e) {
      console.warn(e);
    }
  }

  // fallback
  await post(url, data);
  return undefined;
}

/*
Standard aapi methods
 */

export const aapi = async (type, complements = {}) => post(`/aapi/log/${type}`, complements);

export const aapiBeacon = (type, complements = {}) => beacon(`/aapi/log/${type}`, complements);

/*
  Debounced (= prevent too much aapi calls)
  Not using beacon : formData doesn't support array of objects
  /!\ Call made after a location change may not be sent
 */

const LOGS = [];
const debouncedPost = debounce(() => {
  post('/aapi/logs', { logs: [...LOGS] });
  LOGS.splice(0, LOGS.length); // emptying array in place
}, 50);

export const delayedAapi = (type, complements = {}) => {
  LOGS.push({
    type,
    ...complements,
  });
  debouncedPost();
};

if (typeof window !== 'undefined') {
  if (!window.artprice) {
    window.artprice = {};
  }
  window.artprice.aapiBeacon = aapiBeacon;
  window.artprice.aapi = aapi;
}
