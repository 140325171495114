import { createNextState } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { AUCTIONEERS_UPDATE_STATS } from '../../actions/auctioneers/stats';

function getInitialState() {
  return {
    unauthorized: false,
    loaded: false,
    push: 0,
    future: {
      sales: 0,
      lots: 0,
    },
    past: {
      sales: 0,
      lots: 0,
    },
    error: '',
  };
}

export default function statsReducer(state = getInitialState(), action) {
  return createNextState(state, draft => {
    switch (action.type) {
      case AUCTIONEERS_UPDATE_STATS: {
        const { loading, push, future, past, error, unauthorized } = action;
        merge(draft, { loading, push, future, past, error, unauthorized });
        break;
      }
      default:
        return draft;
    }
  });
}
