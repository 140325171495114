import { getPromotion, arePromotionLoading } from '../../selectors/subscriptions/promotions';
import { get } from '../../../services/wallet/promotion';

export const UPDATE_PROMOTIONS = 'subscriptions/UPDATE_PROMOTIONS';
export const updatePromotions = ({ promotions, loading, error }) => ({
  type: UPDATE_PROMOTIONS,
  payload: {
    data: promotions,
    loading,
    error,
  },
});

export const fetchPromotion =
  ({ id }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (arePromotionLoading(state) || getPromotion(state, { id })) return;
    try {
      dispatch(updatePromotions({ loading: true }));
      const promotions = await get({ id });

      dispatch(updatePromotions({ loading: false, promotions }));
    } catch (error) {
      dispatch(updatePromotions({ loading: false, error }));
    }
  };
