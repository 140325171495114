import { createNextState } from '@reduxjs/toolkit';
import { UPDATE_AGREEMENT } from '../actions/agreements';

function getInitialState() {
  return {};
}

export default function salesReducer(state = getInitialState(), action) {
  return createNextState(state, draft => {
    switch (action.type) {
      case UPDATE_AGREEMENT: {
        const { idagreementtype, result, loading, error } = action;
        draft[idagreementtype] = { ...result, loading, error };
        break;
      }
      default:
        return draft;
    }
  });
}
