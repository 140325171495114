import { get } from 'lodash';
import { internalAxios } from '../../utils/axios/internal';

const priceIndexOptionsToURL = ({ idartist, idcurrency }) => {
  let paramsUrl = '';
  if (idartist) paramsUrl += `idartist=${idartist}&`;
  else console.error('ERROR: idartist =', idartist);

  if (idcurrency) paramsUrl += `idcurrency=${idcurrency}&`;
  else console.error('ERROR: idcurrency =', idcurrency);

  return paramsUrl;
};

export const artistDataService = async ({ idartist, idcurrency }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/price_index?${priceIndexOptionsToURL({ idartist, idcurrency })}`);
  return response.data;
};

export const globalIndexDataService = async ({ idartist, idcurrency }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/price_index_global_index?${priceIndexOptionsToURL({ idartist, idcurrency })}`);
  return response.data;
};

export const sAndP500DataService = async ({ idartist, idcurrency }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/price_index_s_and_p_500?${priceIndexOptionsToURL({ idartist, idcurrency })}`);
  return response.data;
};

export const cac40DataService = async ({ idartist, idcurrency }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/price_index_cac_40?${priceIndexOptionsToURL({ idartist, idcurrency })}`);
  return response.data;
};

export const daxDataService = async ({ idartist, idcurrency }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/price_index_dax?${priceIndexOptionsToURL({ idartist, idcurrency })}`);
  return response.data;
};

export const categoriesDataService = async ({ idcategory, idartist, idcurrency }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/price_index?idcategory=${idcategory}&${priceIndexOptionsToURL({ idartist, idcurrency })}`);
  return response.data;
};

export const artistSelectService = async ({ excludedIdArtist, term, page = 1, perPage = 10 }) => {
  const response = await internalAxios.post('/api/artdbweb/artist/json_search', { term, page, per_page: perPage });
  return get(response, 'data.artists', []).filter(({ id }) => id !== excludedIdArtist);
};
