import { isEmpty } from 'lodash';
import { internalAxios } from '../../utils/axios/internal';

// Pour le moment c'est inutilisé'
export const getSubscriptions = async () => {
  const response = await internalAxios.get('/api/stripe_subscriptions/get_subscriptions');
  return response.data;
};

export const getCustomer = async () => {
  const response = await internalAxios.get('/api/stripe_subscriptions/get_customer');
  return response.data;
};

export const getPlatforms = async ({ type, quantity, promocode, image, idphysicproducts, idestimates, idphysicsale }) => {
  if (isEmpty(idphysicproducts) && !idphysicsale) {
    console.error('no idphysicproducts');
    return {};
  }

  const response = await internalAxios.get('/api/wallet/physicproduct/platforms', { params: { type, quantity, promocode, image, idphysicproducts, idestimates, idphysicsale } });
  return response.data;
};
