import { internalAxios } from '../../utils/axios/internal';

export const progress = async ({ ids }) => {
  const response = await internalAxios.post('/api/auctioneers/sales/progress', { ids });
  return response.data;
};

export const sale = async ({ id }) => {
  const response = await internalAxios.get(`/api/auctioneers/sales/get/${id}`);
  return response.data;
};

export const top5 = async ({ id }) => {
  const response = await internalAxios.get(`/api/auctioneers/sales/top5/${id}`);
  return response.data;
};

export const geo = async ({ id }) => {
  const response = await internalAxios.get(`/api/auctioneers/sales/geo/${id}`);
  return response.data;
};

export const push = async ({ ids }) => {
  const response = await internalAxios.post('/api/auctioneers/sales/push', { ids });
  return response.data;
};

export const show = async ({ ids }) => {
  const response = await internalAxios.post('/api/auctioneers/sales/show', { ids });
  return response.data;
};
