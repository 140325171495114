import { createNextState } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { AUCTIONEERS_MEDIA_SOURCES } from '../../actions/auctioneers/medias';

function getInitialState() {
  return {};
}

export default function statsReducer(state = getInitialState(), action) {
  return createNextState(state, draft => {
    switch (action.type) {
      case AUCTIONEERS_MEDIA_SOURCES: {
        const { loading, data, error } = action;
        merge(draft, { loading, data, error });
        break;
      }
      default:
        return draft;
    }
  });
}
