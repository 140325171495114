import { internalAxios } from '../utils/axios/internal';

export const updateArtistLotsOrder = async ({ artistLotsSectionOrder }) => {
  const response = await internalAxios.post(`/api/account/preferences/ualo/${artistLotsSectionOrder}`);
  return response;
};

export const updateHideArtistFollowRecommendationsModaleUntil = async () => {
  const response = await internalAxios.post('/api/account/preferences/uharfmu');
  return response;
};
