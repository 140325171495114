import { updateArtists } from './artist';
import { updateAuctioneers } from './auctioneer';
import { updateCategories } from './category';
import { updateClassifieds } from './classified';
import { updateTechniques } from './technique';
import { updateCountries } from './country';
import { updateLots } from './lot';
import { updateSales } from './sale';
import { updateStores } from './store';
import { updateEntity } from './entity';
import { updateLotartistassociationtypes } from './lotartistassociationtype';

export const update =
  ({ lots, sales, artists, classifieds, categories, stores, auctioneers, techniques, countries, messages, messagetypes, lotartistassociationtypes, loading, error }, forceUpdate = false) =>
  dispatch => {
    if (sales) {
      dispatch(updateSales({ sales, loading, error }));
    }
    if (artists) {
      dispatch(updateArtists({ artists, loading, error }));
    }
    if (lots) {
      dispatch(updateLots({ lots, loading, error, forceUpdate }));
    }
    if (stores) {
      dispatch(updateStores({ stores, loading, error }));
    }
    if (classifieds) {
      dispatch(updateClassifieds({ classifieds, loading, error }));
    }
    if (auctioneers) {
      dispatch(updateAuctioneers({ auctioneers, loading, error }));
    }
    if (categories) {
      dispatch(updateCategories({ categories, loading, error }));
    }
    if (techniques) {
      dispatch(updateTechniques({ techniques, loading, error }));
    }
    if (lotartistassociationtypes) {
      dispatch(updateLotartistassociationtypes({ lotartistassociationtypes, loading, error }));
    }
    if (countries) {
      dispatch(updateCountries({ countries, loading, error }));
    }
    if (messages) {
      dispatch(updateEntity({ data: messages, entityName: 'messages', loading, error, forceUpdate }));
    }
    if (messagetypes) {
      dispatch(updateEntity({ data: messages, entityName: 'messagetypes', loading, error, forceUpdate }));
    }
  };
