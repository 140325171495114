import { createNextState } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { UPDATE_IDARTIST, UPDATE_ARTIST_INFOS, UPDATE_ARTIST_COUNTRIES, UPDATE_VISIBLE_YEARS, UPDATE_ARTIST_OPTIONS, UPDATE_DISPLAY } from '../../actions/indexes/artist';

function getInitialState() {
  return {};
}

export default function artistReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_IDARTIST:
        draft.idartist = payload.idartist;
        break;

      case UPDATE_ARTIST_INFOS: {
        const { idartist, loading, error, data } = payload;
        set(draft, `${idartist}.infos`, {
          loading,
          error,
          data,
        });
        break;
      }

      case UPDATE_ARTIST_OPTIONS: {
        const { idartist, currency, loading, error, data } = payload;
        set(draft, `${idartist}.${currency}.options`, {
          loading,
          error,
          data,
        });
        break;
      }

      case UPDATE_ARTIST_COUNTRIES: {
        const { idartist, loading, error, data } = payload;
        set(draft, `${idartist}.countries`, {
          loading,
          error,
          data,
        });
        break;
      }

      case UPDATE_VISIBLE_YEARS: {
        const { idartist, loading, error, data } = payload;
        set(draft, `${idartist}.visibleYears`, {
          loading,
          error,
          data,
        });
        break;
      }

      case UPDATE_DISPLAY: {
        const { idartist, loading, error, data } = payload;
        set(draft, `${idartist}.shouldDisplay`, {
          loading,
          error,
          data,
        });
        break;
      }

      default:
        return draft;
    }
  });
}
