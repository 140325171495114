import { fetch as fetchService } from '../../services/agreements';
import { isAgreementLoading, getAgreement } from '../selectors/agreements';

export const UPDATE_AGREEMENT = 'account/UPDATE_AGREEMENT';
export const updateAgreement = ({ idagreementtype, result, error, loading }) => ({
  type: UPDATE_AGREEMENT,
  idagreementtype,
  result,
  error,
  loading,
});

export function fetch({ idagreementtype, force }) {
  return async (dispatch, getState) => {
    const state = getState();
    if (isAgreementLoading(state, { idagreementtype }) || (!force && getAgreement(state, { idagreementtype }))) return;

    try {
      dispatch(updateAgreement({ idagreementtype, loading: true }));
      const data = await fetchService({ idagreementtype });

      dispatch(updateAgreement({ idagreementtype, result: data.result, loading: false }));
    } catch (err) {
      dispatch(
        updateAgreement({
          idagreementtype,
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}
