import {
  createBestClassifiedsKey,
  isBestClassifiedsLoaded,
  isBestClassifiedsLoading,
  isCountryRepartitionLoaded,
  isCountryRepartitionLoading,
  isViewedClassifiedsLoaded,
  isViewedClassifiedsLoading,
} from '../../../selectors/marketplace/stores/stats';
import { COUNTRY_REPARTITION_KEY, VIEWED_CLASSIFIEDS } from '../../../constants/marketplace/store/stats';
import { getBestClassifieds, getCountryRepartition, getViewedClassifieds } from '../../../../services/stats/marketplace';
import { formatFns } from '../../../../utils/dates/format';

export const CHOOSE_PERIOD = 'marketplace/stores/stats/CHOOSE_TOP_PERIOD';
export const choosePeriod = ({ start, end }) => ({
  type: CHOOSE_PERIOD,
  payload: {
    start,
    end,
  },
});

export const UPDATE = 'marketplace/stores/stats/UPDATE';
export const update = ({ key, value, error, loading = false }) => ({
  type: UPDATE,
  payload: {
    key,
    value,
    error,
    loading,
  },
});

export const fetchViewedClassifieds =
  ({ refresh = false } = {}) =>
  async (dispatch, getState) => {
    if (!refresh) {
      const state = getState();
      if (isViewedClassifiedsLoading(state) || isViewedClassifiedsLoaded(state) !== undefined) {
        return;
      }
    }
    dispatch(update({ key: VIEWED_CLASSIFIEDS, loading: true }));
    try {
      const result = await getViewedClassifieds();
      dispatch(update({ loading: false, key: VIEWED_CLASSIFIEDS, value: result }));
      const { start, end } = result[result.length - 1];
      dispatch(choosePeriod({ start, end }));
    } catch (err) {
      dispatch(
        update({
          loading: false,
          key: VIEWED_CLASSIFIEDS,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };

export const fetchCountryRepartition =
  ({ refresh = false } = {}) =>
  async (dispatch, getState) => {
    if (!refresh) {
      const state = getState();
      if (isCountryRepartitionLoading(state) || isCountryRepartitionLoaded(state) !== undefined) {
        return;
      }
    }
    dispatch(update({ key: COUNTRY_REPARTITION_KEY, loading: true }));
    try {
      const result = await getCountryRepartition();
      dispatch(update({ loading: false, key: COUNTRY_REPARTITION_KEY, value: result }));
    } catch (err) {
      dispatch(
        update({
          loading: false,
          key: COUNTRY_REPARTITION_KEY,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };

export const fetchBestClassifieds =
  ({ refresh = false, startDate, endDate = formatFns(new Date(), 'yyyy-MM-dd') } = {}) =>
  async (dispatch, getState) => {
    const params = { startDate, endDate };
    if (!refresh) {
      const state = getState();
      if (isBestClassifiedsLoading(state, params) || isBestClassifiedsLoaded(state, params) !== undefined) {
        return;
      }
    }
    const key = createBestClassifiedsKey(params);
    dispatch(update({ key, loading: true }));
    try {
      const result = await getBestClassifieds(params);
      dispatch(update({ loading: false, key, value: result }));
    } catch (err) {
      dispatch(
        update({
          loading: false,
          key,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };

export const fetchAllStats =
  ({ refresh = false } = {}) =>
  async dispatch => {
    dispatch(fetchViewedClassifieds({ refresh }));
    dispatch(fetchCountryRepartition({ refresh }));
  };
