import { get } from 'lodash';
import { SEARCH_ITEMS_PER_PAGE } from '../../constants/rails';

export const getArtistsViewFormat = state => get(state, 'preferences.artistsViewFormat');
export const getLotsViewFormat = state => get(state, 'preferences.lotsViewFormat');
export const getDimension = state => get(state, 'preferences.dimension');
export const getCurrency = state => get(state, 'preferences.currency')?.toLowerCase();
export const getFreshness = state => get(state, 'preferences.freshness');
export const getLang = state => get(state, 'preferences.lang');
export const getArtistLotsSectionOrder = state => get(state, 'preferences.artistLotsSectionOrder', 1);
export const getHideArtistFollowRecommendationsModaleUntil = state => get(state, 'preferences.hideArtistFollowRecommendationsModaleUntil');
export const getItemsPerpage = state => get(state, 'preferences.itemsPerPage', SEARCH_ITEMS_PER_PAGE[0]);
