import { createNextState } from '@reduxjs/toolkit';
import { AUCTIONEERS_SALES_PUSH, AUCTIONEERS_SALE_GEO, AUCTIONEERS_SALES_PROGRESS, AUCTIONEERS_SALE_TOP_5, AUCTIONEERS_SALES_SHOW } from '../../actions/auctioneers/sales';

function getInitialState() {
  return {
    progress: {},
    top5: {},
    geo: {},
    push: {},
    show: {},
  };
}

export default function salesReducer(state = getInitialState(), action) {
  return createNextState(state, draft => {
    switch (action.type) {
      case AUCTIONEERS_SALE_GEO: {
        const { result, id, loading, error } = action;
        draft.geo[id] = { data: result, loading, error };
        break;
      }
      case AUCTIONEERS_SALE_TOP_5: {
        const { idlots, id, loading, error } = action;
        draft.top5[id] = { idlots, loading, error };
        break;
      }
      case AUCTIONEERS_SALES_PROGRESS: {
        const { result, loading, error } = action;
        draft.progress = { ...result, loading, error };
        break;
      }
      case AUCTIONEERS_SALES_PUSH: {
        const { result, loading, error } = action;
        draft.push = { ...result, loading, error };
        break;
      }
      case AUCTIONEERS_SALES_SHOW: {
        const { result, loading, error } = action;
        draft.show = { ...result, loading, error };
        break;
      }
      default:
        return draft;
    }
  });
}
