import { get as getCookie, set as setCookie } from '../../utils/cookie';
import {
  UPDATE_CURRENCY_PREFERENCE,
  UPDATE_DIMENSION_PREFERENCE,
  UPDATE_LANG_PREFERENCE,
  UPDATE_PREFERENCE,
  UPDATE_FRESHNESS_PREFERENCE,
  UPDATE_ITEMS_PER_PAGE_PREFERENCE,
  UPDATE_LOTS_VIEW_FORMAT_PREFERENCE,
  UPDATE_ARTISTS_VIEW_FORMAT_PREFERENCE,
} from '../actions/preferences';
import { SEARCH_ITEMS_PER_PAGE } from '../../constants/rails';

function getInitialState() {
  const lotCurrency = getCookie('lot-currency') ? getCookie('lot-currency').toLowerCase() : 'usd';
  const lotDimension = getCookie('lot-dimension') ? getCookie('lot-dimension').toLowerCase() : 'cm';
  const customerLang = getCookie('customer-lang') ? getCookie('customer-lang').toLowerCase() : 'en';
  const lotsViewFormat = getCookie('lot-view') ? getCookie('lot-view').toLowerCase() : 'square';
  const artistsViewFormat = getCookie('artists-view') ? getCookie('artists-view').toLowerCase() : 'square';
  const freshness = getCookie('nbd') ? parseInt(getCookie('nbd'), 10) : 5;
  const ipp = getCookie('ipp') ? parseInt(getCookie('ipp'), 10) : SEARCH_ITEMS_PER_PAGE[0];

  return {
    dimension: lotDimension,
    currency: lotCurrency,
    lang: customerLang,
    itemsPerPage: ipp,
    freshness,
    lotsViewFormat,
    artistsViewFormat,
  };
}

export default function preferencesReducer(state = getInitialState(), action) {
  switch (action.type) {
    case UPDATE_CURRENCY_PREFERENCE: {
      setCookie('lot-currency', action.currency.toLowerCase());

      return {
        ...state,
        currency: action.currency.toLowerCase(),
      };
    }
    case UPDATE_DIMENSION_PREFERENCE: {
      setCookie('lot-dimension', action.dimension.toLowerCase());

      return {
        ...state,
        dimension: action.dimension.toLowerCase(),
      };
    }
    case UPDATE_LOTS_VIEW_FORMAT_PREFERENCE: {
      setCookie('lot-view', action.lotsViewFormat.toLowerCase());

      return {
        ...state,
        lotsViewFormat: action.lotsViewFormat.toLowerCase(),
      };
    }
    case UPDATE_ARTISTS_VIEW_FORMAT_PREFERENCE: {
      setCookie('artists-view', action.artistsViewFormat.toLowerCase());

      return {
        ...state,
        artistsViewFormat: action.artistsViewFormat.toLowerCase(),
      };
    }
    case UPDATE_LANG_PREFERENCE: {
      return {
        ...state,
        lang: action.lang.toLowerCase(),
      };
    }
    case UPDATE_FRESHNESS_PREFERENCE: {
      setCookie('nbd', action.freshness);

      return {
        ...state,
        freshness: action.freshness,
      };
    }
    case UPDATE_PREFERENCE: {
      const newState = { ...state };

      if (action.currency) {
        newState.currency = action.currency.toLowerCase();
      }
      if (action.lang) {
        newState.lang = action.lang.toLowerCase();
      }
      if (action.dimension) {
        newState.dimension = action.dimension.toLowerCase();
      }
      if (action.artistLotsSectionOrder) {
        newState.artistLotsSectionOrder = action.artistLotsSectionOrder;
      }
      if (action.hideArtistFollowRecommendationsModaleUntil) {
        newState.hideArtistFollowRecommendationsModaleUntil = action.hideArtistFollowRecommendationsModaleUntil;
      }

      return newState;
    }
    case UPDATE_ITEMS_PER_PAGE_PREFERENCE: {
      const itemsPerPage = Number.parseInt(action.itemsPerPage, 10);
      if (Number.isNaN(itemsPerPage)) {
        console.error(`Bad IPP: ${action.itemsPerPage}`);
      } else {
        setCookie('ipp', itemsPerPage);
        return {
          ...state,
          itemsPerPage,
        };
      }
      return state;
    }
    default:
      return state;
  }
}
