import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getIdartist } from './artist';
import { getCurrency } from '../preferences';

const getRanking = state => state.indexes.ranking;

export const isRankingDataLoading = createSelector(getRanking, getIdartist, getCurrency, (ranking, idartist, currency) => get(ranking, `${idartist}.${currency}.loading`));

export const getRankingData = createSelector(getRanking, getIdartist, getCurrency, (ranking, idartist, currency) => {
  const data = get(ranking, `${idartist}.${currency}.data`);
  if (data === undefined) {
    return undefined;
  }
  return Object.entries(data).sort(([yearA], [yearB]) => yearB - yearA);
});
