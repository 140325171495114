import React from 'react';

export const VERTICAL_BAR_CURRENT_ELEMENT_KEY = 'myArtpriceVerticalBarCurrentElementKey';
export const MYARTPRICE_DRAWER_ID = 'myartprice-vertical-bar';

export const SHORTCUT_SETTINGS_SECTIONS = 'myartprice/SHORTCUT_SECTIONS';
export const SHORTCUT_SETTINGS_CATEGORIES = 'myartprice/SHORTCUT_CATEGORIES';
export const SHORTCUT_SETTINGS_ARTISTS = 'myartprice/SHORTCUT_ARTISTS';
export const SHORTCUTS_SETTINGS = [SHORTCUT_SETTINGS_SECTIONS, SHORTCUT_SETTINGS_CATEGORIES, SHORTCUT_SETTINGS_ARTISTS];

// Summary
export const SHORTCUT_SUMMARY = 'myartprice/SHORTCUT_SUMMARY';
export const MYARTPRICE_SUMMARY_FILTER = 'myartprice-summary-filter';
export const MYARTPRICE_SUMMARY_NEWS_ONLY = 'myartprice-summary-news-only';

// Artists
export const SHORTCUT_LOTS_FUTURE_NEW = 'myartprice/SHORTCUT_LOTS_FUTURE_NEW';
export const SHORTCUT_LOTS_FUTURE_ALL = 'myartprice/SHORTCUT_LOTS_FUTURE_ALL';
export const SHORTCUT_LOTS_AUCTIONED_OFF = 'myartprice/SHORTCUT_LOTS_AUCTIONED_OFF';
export const SHORTCUT_LOTS_MARKETPLACE = 'myartprice/SHORTCUT_LOTS_MARKETPLACE';
export const SHORTCUTS_LOTS = [SHORTCUT_SUMMARY, SHORTCUT_LOTS_FUTURE_NEW, SHORTCUT_LOTS_FUTURE_ALL, SHORTCUT_LOTS_AUCTIONED_OFF, SHORTCUT_LOTS_MARKETPLACE];

// Lots
export const SHORTCUT_LOTS_FOLLOW_PAST = 'myartprice/SHORTCUT_LOTS_FOLLOW_PAST';
export const SHORTCUT_LOTS_FOLLOW_FUTURE = 'myartprice/SHORTCUT_LOTS_FOLLOW_FUTURE';
export const SHORTCUT_LOTS_FOLLOW_ALL = 'myartprice/SHORTCUT_LOTS_FOLLOW_ALL';
export const SHORTCUT_LOTS_FOLLOW_MARKETPLACE = 'myartprice/SHORTCUT_LOTS_FOLLOW_MARKETPLACE';
export const SHORTCUTS_LOTS_FOLLOW = [SHORTCUT_LOTS_FOLLOW_PAST, SHORTCUT_LOTS_FOLLOW_FUTURE, SHORTCUT_LOTS_FOLLOW_ALL, SHORTCUT_LOTS_FOLLOW_MARKETPLACE];

// Marketplace store
export const SHORTCUT_MARKETPLACE = 'myartprice/SHORTCUT_MARKETPLACE';
export const SHORTCUTS_MARKETPLACE = [SHORTCUT_MARKETPLACE];

// Lot Mobile drawer menus
export const SHORTCUT_SUBMENU_SORT = 'myartprice/SHORTCUT_SUBMENU_SORT';
export const SHORTCUT_SUBMENU_DISPLAY = 'myartprice/SHORTCUT_SUBMENU_DISPLAY';
export const SHORTCUT_SUBMENU_CATEGORIES = 'myartprice/SHORTCUT_SUBMENU_CATEGORIES';
export const SHORTCUT_SUBMENU_ARTIST = 'myartprice/SHORTCUT_SUBMENU_ARTIST';
export const SHORTCUT_SUBMENU_SETTINGS = 'myartprice/SHORTCUT_SUBMENU_SETTINGS';
export const SHORTCUT_SUBMENU_NEW = 'myartprice/SHORTCUT_SUBMENU_NEW';
