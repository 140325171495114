export const SET = 'ui/dictionary/set';

export const updateDictionary = (id, value) => ({
  type: SET,
  payload: {
    id,
    value,
  },
});

export function set(id, value) {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.ui.dictionary[id] === value) return;
    dispatch(updateDictionary(id, value));
  };
}
