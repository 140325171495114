import { internalAxios } from '../../utils/axios/internal';

export const artistInfosService = async ({ idartist }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/artist_infos?idartist=${idartist}`);
  return response.data;
};

export const artistOptionsService = async ({ idartist, idcurrency }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/artist_options?idartist=${idartist}&idcurrency=${idcurrency}`);
  return response.data;
};

export const indexesCountriesService = async ({ idartist }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/countries?idartist=${idartist}`);
  return response.data;
};

export const visibleYearsService = async ({ idartist }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/artist_visible_years?idartist=${idartist}`);
  return response.data;
};

export const shouldDisplayService = async ({ idartist }) => {
  const response = await internalAxios.get(`/api/artdbweb/indexes/artist_display?idartist=${idartist}`);
  return response.data;
};
