import { statSales as statSalesService } from '../../../services/auctioneers/stats';
import { areStatsLoading, stats } from '../../selectors/auctioneers/stats';

export const AUCTIONEERS_UPDATE_STATS = 'auctioneers/AUCTIONEERS_UPDATE_STATS';
export const updateStats = ({ push, future, past, unauthorized, error, loading = false }) => ({
  type: AUCTIONEERS_UPDATE_STATS,
  push,
  future,
  past,
  unauthorized,
  error,
  loading,
});

export function getStats() {
  return async (dispatch, getState) => {
    const state = getState();
    if (areStatsLoading(state) || stats(state).loaded) return;

    dispatch(updateStats({ loading: true }));
    try {
      const { push, future, past, unauthorized, error } = await statSalesService();
      dispatch(updateStats({ loading: false, push, future, past, unauthorized, error }));
    } catch (err) {
      dispatch(
        updateStats({
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}
