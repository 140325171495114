import { createNextState } from '@reduxjs/toolkit';
import { UPDATE_SCROLL } from '../../actions/ui/scroll';

function getInitialState() {
  return {};
}

export default function paramsReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_SCROLL:
        return payload;
      default:
        return draft;
    }
  });
}
