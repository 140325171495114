import { internalAxios } from '../../utils/axios/internal';

export const fetchArtists = async () => {
  const response = await internalAxios.get('/api/myartprice/artists/favorites');
  return response.data;
};

export const fetchSummary = async () => {
  const response = await internalAxios.get('/api/myartprice/artists/summary');
  return response.data;
};

export const followArtist = async id => {
  const response = await internalAxios.post('/api/myartprice/settings/follow', { id });
  return response.data;
};

export const unfollowArtist = async id => {
  const response = await internalAxios.post('/api/myartprice/settings/unfollow', { id });
  return response.data;
};
