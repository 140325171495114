import { set } from 'lodash';
import { getArtist, areArtistsLoading, getIdFreeArtist, isFreeArtistLoading } from '../../selectors/dbEntities';
import { free, getSome } from '../../../services/dbEntities/artist';

export const UPDATE_FREE_IDARTIST = 'dbEntities/UPDATE_FREE_IDARTIST';
export const updateFreeIdartist = ({ idartist, loading, error }) => ({
  type: UPDATE_FREE_IDARTIST,
  payload: {
    data: idartist,
    loading,
    error,
  },
});

export const UPDATE_ARTISTS = 'dbEntities/UPDATE_ARTISTS';
export const updateArtists = ({ artists, loading, error }) => ({
  type: UPDATE_ARTISTS,
  payload: {
    data: artists,
    loading,
    error,
  },
});

export const fetchArtists =
  ({ id, withDates = false }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (areArtistsLoading(state) || getArtist(state, { id })) return;
    try {
      dispatch(updateArtists({ loading: true }));

      const artists = {};
      const data = await getSome([id], withDates);
      set(artists, id, data);

      dispatch(updateArtists({ loading: false, artists }));
    } catch (error) {
      dispatch(updateArtists({ loading: false, error }));
    }
  };

export const fetchFreeArtist = () => async (dispatch, getState) => {
  const state = getState();
  if (isFreeArtistLoading(state) || getIdFreeArtist(state)) return;
  try {
    dispatch(updateFreeIdartist({ loading: true }));
    const data = await free();
    dispatch(updateArtists({ artists: data.artists }));
    dispatch(updateFreeIdartist({ loading: false, idartist: data.idartist[0] }));
  } catch (error) {
    dispatch(updateFreeIdartist({ loading: false, error }));
  }
};
