import { createNextState } from '@reduxjs/toolkit';
import { get, merge } from 'lodash';
import { UPDATE_PROMOTIONS } from '../../actions/subscriptions/promotion';
import { assignOnlyNewKeys } from '../../../utils/object';

function getInitialState() {
  return {};
}

export default function switchesReducer(state = getInitialState(), { type, payload }) {
  const { data, error, loading } = payload || {};
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_PROMOTIONS: {
        const newData = get(draft, 'data') || {};
        assignOnlyNewKeys(newData, data);
        merge(draft, { data: newData, error, loading });
        break;
      }
      default:
        return draft;
    }
  });
}
