import { createNextState } from '@reduxjs/toolkit';
import { SET } from '../../actions/ui/dictionary';

function getInitialState() {
  return {};
}

export default function dictionaryReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case SET: {
        draft[payload.id] = payload.value;
        break;
      }
      default:
        return draft;
    }
  });
}
