import { CancelToken } from 'axios';
import { get } from 'lodash';
import { cleanPathname, objectToQueryString } from '../../utils/http';
import {
  CLASSIFIEDS,
  MYARTPRICE_STORE,
  AUCTIONEER_SALE_LOTS,
  AUCTIONEER_PAST_SALES,
  AUCTIONEER_FUTURE_SALES,
  AUCTIONEER_SALE,
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FUTURE_NEW,
  MYARTPRICE_LOTS_MARKETPLACE,
  STORES,
  MYARTPRICE_MARKETPLACE,
  MARKETPLACE_MANAGE_MODIFICATION_REQUIRED,
  MARKETPLACE_MANAGE_OFFLINE,
  MARKETPLACE_MANAGE_MY_OFFERS,
  MARKETPLACE_MANAGE_WINNING_AUCTIONS,
  MARKETPLACE_MANAGE_ONLINE,
  MARKETPLACE_MANAGE_WAITING,
  MARKETPLACE_MANAGE_WITH_OFFERS,
  CLASSIFIEDS_IN_STORE,
  LOTS,
  TOTAL_ACCESS,
  TOTAL_ACCESS_GEO,
  TOTAL_ACCESS_CHRONO,
  TOTAL_ACCESS_LOTS,
  TOTAL_ACCESS_ARTISTS,
  TOTAL_ACCESS_AUCTIONEERS,
  ARTIST_LOTS_FUTURE,
  ARTIST_LOTS_PAST,
  ARTIST_LOTS_ALL,
  SALE_LOTS,
  MYARTPRICE_LOTS_HISTORY,
  SALES_FUTURES,
  ARTISTS,
} from '../../constants/search/scope';
import { internalAxios } from '../../utils/axios/internal';

const cancelMap = new Map();
const createMapKey = (searchScope, otherParams) => `${searchScope}_${otherParams.join('&')}`;

const baseFetch = async ({ params, searchScope, cancelPrevious = false, otherParams }) => {
  const queryString = objectToQueryString(params);
  if (!otherParams.includes('format=json')) {
    otherParams.push('format=json');
  }

  let baseUrl;
  switch (searchScope) {
    case MYARTPRICE_STORE:
      baseUrl = '/api/es/myartprice/store';
      break;
    case MARKETPLACE_MANAGE_MODIFICATION_REQUIRED:
      baseUrl = '/marketplace/manage/modification_required';
      break;
    case MARKETPLACE_MANAGE_OFFLINE:
      baseUrl = '/marketplace/manage/offline';
      break;
    case MARKETPLACE_MANAGE_MY_OFFERS:
      baseUrl = '/marketplace/manage/my_offers';
      break;
    case MARKETPLACE_MANAGE_WINNING_AUCTIONS:
      baseUrl = '/marketplace/manage/winning_auctions';
      break;
    case MARKETPLACE_MANAGE_ONLINE:
      baseUrl = '/marketplace/manage/online';
      break;
    case MARKETPLACE_MANAGE_WAITING:
      baseUrl = '/marketplace/manage/waiting';
      break;
    case MARKETPLACE_MANAGE_WITH_OFFERS:
      baseUrl = '/marketplace/manage/with_offers';
      break;
    case CLASSIFIEDS_IN_STORE:
      baseUrl = '';
      break;
    case CLASSIFIEDS:
      baseUrl = '/marketplace';
      break;
    case STORES:
      baseUrl = '/marketplace/store';
      break;
    case AUCTIONEER_SALE:
      baseUrl = '/api/auctioneers/sales/get';
      break;
    case AUCTIONEER_SALE_LOTS:
      baseUrl = '/api/es/auctioneers/lots_for_sale';
      break;
    case AUCTIONEER_FUTURE_SALES:
      baseUrl = '/api/es/auctioneers/future_sales';
      break;
    case AUCTIONEER_PAST_SALES:
      baseUrl = '/api/es/auctioneers/past_sales';
      break;
    case MYARTPRICE_LOTS_FUTURE_NEW:
      baseUrl = '/api/es/myartprice/lots_future_new';
      break;
    case MYARTPRICE_LOTS_FUTURE_ALL:
      baseUrl = '/api/es/myartprice/lots_future_all';
      break;
    case MYARTPRICE_LOTS_AUCTIONED_OFF:
      baseUrl = '/api/es/myartprice/lots_auctioned_off';
      break;
    case MYARTPRICE_LOTS_MARKETPLACE:
      baseUrl = '/api/myartprice/search/marketplace_lots';
      break;
    case MYARTPRICE_LOTS_FOLLOW_FUTURE:
      baseUrl = '/api/es/myartprice/future_lots_follow';
      break;
    case MYARTPRICE_LOTS_FOLLOW_PAST:
      baseUrl = '/api/es/myartprice/past_lots_follow';
      break;
    case MYARTPRICE_LOTS_FOLLOW_MARKETPLACE:
      baseUrl = '/api/es/myartprice/marketplace_lots_follow';
      break;
    case MYARTPRICE_MARKETPLACE:
      baseUrl = '/api/es/myartprice/marketplace';
      break;
    case TOTAL_ACCESS:
    case TOTAL_ACCESS_LOTS:
      baseUrl = '/api/es/lots/total_access';
      break;
    case TOTAL_ACCESS_ARTISTS:
      baseUrl = '/api/es/lots/total_access_artists';
      break;
    case TOTAL_ACCESS_AUCTIONEERS:
      baseUrl = '/api/es/lots/total_access_auctioneers';
      break;
    case TOTAL_ACCESS_CHRONO:
      baseUrl = '/api/es/lots/total_access_chrono';
      break;
    case TOTAL_ACCESS_GEO:
      baseUrl = '/api/es/lots/total_access_geo';
      break;
    case LOTS:
    case SALE_LOTS:
    case ARTIST_LOTS_ALL:
    case ARTIST_LOTS_PAST:
    case ARTIST_LOTS_FUTURE:
    case MYARTPRICE_LOTS_HISTORY:
    case SALES_FUTURES:
    case ARTISTS:
      baseUrl = cleanPathname(window.location.pathname);
      break;
    default:
      throw new Error(`Unknown searchScope: ${searchScope} in service fetchFacets`);
  }

  const url = `${baseUrl}?${otherParams.join('&')}${queryString !== '' ? '&' : ''}${queryString}`;

  const cancelMapKey = createMapKey(searchScope, otherParams);
  if (cancelPrevious && cancelMap.has(cancelMapKey)) {
    cancelMap.get(cancelMapKey).cancel(`Cancelling previous call [${cancelMapKey}]`);
  }
  const cancelSource = CancelToken.source();
  cancelMap.set(cancelMapKey, cancelSource);
  const response = await internalAxios.get(url, {
    cancelToken: cancelSource.token,
  });

  return response.data;
};

export const fetchFacets = async (params, searchScope, cancelPrevious = false) =>
  baseFetch({
    params,
    searchScope,
    cancelPrevious,
    otherParams: ['facets=1'],
  });

export const fetchResults = async (params, searchScope, cancelPrevious = false) =>
  baseFetch({
    params,
    searchScope,
    cancelPrevious,
    otherParams: ['results=1'],
  });

export const fetchFacetsAndResults = async (params, searchScope, cancelPrevious = false) =>
  baseFetch({
    params,
    searchScope,
    cancelPrevious,
    otherParams: ['results=1', 'facets=1'],
  });

export const artistSearch = async ({ excludedIdArtist, term, page = 1, perPage = 10, favorites = false }) => {
  const data = { term, page, per_page: perPage };
  if (favorites) {
    data.favorites = 1;
  }
  const response = await internalAxios.post('/api/artdbweb/artist/json_search', data);
  return get(response, 'data.artists', []).filter(({ id }) => id !== excludedIdArtist);
};

export const artistSearchOnKeyword = async ({ term, signal }) => {
  const response = await internalAxios.post('/api/artdbweb/artist/json_search', { term, page: 1, per_page: 6 }, { signal });
  return { data: get(response, 'data.artists', []), totalCount: get(response, 'data.count', []), outOfBounds: get(response, 'data.outOfBounds', false) };
};

export const lotSearchOnKeyword = async ({ term, signal }) => {
  const response = await internalAxios.post('/api/artdbweb/lot/search', { term }, { signal });
  return get(response, 'data', { totalCount: 0 });
};

export const classifiedSearchOnKeyword = async ({ term, signal }) => {
  const response = await internalAxios.post('/api/wallet/classified/search', { term }, { signal });
  return get(response, 'data', { totalCount: 0 });
};

export const reportSearchOnKeyword = async ({ term, signal }) => {
  const response = await internalAxios.post('/api/es/reports/search', { per_page: 2, keyword: term }, { signal });
  return get(response, 'data', { totalCount: 0 });
};

export const artmarketInsightSearchOnKeyword = async ({ term, signal }) => {
  const response = await internalAxios.post('/api/es/artmarketinsights/search', { per_page: 2, keyword: term }, { signal });
  return get(response, 'data', { totalCount: 0 });
};

export const auctioneerSearch = async ({ term, page = 1, perPage = 10 }) => {
  const data = { term, page, per_page: perPage };
  const response = await internalAxios.post('/api/artdbweb/auctioneer/simple_search', data);
  return get(response, 'data.auctioneers', []);
};
