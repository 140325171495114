import { createNextState } from '@reduxjs/toolkit';
import { set, setWith } from 'lodash';
import { UPDATE_PRICE_INDEX_ARTIST_DATA, UPDATE_PRICE_INDEX_OPTIONS, UPDATE_PRICE_INDEX_STOCK_INDEX_DATA, UPDATE_PRICE_INDEX_CATEGORIES_DATA } from '../../actions/indexes/priceIndex';

function getInitialState() {
  return {};
}

export default function priceIndexReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_PRICE_INDEX_OPTIONS:
        draft.options = { ...state.options, ...payload.options };
        break;

      case UPDATE_PRICE_INDEX_ARTIST_DATA: {
        const { idartist, currency, loading, error, data } = payload;
        set(draft, `${idartist}.${currency}.artist`, {
          loading,
          error,
          data,
        });
        break;
      }

      case UPDATE_PRICE_INDEX_STOCK_INDEX_DATA: {
        const { idartist, currency, stockIndex, loading, error, data } = payload;
        set(draft, `${idartist}.${currency}.${stockIndex}`, {
          loading,
          error,
          data,
        });
        break;
      }

      case UPDATE_PRICE_INDEX_CATEGORIES_DATA: {
        const { idcategory, idartist, currency, loading, error, data } = payload;
        setWith(
          draft,
          `${idartist}.${currency}.categories.${idcategory}`,
          {
            loading,
            error,
            data,
          },
          Object,
        );
        break;
      }

      default:
        return draft;
    }
  });
}
