import { internalAxios } from '../../../utils/axios/internal';

export const add = async id => {
  const response = await internalAxios.get(`/api/wallet/store/add_store_in_favorite/${id}.json`);
  return response.data;
};

export const remove = async id => {
  const response = await internalAxios.get(`/api/wallet/store/remove_store_from_favorite/${id}.json`);
  return response.data;
};

export const fetchFavoriteStores = async () => {
  const response = await internalAxios.get('/api/wallet/store/favorites');
  return response.data;
};
