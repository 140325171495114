import { createNextState } from '@reduxjs/toolkit';
import { UPDATE_SUBSCRIPTIONS_SETTINGS } from '../../actions/subscriptions/settings';

function getInitialState() {
  return {
    view: 'new',
    filter: 'subscription',
    image: true,
    quantity: 1,
    promocode: '',
    selectedIdphysicproduct: undefined,
    reload: 0,
    hasAuctioneerDiscount: false,
    hasLoyaltyDiscount: false,
    hasOnedayRefund: false,
  };
}

export default function switchesReducer(state = getInitialState(), { type, payload }) {
  const { data } = payload || {};
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_SUBSCRIPTIONS_SETTINGS: {
        return { ...draft, ...data };
      }
      default:
        return draft;
    }
  });
}
