import { send as sendService } from '../../../services/auctioneers/contact';
import { isSending } from '../../selectors/auctioneers/contact';

export const AUCTIONEERS_CLEAR_SENDING_STATUS = 'auctioneers/AUCTIONEERS_CLEAR_SENDING_STATUS';
export const clearSendingStatus = () => ({ type: AUCTIONEERS_CLEAR_SENDING_STATUS });

export const AUCTIONEERS_UPDATE_SENDING_STATUS = 'auctioneers/AUCTIONEERS_UPDATE_SENDING_STATUS';
export const updateSendingStatus = ({ data, error, sending = false }) => ({
  type: AUCTIONEERS_UPDATE_SENDING_STATUS,
  data,
  error,
  sending,
});

export function send(message = {}) {
  return async (dispatch, getState) => {
    const state = getState();

    if (isSending(state)) return;
    dispatch(updateSendingStatus({ sending: true }));

    try {
      const { unauthorized, sent } = await sendService(message);
      dispatch(updateSendingStatus({ data: { unauthorized, sent }, sending: false }));
    } catch (err) {
      dispatch(
        updateSendingStatus({
          sending: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}
