import { combineReducers } from 'redux';
import artistReducer from './artist';
import summaryReducer from './summary';
import priceIndexReducer from './priceIndex';
import chronologicalReducer from './chronological';
import geographicalReducer from './geographical';
import priceSegmentsReducer from './priceSegments';
import mediumReducer from './medium';
import rankingReducer from './ranking';

export default combineReducers({
  artist: artistReducer,
  summary: summaryReducer,
  priceIndex: priceIndexReducer,
  chronological: chronologicalReducer,
  geographical: geographicalReducer,
  priceSegments: priceSegmentsReducer,
  medium: mediumReducer,
  ranking: rankingReducer,
});
