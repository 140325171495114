/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const universalSlice = createSlice({
  name: 'universal',
  initialState: {
    layoutContainer: {
      open: false,
      transition: {
        origin: {
          selector: undefined,
        },
      },
    },
  },
  reducers: {
    openLayoutContainer(state) {
      state.layoutContainer.open = true;
    },
    closeLayoutContainer(state) {
      state.layoutContainer.open = false;
    },
    setTransitionOrigin(state, action) {
      state.layoutContainer.transition.origin = action.payload;
    },
    removeTransitionOrigin(state) {
      state.layoutContainer.transition.origin = {};
    },
  },
});

/* Selectors */

export const getTransitionOriginSelector = state => get(state.searchV2.universal, 'layoutContainer.transition.origin.selector');
export const isLayoutContainerOpen = state => get(state.searchV2.universal, 'layoutContainer.open', false);

/* Actions */

export const { openLayoutContainer, closeLayoutContainer, setTransitionOrigin, removeTransitionOrigin } = universalSlice.actions;

export default universalSlice.reducer;
