import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getCurrency } from '../preferences';
import { getIdartist } from './artist';

const getSummary = state => state.indexes.summary;

// Summary data
export const isSummaryDataLoading = createSelector(getSummary, getIdartist, getCurrency, (summary, idartist, currency) => get(summary, `${idartist}.${currency}.loading`));

export const getSummaryData = createSelector(getSummary, getIdartist, getCurrency, (summary, idartist, currency) => get(summary, `${idartist}.${currency}.data`));
