import { createNextState } from '@reduxjs/toolkit';
import { END_OF_MOUSE_HOVER_SELECTED_PARAMS, START_OF_MOUSE_HOVER_SELECTED_PARAMS } from '../../actions/marketplace/searchbars/ui';

function getInitialState() {
  return {
    overflownParams: {},
  };
}

export default function searchReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case END_OF_MOUSE_HOVER_SELECTED_PARAMS:
        draft.overflownParams = {};
        break;
      case START_OF_MOUSE_HOVER_SELECTED_PARAMS:
        draft.overflownParams = payload.overflownParams;
        break;
      default:
        return draft;
    }
  });
}
