import lodashGet from 'lodash/get';
import { differenceInDays, isAfter } from 'date-fns';
import { needsAction } from '../../../utils/subscriptions/attentionNeeded';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/utils';
import { CUSTOMER_EMAIL_STATUS_NONVERIFIED } from '../../../constants/rails';

export const get = state => state.userContext || EMPTY_OBJECT;
export const isLoaded = state => state.userContext.loaded || false;
export const isLoading = state => state.userContext.loading || false;

export const isMarketplaceLoaded = state => state.userContext?.marketplace?.loaded || false;
export const isMarketplaceLoading = state => state.userContext?.marketplace?.loading || false;

export const isEstimateLoaded = state => state.userContext?.estimate?.loaded || false;
export const isEstimateLoading = state => state.userContext?.estimate?.loading || false;
export const isLogged = state => state.userContext.logged || false;
export const getLang = state => state.userContext.lang;
export const isLoginAuto = state => state.userContext.loginAuto || false;
export const getIdcustomer = state => state.userContext.idcustomer;
export const getStripeCustomer = state => state.subscriptions.stripe.customer;
export const getIsAuctioneer = state => state.userContext.isAuctioneer || false;
export const getIsNonPartnerAuctioneer = state => state.userContext.isNonPartnerAuctioneer || false;
export const getAuctioneerAgreementSignedAt = state => state.userContext.auctioneerAgreement?.signedAt;
export const getAuctioneerAgreementLastVersion = state => state.userContext.auctioneerAgreement?.lastVersion;
export const getName = state => state.userContext.name;
export const getLogin = state => state.userContext.login;
export const getFtpLogin = state => state.userContext.ftpLogin;
export const getEmail = state => state.userContext.email;
export const getSubscriptionLevel = state => state.userContext.subscriptionLevel;
export const getIsArtpriceIp = state => state.userContext.artpriceIp;
export const getIsBot = state => state.userContext.bot;
export const getEmailStatus = state => state.userContext.emailStatus;
export const getIdprofessionaltype = state => state.userContext.idprofessionaltype;
export const getIsChildCustomer = state => state.userContext.isChildCustomer;
export const getIdstatutetype = state => state.userContext.idstatutetype;
export const getIdaccountrole = state => state.userContext.idaccountrole;
export const getCustomerVerificationStatus = state => state.userContext.customerVerificationStatus;
export const getUsersCount = state => state.userContext.activeSubscription?.usersCount;
export const getActiveSubscriptionName = state => state.userContext.activeSubscription?.name;
export const getActiveSubscription = state => state.userContext.activeSubscription;
export const getLastSubscription = state => state.userContext.lastSubscription;

export const hasModuleAccess = (state, { idmodule }) => {
  const activeSubscription = getActiveSubscription(state);
  if (activeSubscription) {
    return activeSubscription.modules?.includes(idmodule);
  }

  // special clause for one day
  const lastSubscription = getLastSubscription(state);
  if (lastSubscription && lastSubscription.nbDays === 1 && !lastSubscription.started) {
    return lastSubscription.modules?.includes(idmodule);
  }

  return false;
};

export const getVerifiedAt = state => state.userContext.verifiedAt;
export const getHasImageAccess = state => state.userContext.activeSubscription?.hasImageAccess;
export const getHasIndexesAccess = state => hasModuleAccess(state, { idmodule: 4 });
export const getDisplayedPromotionId = state => state.userContext.displayedPromotionId;
export const getPushEmail = state => state.userContext.pushEmail || false;
export const getFavoriteCount = state => state.userContext.favoriteCount || 0;
export const getNewsletter = state => state.userContext.newsletter || 0;
export const getOnlyLotsSignedByArtistInPush = state => state.userContext.onlyLotsSignedByArtistInPush || false;
export const getMarketplace = state => state.userContext.marketplace || EMPTY_OBJECT;
export const getMarketplaceHasClassifieds = state => getMarketplace(state).hasClassifieds || false;
export const getMarketplaceMessageCount = state => getMarketplace(state).messageCount || 0;
export const getMarketplaceAwaitingOfferSelection = state => getMarketplace(state).awaitingOfferSelection || EMPTY_ARRAY;
export const getMarketplaceAuctionsWithOngoingTransactions = state => getMarketplace(state).auctionsWithOngoingTransactions || EMPTY_ARRAY;
export const getModificationRequiredCount = state => lodashGet(getMarketplace(state), 'counts.modificationRequiredCount', 0);
export const getExpiredCount = state => lodashGet(getMarketplace(state), 'counts.expiredCount', 0);
export const getHasStore = state => state.userContext?.marketplace?.hasStore;
export const getCurrentBillCurrency = state => state.userContext.currentBillCurrency || 'eur';
export const getMessageCount = state => state.userContext.messageCount || 0;
export const getEstimate = state => state.userContext.estimate || EMPTY_OBJECT;
export const getEstimateWaitingPaymentsCount = state => lodashGet(getEstimate(state), 'counts.waitingPaymentsCount', 0);

/* * Notifications */
export const hasExpiredSubscription = state => {
  const lastSubscription = getLastSubscription(state);
  if (!lastSubscription?.dates) {
    return false;
  }

  if (isAfter(new Date(), new Date(lastSubscription.dates.dtExpire))) {
    // Special case: subscriptions that needs action ("abo en souffrance")
    // In this case, we do not want to indicate that the subscription is expired (the customer already has another notif).
    if (needsAction(lastSubscription.attentionNeeded)) {
      return false;
    }
    // Display 30 days after the expiration
    return differenceInDays(new Date(), new Date(lastSubscription.dates.dtExpire)) < 30;
  }
  return false;
};
export const subscriptionNeedsAction = state => needsAction(getLastSubscription(state)?.attentionNeeded);
export const mustCompleteSellerProfile = state => getMarketplace(state).displayVerificationLink || false;
export const mustRenameStore = state => getMarketplace(state).mustRenameStore || false;
export const mustCompleteStoreInformation = state => getMarketplace(state).mustCompleteStoreInformation || false;
export const storeHasAProblem = state => mustRenameStore(state) || mustCompleteStoreInformation(state); // en fait il ne faut les compter qu'une fois si jamais il y a les deux...

export const hasAlmostExpiredSubscription = state => {
  const lastSubscription = getLastSubscription(state);
  if (!lastSubscription?.dates) {
    return false;
  }
  if (lastSubscription.nbDays < 90) {
    // we would always show this warning for subscriptions who are < 90 days
    return false;
  }
  if (isAfter(new Date(), new Date(lastSubscription.dates.dtExpire))) {
    // we return false if the subscription is already expired
    return false;
  }

  return differenceInDays(new Date(lastSubscription.dates.dtExpire), new Date()) < 90;
};
export const hasNotSignedAuctioneerAgreement = state => {
  if (!getIsAuctioneer(state) && !getIsNonPartnerAuctioneer(state)) {
    return false;
  }

  return !getAuctioneerAgreementSignedAt(state);
};
export const isUndelivered = state => isLoaded(state) && state.userContext.isUndelivered;
export const mustFillEmail = state => isLoaded(state) && (getEmail(state) || '').trim() === '';
export const emailNeedsVerification = state => isLoaded(state) && !mustFillEmail(state) && getEmailStatus(state) === CUSTOMER_EMAIL_STATUS_NONVERIFIED;
export const hasClassifiedsWaitingForModification = state => getModificationRequiredCount(state) > 0;
export const hasExpiredClassifieds = state => getExpiredCount(state) > 0;
export const hasEstimatesWaitingForPayment = state => getEstimateWaitingPaymentsCount(state) > 0;
export const hasClassifiedsAwaitingOfferSelection = state => getMarketplaceAwaitingOfferSelection(state).length > 0;
export const hasAuctionsWithOngoingTransactions = state => getMarketplaceAuctionsWithOngoingTransactions(state).length > 0;

export const getNotificationsCount = state =>
  [
    isUndelivered,
    hasExpiredSubscription,
    subscriptionNeedsAction,
    mustCompleteSellerProfile,
    storeHasAProblem,
    hasAlmostExpiredSubscription,
    hasNotSignedAuctioneerAgreement,
    mustFillEmail,
    hasClassifiedsWaitingForModification,
    hasExpiredClassifieds,
    hasEstimatesWaitingForPayment,
    hasClassifiedsAwaitingOfferSelection,
    hasAuctionsWithOngoingTransactions,
  ]
    .map(selector => selector(state))
    .filter(Boolean).length;
