import { /* CREATE_MESSAGE, */ RETRIEVE_MESSAGES } from '../../../actions/marketplace/classifieds/messages';

export default function messagesReducer(state = {}, { type, payload }) {
  switch (type) {
    /* case CREATE_MESSAGE: {
      // const { message, idclassified, idcustomerInterlocutor } = payload;
      return state;
      // return { ...state, [idclassified]: { idclassified, interlocuter: idcustomerInterlocutor, messages: [...state[idclassified].messages, { body: message }] } };
    } */
    case RETRIEVE_MESSAGES: {
      return { ...state, [payload.idclassified]: payload };
    }
    default:
      return state;
  }
}
