/*  eslint-disable */
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getIdartist } from './artist';
import { getCurrency } from '../preferences';

export const createChartKey = ({ chartTab, idcategory = '', idcountry = '', minYear, maxYear, minPrice, maxPrice }) =>
  `${chartTab}-${idcategory}-${idcountry}-${Number.parseInt(minYear, 10) || ''}-${Number.parseInt(maxYear, 10) || ''}-${Number.parseInt(minPrice, 10) || ''}-${Number.parseInt(maxPrice, 10) || ''}`;

const getPriceSegments = state => state.indexes.priceSegments;

export const getPriceSegmentsDisplayedChartTab = state => state.indexes.priceSegments.displayedChartTab;
export const getPriceSegmentsDisplayedChartType = state => state.indexes.priceSegments.displayedChartType;
export const getPriceSegmentsOptions = state => state.indexes.priceSegments.options;

// Price segments data
export const isPriceSegmentsDataLoading = createSelector(
  getPriceSegments,
  getIdartist,
  getCurrency,
  getPriceSegmentsDisplayedChartTab,
  getPriceSegmentsOptions,
  (priceSegments, idartist, currency, chartTab, options) => get(priceSegments, `${idartist}.${currency}.${createChartKey({ chartTab, ...options })}.loading`),
);

export const getPriceSegmentsData = createSelector(
  getPriceSegments,
  getIdartist,
  getCurrency,
  getPriceSegmentsDisplayedChartTab,
  getPriceSegmentsOptions,
  (priceSegments, idartist, currency, chartTab, options) => get(priceSegments, `${idartist}.${currency}.${createChartKey({ chartTab, ...options })}.data`),
);
