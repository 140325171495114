import { combineReducers } from 'redux';
import draftParams from './draftParams';
import urlParams from './urlParams';
import defaultFacets from './defaultFacets';
import info from './info';
import draftFacets from './draftFacets';
import urlFacets from './urlFacets';
import results from './results';
import ui from './ui';

export default combineReducers({
  draftFacets,
  draftParams,
  defaultFacets,
  urlParams,
  urlFacets,
  info,
  results,
  ui,
});
