import { internalAxios } from '../../utils/axios/internal';

export const inbox = async (page = 1, perPage = 20) => {
  const response = await internalAxios.get(`/api/wallet/customer/inbox_messages.json?page=${page}&per=${perPage}`);
  return response.data;
};

export const trash = async (page = 1, perPage = 20) => {
  const response = await internalAxios.get(`/api/wallet/customer/deleted_messages.json?page=${page}&per=${perPage}`);
  return response.data;
};

export const sent = async (page = 1, perPage = 20) => {
  const response = await internalAxios.get(`/api/wallet/customer/sent_messages.json?page=${page}&per=${perPage}`);
  return response.data;
};

export const markRead = async id => {
  const response = await internalAxios.post('/api/wallet/private_message/mark_read.json', { id });
  return response.data;
};

export const create = async message => {
  const response = await internalAxios.post('/api/wallet/private_message/create.json', { message });
  return response.data;
};

export const moveToTrash = async id => {
  const response = await internalAxios.post('/api/wallet/private_message/delete.json', { id });
  return response.data;
};
