import { getCurrency } from '../../selectors/preferences';
import { CURRENCIES } from '../../../constants/preferences';

import { artistInfosService, artistOptionsService, indexesCountriesService, visibleYearsService, shouldDisplayService } from '../../../services/indexes/artist';
import {
  getIdartist,
  isArtistInfosLoading,
  isCountriesLoading,
  isArtistOptionsLoading,
  getArtistOptions,
  isVisibleYearsLoading,
  getCountries,
  getVisibleYears,
  getAllShouldDisplay,
  isDisplayLoading,
  getArtistInfos,
} from '../../selectors/indexes/artist';

export const UPDATE_IDARTIST = 'indexes/artist/UPDATE_IDARTIST';
export const updateIdartist = ({ idartist }) => ({
  type: UPDATE_IDARTIST,
  payload: {
    idartist,
  },
});

export const UPDATE_ARTIST_INFOS = 'indexes/artist/UPDATE_ARTIST_INFOS';
export const updateArtistInfos = ({ idartist, loading, data, error }) => ({
  type: UPDATE_ARTIST_INFOS,
  payload: {
    idartist,
    loading,
    data,
    error,
  },
});
export function artistInfosAction({ idartist: optionalIdartist } = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    const idartist = optionalIdartist || getIdartist(state);
    if (!idartist) return;

    if (isArtistInfosLoading(state, { idartist }) || getArtistInfos(state, { idartist })) return;
    dispatch(updateArtistInfos({ idartist, loading: true }));

    try {
      const data = await artistInfosService({ idartist });
      dispatch(updateArtistInfos({ idartist, loading: false, data }));
    } catch (err) {
      dispatch(updateArtistInfos({ idartist, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const UPDATE_ARTIST_OPTIONS = 'indexes/artist/UPDATE_ARTIST_OPTIONS';
export const updateArtistOptions = ({ idartist, currency, loading, data, error }) => ({
  type: UPDATE_ARTIST_OPTIONS,
  payload: {
    idartist,
    currency,
    loading,
    data,
    error,
  },
});
export function artistOptionsAction() {
  return async (dispatch, getState) => {
    const state = getState();
    if (isArtistOptionsLoading(state) || getArtistOptions(state) !== undefined) return;

    const idartist = getIdartist(state);
    const currency = getCurrency(state);

    if (!idartist || !currency) return;

    dispatch(updateArtistOptions({ idartist, currency, loading: true }));

    try {
      const data = await artistOptionsService({ idartist, idcurrency: CURRENCIES[currency].id });
      dispatch(updateArtistOptions({ idartist, currency, data, loading: false }));
    } catch (err) {
      dispatch(updateArtistOptions({ idartist, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const UPDATE_ARTIST_COUNTRIES = 'indexes/artist/UPDATE_ARTIST_COUNTRIES';
export const updateCountries = ({ idartist, loading, data, error }) => ({
  type: UPDATE_ARTIST_COUNTRIES,
  payload: {
    idartist,
    loading,
    data,
    error,
  },
});
export function fetchCountries() {
  return async (dispatch, getState) => {
    const state = getState();
    const idartist = getIdartist(state);
    if (!idartist) return;

    if (isCountriesLoading(state)) return;
    if (getCountries(state) !== undefined) return;
    dispatch(updateCountries({ idartist, loading: true }));

    try {
      const data = await indexesCountriesService({ idartist });
      dispatch(updateCountries({ idartist, loading: false, data }));
    } catch (err) {
      dispatch(updateCountries({ idartist, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const UPDATE_VISIBLE_YEARS = 'indexes/artist/UPDATE_VISIBLE_YEARS';
export const updateVisibleYears = ({ idartist, loading, data, error }) => ({
  type: UPDATE_VISIBLE_YEARS,
  payload: {
    idartist,
    loading,
    data,
    error,
  },
});
export function fetchVisibleYearsInCharts() {
  return async (dispatch, getState) => {
    const state = getState();
    const idartist = getIdartist(state);
    if (!idartist) return;

    if (isVisibleYearsLoading(state)) return;
    if (getVisibleYears(state) !== undefined) return;
    dispatch(updateVisibleYears({ idartist, loading: true }));

    try {
      const data = await visibleYearsService({ idartist });
      dispatch(updateVisibleYears({ idartist, loading: false, data }));
    } catch (err) {
      dispatch(updateVisibleYears({ idartist, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const UPDATE_DISPLAY = 'indexes/artist/UPDATE_DISPLAY';
export const updateDisplay = ({ idartist, loading, data, error }) => ({
  type: UPDATE_DISPLAY,
  payload: {
    idartist,
    loading,
    data,
    error,
  },
});
export function shouldDisplayAction({ idartist: optionalIdartist } = {}) {
  return async (dispatch, getState) => {
    const state = getState();

    const idartist = optionalIdartist || getIdartist(state);
    if (!idartist) return;

    if (isDisplayLoading(state, { idartist }) || getAllShouldDisplay(state, { idartist })) return;

    dispatch(updateDisplay({ idartist, loading: true }));
    try {
      const data = await shouldDisplayService({ idartist });
      dispatch(updateDisplay({ idartist, loading: false, data }));
    } catch (err) {
      dispatch(updateDisplay({ idartist, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}
