import { createNextState } from '@reduxjs/toolkit';
import { set, get } from 'lodash';
import { assignOnlyNewKeys } from '../../utils/object';
import { UPDATE_AUCTIONEERS } from '../actions/dbEntities/auctioneer';
import { UPDATE_ARTISTS, UPDATE_FREE_IDARTIST } from '../actions/dbEntities/artist';
import { UPDATE_LOTS } from '../actions/dbEntities/lot';
import { UPDATE_SALES } from '../actions/dbEntities/sale';
import { UPDATE_STORES } from '../actions/dbEntities/store';
import { UPDATE_CLASSIFIEDS } from '../actions/dbEntities/classified';
import { UPDATE_CATEGORIES } from '../actions/dbEntities/category';
import { UPDATE_TECHNIQUES } from '../actions/dbEntities/technique';
import { UPDATE_COUNTRIES } from '../actions/dbEntities/country';
import { UPDATE_ENTITY } from '../actions/dbEntities/entity';
import { UPDATE_LOTARTISTASSOCIATIONTYPES } from '../actions/dbEntities/lotartistassociationtype';

function getInitialState() {
  return {
    free: {},
    lots: {},
    sales: {},
    artists: {},
    classifieds: {},
    stores: {},
    auctioneers: {},
    categories: {},
    countries: {},
    techniques: {},
    messages: {},
    lotartistassociationtypes: {},
  };
}

export default function dbEntitiesReducer(state = getInitialState(), action) {
  const { type, payload } = action;
  const { data, error, loading, entityName, forceUpdate } = payload || {};

  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_FREE_IDARTIST: {
        set(draft, 'free.artist', { id: data, error, loading });
        break;
      }
      case UPDATE_LOTS: {
        let newData = get(draft, 'lots.data') || {};

        if (forceUpdate) {
          newData = { ...newData, ...data };
        } else {
          assignOnlyNewKeys(newData, data);
        }

        set(draft, 'lots', { data: newData, error, loading });
        break;
      }
      case UPDATE_SALES: {
        const newData = get(draft, 'sales.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'sales', { data: newData, error, loading });
        break;
      }
      case UPDATE_ARTISTS: {
        const newData = get(draft, 'artists.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'artists', { data: newData, error, loading });
        break;
      }
      case UPDATE_CLASSIFIEDS: {
        const newData = get(draft, 'classifieds.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'classifieds', { data: newData, error, loading });
        break;
      }
      case UPDATE_ENTITY: {
        let newData = get(draft, `${entityName}.data`) || {};

        if (forceUpdate) {
          newData = { ...newData, ...data };
        } else {
          assignOnlyNewKeys(newData, data);
        }

        set(draft, entityName, { data: newData, error, loading });
        break;
      }
      case UPDATE_STORES: {
        const newData = get(draft, 'stores.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'stores', { data: newData, error, loading });
        break;
      }
      case UPDATE_AUCTIONEERS: {
        const newData = get(draft, 'auctioneers.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'auctioneers', { data: newData, error, loading });
        break;
      }
      case UPDATE_CATEGORIES: {
        const newData = get(draft, 'categories.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'categories', { data: newData, error, loading });
        break;
      }
      case UPDATE_TECHNIQUES: {
        const newData = get(draft, 'techniques.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'techniques', { data: newData, error, loading });
        break;
      }
      case UPDATE_COUNTRIES: {
        const newData = get(draft, 'countries.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'countries', { data: newData, error, loading });
        break;
      }
      case UPDATE_LOTARTISTASSOCIATIONTYPES: {
        const newData = get(draft, 'lotartistassociationtypes.data') || {};
        assignOnlyNewKeys(newData, data);
        set(draft, 'lotartistassociationtypes', { data: newData, error, loading });
        break;
      }
      default:
        return draft;
    }
  });
}
