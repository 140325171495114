import { internalAxios } from '../../../utils/axios/internal';

export const getKnowledge = async ({ id }) => {
  const response = await internalAxios.get(`/api/artdbweb/artist/knowledge/${id}`);
  return response.data;
};

export const getTop10 = async () => {
  const response = await internalAxios.get(`/api/artdbweb/artist/top10`);
  return response.data;
};

export const getCollaborations = async ({ id }) => {
  const response = await internalAxios.get(`/api/artdbweb/artist/collaborations/${id}`);
  return response.data;
};

export const getCa = async ({ id }) => {
  const response = await internalAxios.get(`/api/artdbweb/artist/ca/${id}`);
  return response.data;
};

export const getFree = async () => {
  const response = await internalAxios.get(`/api/artdbweb/artist/free`);
  return response.data.artists[response.data.idartist[0]];
};
