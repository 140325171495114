import { count as countSelector } from '../../selectors/account/messages';
import { inbox, sent, trash, create as createService, markRead as markReadService, moveToTrash as moveToTrashService } from '../../../services/account/messages';
import { update } from '../dbEntities';

export const ACCOUNT_MESSAGES = 'account/ACCOUNT_MESSAGES';
export const updateAccountMessages = ({ result, error, loading }) => ({
  type: ACCOUNT_MESSAGES,
  result,
  error,
  loading,
});

export function fetch({ folder, page, perPage }) {
  return async dispatch => {
    dispatch(updateAccountMessages({ loading: true }));
    try {
      let serviceFunction;
      switch (folder) {
        case 'sent':
          serviceFunction = sent;
          break;
        case 'trash':
          serviceFunction = trash;
          break;
        default:
          serviceFunction = inbox;
      }

      const result = await serviceFunction(page, perPage);

      dispatch(updateAccountMessages({ result: { count: result.count, perPage: result.per_page, idmessages: result.messages.map(({ id }) => id) }, loading: false }));
      dispatch(update({ messages: result.messages.reduce((ac, cv) => ({ ...ac, ...{ [cv.id]: cv } }), {}) }));
    } catch (err) {
      dispatch(
        updateAccountMessages({
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  };
}

export function create({ message }) {
  return async dispatch => {
    const messages = await createService(message);

    dispatch(update({ messages }));
  };
}

export function markRead({ id }) {
  return async dispatch => {
    const messages = await markReadService(id);

    dispatch(update({ messages }, true));
  };
}

export const UPDATE_MESSAGE_COUNT = 'account/UPDATE_MESSAGE_COUNT';
export const updateMessageCount = ({ count }) => ({
  type: UPDATE_MESSAGE_COUNT,
  count,
});

export const DELETE_FROM_CURRENT_FORLDER = 'account/DELETE_FROM_CURRENT_FORLDER';
export const deleteFromCurrentFolder = ({ id }) => ({
  type: DELETE_FROM_CURRENT_FORLDER,
  id,
});

export function moveToTrash({ id }) {
  return async (dispatch, getState) => {
    const state = getState();
    const messages = await moveToTrashService(id);
    const count = countSelector(state);
    dispatch(deleteFromCurrentFolder({ id }));
    dispatch(updateMessageCount({ count: count - 1 }));
    dispatch(update({ messages }, true));
  };
}
