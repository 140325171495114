import { createNextState } from '@reduxjs/toolkit';
import { get, set, setWith } from 'lodash';
import {
  SAVE_COUNTRY_LABELS,
  SAVE_EVENT,
  STORE_EVENTS,
  SAVE_EVENTS_IDS_OF_TYPE,
  UPDATE_SELECTED_EVENT,
  UPDATE_WP_DATA,
  UPDATE_WP_MEDIA,
  UPDATE_WP_THUMBNAIL,
  SELECT_COUNTRY_FILTER,
  SELECT_DATES_FILTER,
  SAVE_TOTAL_COUNT,
  SELECT_PAGE_FILTER,
} from '../../actions/events';
import { getEventsFilters } from '../../selectors/events';
import { distinct } from '../../../utils/common/filters';

function getInitialState() {
  return {};
}

export default function eventsReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_SELECTED_EVENT:
        set(draft, 'details.selectedEvent', payload.id || get(state, 'details.selectedEvent'));
        break;

      case SELECT_COUNTRY_FILTER: {
        const { idcountry } = payload;
        set(draft, 'filters.country', idcountry);
        break;
      }

      case SELECT_DATES_FILTER: {
        const { from, to } = payload;
        set(draft, 'filters.dates', { from, to });
        break;
      }

      case SELECT_PAGE_FILTER: {
        const { page } = payload;
        set(draft, 'filters.page', page);
        break;
      }

      case SAVE_TOTAL_COUNT: {
        const { eventsType, totalCount } = payload;
        const fakeGlobalState = { events: state };
        set(draft, `${eventsType}.${getEventsFilters(fakeGlobalState)}.totalCount`, totalCount);
        break;
      }

      case STORE_EVENTS: {
        const { loading, error, data } = payload;
        const currentData = get(state, 'store.data');
        set(draft, 'store', {
          loading,
          error,
          data: { ...currentData, ...data },
        });
        break;
      }

      case SAVE_EVENT: {
        const { idevent, loading, error, data } = payload;
        setWith(
          draft,
          `details.${idevent}`,
          {
            loading,
            error,
            data,
          },
          Object,
        );
        break;
      }

      case SAVE_EVENTS_IDS_OF_TYPE: {
        const { ids = [], eventsType, loading, error } = payload;
        const fakeGlobalState = { events: state };
        const path = `${eventsType}.${getEventsFilters(fakeGlobalState)}`;
        const currentIds = get(state, `${path}.ids`) || [];

        const newIds = currentIds.concat(ids).filter(distinct);

        set(draft, path, {
          loading,
          error,
          ids: newIds,
        });
        break;
      }

      case UPDATE_WP_DATA: {
        const { lang, loading, error, data } = payload;
        set(draft, `details.${get(state, 'details.selectedEvent')}.WP/${lang}`, {
          loading,
          error,
          data,
        });
        break;
      }

      case UPDATE_WP_MEDIA: {
        const { idmedia, loading, error, data } = payload;
        setWith(
          draft,
          `medias.${idmedia}`,
          {
            loading,
            error,
            data,
          },
          Object,
        );
        break;
      }

      case UPDATE_WP_THUMBNAIL: {
        const { idmedia, loading, error, slug } = payload;
        setWith(
          draft,
          `medias.bySlug.${slug}`,
          {
            loading,
            error,
            idmedia,
          },
          Object,
        );
        break;
      }

      case SAVE_COUNTRY_LABELS: {
        const { idcountry, loading, error, data } = payload;
        setWith(
          draft,
          `countries.${idcountry}`,
          {
            loading,
            error,
            data,
          },
          Object,
        );
        break;
      }

      default:
        return draft;
    }
  });
}
