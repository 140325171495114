export const UPDATE_URL_PARAMS = 'search/UPDATE_URL_PARAMS';
/**
 * Dispatch du stockage des params de recherche dans le redux
 * @param params - params de la recherche
 * @param searchScope - scope de la recherche (lots, ...)
 * @returns {{payload: {params: *, searchScope: string}, type: string}}
 */
export const updateUrlParams = ({ params, searchScope }) => ({
  type: UPDATE_URL_PARAMS,
  payload: {
    params,
    searchScope,
  },
});
