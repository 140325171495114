// MUST be ordered in the same way ALLOWED_LANGUAGES (in ruby)
export const MANAGED_LOCALES = ['en', 'fr', 'de', 'it', 'es', 'zh'];

export const HOSTNAME_PREFIXES = {
  en: 'www',
  fr: 'fr',
  de: 'de',
  it: 'it',
  es: 'es',
  zh: 'zh',
};

export const LOCALE_BY_PREFIX = {
  www: 'en',
  fr: 'fr',
  de: 'de',
  it: 'it',
  es: 'es',
  zh: 'zh',
};

export const FLAGS = {
  en: 'gb',
  fr: 'fr',
  de: 'de',
  it: 'it',
  es: 'es',
  zh: 'cn',
};

export const LANGUAGES_RSX_KEY = {
  en: 'layouts.menu.m23',
  fr: 'layouts.menu.m22',
  de: 'layouts.menu.m24',
  it: 'layouts.menu.m26',
  es: 'layouts.menu.m25',
  zh: 'layouts.menu.m27',
};
