import { combineReducers } from 'redux';
import physicproductsReducer from './physicproducts';
import promotionsReducer from './promotions';
import settingsReducer from './settings';
import stripeReducer from './stripe';

export default combineReducers({
  promotions: promotionsReducer,
  physicproducts: physicproductsReducer,
  settings: settingsReducer,
  stripe: stripeReducer,
});
