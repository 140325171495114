import { createNextState } from '@reduxjs/toolkit';
import _ from 'lodash';
import { UPDATE_GEOGRAPHICAL_DATA, UPDATE_GEOGRAPHICAL_DISPLAYED_CHART_TAB, UPDATE_GEOGRAPHICAL_OPTIONS } from '../../actions/indexes/geographical';

function getInitialState() {
  return {};
}

export default function geographicalReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_GEOGRAPHICAL_OPTIONS:
        draft.options = { ...state.options, ...payload.options };
        break;

      case UPDATE_GEOGRAPHICAL_DISPLAYED_CHART_TAB:
        draft.displayedChartTab = payload.chartTab;
        break;

      case UPDATE_GEOGRAPHICAL_DATA: {
        const { idartist, currency, chartKey, loading, error, data } = payload;
        _.set(draft, `${idartist}.${currency}.${chartKey}`, {
          loading,
          error,
          data,
        });
        break;
      }

      default:
        return draft;
    }
  });
}
