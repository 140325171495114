import * as selectors from '../../selectors/dbEntities';
import * as services from '../../../services/dbEntities/technique';

export const UPDATE_TECHNIQUES = 'dbEntities/UPDATE_TECHNIQUES';
export const updateTechniques = ({ techniques, loading, error }) => ({
  type: UPDATE_TECHNIQUES,
  payload: {
    data: techniques,
    loading,
    error,
  },
});

export const fetchTechnique =
  ({ id }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (selectors.areTechniquesLoading(state) || selectors.getTechnique(state, { id })) return;
    try {
      dispatch(updateTechniques({ loading: true }));
      const techniques = await services.getSome([id]);

      dispatch(updateTechniques({ loading: false, techniques }));
    } catch (error) {
      dispatch(updateTechniques({ loading: false, error }));
    }
  };
