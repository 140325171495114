import { get } from 'lodash';
import { getCurrentScope } from './index';

export const getInfiniteScroll = (state, { searchScope }) => get(state.search, `info.${searchScope}.infiniteScroll`);
export const getCurrentInfiniteScroll = state => {
  const searchScope = getCurrentScope(state);
  return getInfiniteScroll(state, { searchScope });
};

export const getSort = (state, { searchScope }) => get(state.search, `info.${searchScope}.sort`);
export const getCurrentSort = state => {
  const searchScope = getCurrentScope(state);
  return getSort(state, { searchScope });
};

export const getPage = (state, { searchScope }) => get(state.search, `info.${searchScope}.page`);
export const getCurrentPage = state => {
  const searchScope = getCurrentScope(state);
  return getPage(state, { searchScope });
};

export const getDraftCount = (state, { searchScope }) => get(state.search, `info.${searchScope}.draftCount`);
export const getCurrentDraftCount = state => {
  const searchScope = getCurrentScope(state);
  return getDraftCount(state, { searchScope });
};

export const getCount = (state, { searchScope }) => get(state.search, `info.${searchScope}.count`);
export const getCurrentCount = state => {
  const searchScope = getCurrentScope(state);
  return getCount(state, { searchScope });
};
