/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { get, set, isEmpty, isPlainObject, isMatch } from 'lodash';
import { ensureCorrectScope } from '../../../utils/search';
import { fetchFacets as fetchFacetsService } from '../../../services/search';
import { EMPTY_OBJECT } from '../../../constants/utils';

/* params & facets types */
const BASE = 'BASE';
const DRAFT = 'DRAFT';
const ADVANCED = 'ADVANCED';
const TYPES = [BASE, DRAFT, ADVANCED];

function createEmptyScope() {
  return {
    [BASE]: {
      loading: false,
      loaded: false,
    },
    [DRAFT]: {
      loading: false,
      loaded: false,
    },
    [ADVANCED]: {
      loading: false,
      loaded: false,
    },
    options: EMPTY_OBJECT,
  };
}

const scopedSlice = createSlice({
  name: 'scoped',
  initialState: {
    scopes: {}, // will contains every scopes info
  },
  reducers: {
    // ⌄ Every actions below needs the searchScope ⌄
    clear(state, action) {
      const { searchScope } = action.payload;
      state.scopes[searchScope] = createEmptyScope();
    },
    prepare(state, action) {
      const { searchScope } = action.payload;
      if (isEmpty(get(state.scopes, searchScope, {}))) {
        state.scopes[searchScope] = createEmptyScope();
      } else {
        TYPES.forEach(type => {
          set(state.scopes, `${searchScope}.${type}.loading`, false);
          set(state.scopes, `${searchScope}.${type}.loaded`, false);
        });
        set(state.scopes, `${searchScope}.options`, EMPTY_OBJECT);
      }
    },
    setOptions(state, action) {
      const { searchScope, options } = action.payload;
      set(state.scopes, `${searchScope}.options`, options);
    },
    // ⌄ Every actions below needs the type too ⌄
    setLoading(state, action) {
      const { searchScope, type, loading = true } = action.payload;
      set(state.scopes, `${searchScope}.${type}.loading`, loading);
    },
    mergeParams(state, action) {
      const { searchScope, type, params = EMPTY_OBJECT } = action.payload;
      const existingParams = get(state.scopes, `${searchScope}.${type}.params`, {});
      set(state.scopes, `${searchScope}.${type}.params`, { ...existingParams, ...params });
    },
    setParams(state, action) {
      const { searchScope, type, params = EMPTY_OBJECT } = action.payload;
      set(state.scopes, `${searchScope}.${type}.params`, params);
    },
    setFacets(state, action) {
      const { searchScope, type, facets, count, pages, sort } = action.payload;
      set(state.scopes, `${searchScope}.${type}.facets`, facets);
      set(state.scopes, `${searchScope}.${type}.count`, Number.parseInt(count, 10) || 0); // fallback on 0
      set(state.scopes, `${searchScope}.${type}.pages`, Number.parseInt(pages, 10));
      set(state.scopes, `${searchScope}.${type}.sort`, sort);
    },
    setLoaded(state, action) {
      const { searchScope, type, loaded = true } = action.payload;
      set(state.scopes, `${searchScope}.${type}.loaded`, loaded);
      if (loaded) {
        set(state.scopes, `${searchScope}.${type}.loading`, false);
      }
    },
  },
});

/* Selectors */

const getOptions = (state, { searchScope }) => get(state.searchV2.scoped.scopes, `${searchScope}.options`);
const areFacetsLoading = (state, { searchScope, type }) => get(state.searchV2.scoped.scopes, `${searchScope}.${type}.loading`);
export const areBaseFacetsLoading = (state, { searchScope }) => areFacetsLoading(state, { searchScope, type: BASE });
export const areDraftFacetsLoading = (state, { searchScope }) => areBaseFacetsLoading(state, { searchScope }) || areFacetsLoading(state, { searchScope, type: DRAFT }); // if base facets are loading, draft will change too
const getCount = (state, { searchScope, type }) => get(state.searchV2.scoped.scopes, `${searchScope}.${type}.count`);
export const getDraftCount = (state, { searchScope }) => getCount(state, { searchScope, type: DRAFT });
export const getAdvancedCount = (state, { searchScope }) => getCount(state, { searchScope, type: ADVANCED });
const getParams = (state, { searchScope, type }) => get(state.searchV2.scoped.scopes, `${searchScope}.${type}.params`);
export const getBaseParams = (state, { searchScope }) => getParams(state, { searchScope, type: BASE });
export const getDraftParams = (state, { searchScope }) => getParams(state, { searchScope, type: DRAFT });
export const getAdvancedParams = (state, { searchScope }) => getParams(state, { searchScope, type: ADVANCED });
// will only work correctly while working with ONE searchScope
// it you ever need to work with multiple searchScopes, check this: https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
export const getEffectiveDraftParams = createSelector(getBaseParams, getDraftParams, (baseParams, draftParams) => ({
  ...baseParams,
  ...draftParams,
}));
// same as above
export const getEffectiveAdvancedParams = createSelector(getBaseParams, getAdvancedParams, (baseParams, advancedParams) => ({
  ...baseParams,
  ...advancedParams,
}));
// same
export const getAllParams = createSelector(getBaseParams, getAdvancedParams, (baseParams, advancedParams) => ({
  [BASE]: baseParams,
  [ADVANCED]: advancedParams,
  effective: {
    ...baseParams,
    ...advancedParams,
  },
}));
const getFacets = (state, { searchScope, type, facet }) => get(state.searchV2.scoped.scopes, facet ? `${searchScope}.${type}.facets.${facet}` : `${searchScope}.${type}.facets`);
export const getBaseFacets = (state, { searchScope, facet }) => getFacets(state, { searchScope, type: BASE, facet });
export const getDraftFacets = (state, { searchScope, facet }) => getFacets(state, { searchScope, type: DRAFT, facet });
export const getAdvancedFacets = (state, { searchScope, facet }) => getFacets(state, { searchScope, type: ADVANCED, facet });

/* Actions */

export const { clear, prepare, setOptions, setLoading, setLoaded, mergeParams, setParams, setFacets } = scopedSlice.actions;

/*
  This action will update the advanceParams (and consequently the facets)
  It will also set the draft params/facets to their corresponding advanced value
 */
export const updateAdvancedParams =
  ({ searchScope, params }) =>
  async (dispatch, getState) => {
    dispatch(setLoading({ searchScope, type: ADVANCED }));
    dispatch(setLoading({ searchScope, type: DRAFT }));

    const options = getOptions(getState(), { searchScope });

    dispatch(setParams({ searchScope, type: DRAFT, params }));
    dispatch(setParams({ searchScope, type: ADVANCED, params }));
    const effectiveAdvancedParams = getEffectiveAdvancedParams(getState(), { searchScope });
    const { facets, totalCount, totalPages, sort } = await fetchFacetsService(effectiveAdvancedParams, searchScope, options?.cancelPrevious);
    dispatch(setFacets({ searchScope, type: DRAFT, facets, count: totalCount, pages: totalPages, sort }));
    dispatch(setFacets({ searchScope, type: ADVANCED, facets, count: totalCount, pages: totalPages, sort }));

    dispatch(setLoaded({ searchScope, type: DRAFT }));
    dispatch(setLoaded({ searchScope, type: ADVANCED }));
  };

/*
  This actions will prepare the scope in case to function properly
 */
export const prepareScope = ({ searchScope, baseParams, advancedParams, options, clearScope = false }) => {
  ensureCorrectScope(searchScope);
  return async (dispatch, getState) => {
    if (clearScope) {
      dispatch(clear({ searchScope }));
    } else {
      dispatch(prepare({ searchScope }));
    }
    if (isPlainObject(options)) {
      dispatch(setOptions({ searchScope, options }));
    }
    dispatch(setLoading({ searchScope, type: BASE }));
    dispatch(setParams({ searchScope, type: BASE, params: baseParams }));
    // fetching baseFacets if we don't have them
    let baseFacets = getBaseFacets(getState(), { searchScope });
    let baseCount = -1;
    let basePages = -1;
    let baseSort;
    if (!baseFacets) {
      const { facets, totalCount, totalPages, sort } = await fetchFacetsService(baseParams, searchScope, options.cancelPrevious);
      baseFacets = facets;
      baseCount = totalCount;
      basePages = totalPages;
      baseSort = sort;
      dispatch(setFacets({ searchScope, type: BASE, facets: baseFacets, count: baseCount, pages: basePages, sort: baseSort }));
    }
    if (advancedParams) {
      await dispatch(updateAdvancedParams({ searchScope, params: advancedParams }));
    } else {
      dispatch(setFacets({ searchScope, type: DRAFT, facets: baseFacets, count: baseCount, pages: basePages, sort: baseSort }));
      dispatch(setFacets({ searchScope, type: ADVANCED, facets: baseFacets, count: baseCount, pages: basePages, sort: baseSort }));
    }
    dispatch(setLoaded({ searchScope, type: BASE }));
  };
};

/*
  This action will merge the new params into the existing draftParams
  It will also trigger the facet call and update the facets.

  lockedFacets can't be "reduced" by the facets you got.
  They can however be "augmented" if the new facet is a superset of the current facet.
 */
export const addToDraftParams =
  ({ searchScope, params, lockedFacets = [] }) =>
  async (dispatch, getState) => {
    if (areBaseFacetsLoading(getState(), { searchScope })) {
      console.warn('Trying to call addToDraftParams while the base facets are loading...');
      return;
    }

    // setup
    dispatch(setLoading({ searchScope, type: DRAFT }));
    dispatch(mergeParams({ searchScope, type: DRAFT, params }));
    const state = getState();
    // fetch facets
    const options = getOptions(state, { searchScope });
    const effectiveParams = getEffectiveDraftParams(state, { searchScope });
    try {
      const { facets, totalCount, totalPages, sort } = await fetchFacetsService(effectiveParams, searchScope, options.cancelPrevious);
      // keeping lockedFacets
      if (lockedFacets.length > 0) {
        lockedFacets.forEach(lockedFacet => {
          const existing = getDraftFacets(state, { searchScope, facet: lockedFacet });
          if (existing && isMatch(existing, facets[lockedFacet])) {
            facets[lockedFacet] = existing;
          }
        });
      }
      dispatch(setFacets({ searchScope, type: DRAFT, facets, count: totalCount, pages: totalPages, sort }));
      dispatch(setLoaded({ searchScope, type: DRAFT }));
    } catch (err) {
      if (!Axios.isCancel(err)) {
        throw err;
      }
    }
  };

export const updateDraftParams =
  ({ searchScope, params, lockedFacets = [] }) =>
  async (dispatch, getState) => {
    if (areBaseFacetsLoading(getState(), { searchScope })) {
      console.warn('Trying to call updateDraftParams while the base facets are loading...');
      return;
    }

    // setup
    dispatch(setLoading({ searchScope, type: DRAFT }));
    dispatch(setParams({ searchScope, type: DRAFT, params }));
    const state = getState();
    // fetch facets
    const options = getOptions(state, { searchScope });
    const effectiveParams = getEffectiveDraftParams(state, { searchScope });
    try {
      const { facets, totalCount, totalPages, sort } = await fetchFacetsService(effectiveParams, searchScope, options.cancelPrevious);
      // keeping lockedFacets
      if (lockedFacets.length > 0) {
        lockedFacets.forEach(lockedFacet => {
          const existing = getDraftFacets(state, { searchScope, facet: lockedFacet });
          if (existing) {
            facets[lockedFacet] = existing;
          }
        });
      }
      dispatch(setFacets({ searchScope, type: DRAFT, facets, count: totalCount, pages: totalPages, sort }));
      dispatch(setLoaded({ searchScope, type: DRAFT }));
    } catch (err) {
      if (!Axios.isCancel(err)) {
        throw err;
      }
    }
  };

/*
  Reset draft params = put advancedParams inside
 */
export const resetDraftParams =
  ({ searchScope }) =>
  async (dispatch, getState) => {
    const state = getState();
    const advancedParams = getAdvancedParams(state, { searchScope });
    dispatch(updateDraftParams({ searchScope, params: advancedParams }));
  };

export default scopedSlice.reducer;
