import { internalAxios } from '../../utils/axios/internal';

export const login = async params => {
  const response = await internalAxios.post('/api/wallet/customer/login?format=json', params);
  return response.data;
};

export const get = async params => {
  const response = await internalAxios.post('/api/wallet/customer/show?format=json', params);
  return response.data;
};

export const getAddresses = async params => {
  const response = await internalAxios.post('/api/wallet/address/get_customer_addresses?format=json', params);
  return response.data;
};

export const setAddress = async params => {
  const response = await internalAxios.post('/api/wallet/address/update_or_create?format=json', params);
  return response.data;
};

export const updateOrCreate = async params => {
  const response = await internalAxios.post('/api/wallet/customer/update_or_create?format=json', params);
  return response.data;
};

export const exixts = async params => {
  const response = await internalAxios.post('/api/wallet/customer/exu?format=json', params);
  return response.data;
};

export const hasUpgrades = async () => {
  const response = await internalAxios.get('/api/wallet/customer/has_upgrades?format=json');
  return response.data;
};
