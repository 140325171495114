import { createNextState } from '@reduxjs/toolkit';
import { UPDATE_VIEWPORT } from '../../actions/ui/viewport';

function getInitialState() {
  return {};
}

export default function viewportReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_VIEWPORT:
        return payload;
      default:
        return draft;
    }
  });
}
