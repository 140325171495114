import { get } from 'lodash';
import { createSelector } from 'reselect';
import { COUNTRY_REPARTITION_KEY, BEST_CLASSIFIEDS, VIEWED_CLASSIFIEDS } from '../../../constants/marketplace/store/stats';
import { formatFns } from '../../../../utils/dates/format';

export const getDisplayedPeriod = state => get(state.marketplace.stores.stats, 'displayedPeriod');

export const isCountryRepartitionLoading = state => get(state.marketplace.stores.stats, `${COUNTRY_REPARTITION_KEY}.loading`);
export const isCountryRepartitionLoaded = state => get(state.marketplace.stores.stats, `${COUNTRY_REPARTITION_KEY}.data`) || get(state.marketplace.stores.stats, `${COUNTRY_REPARTITION_KEY}.error`);
export const getCountryRepartition = state => get(state.marketplace.stores.stats, `${COUNTRY_REPARTITION_KEY}.data`);
export const getPercentageCountryRepartition = createSelector(getCountryRepartition, countryRepartition => {
  if (Array.isArray(countryRepartition)) {
    const total = countryRepartition.reduce((sum, { value }) => sum + value, 0);
    return countryRepartition.map(row => ({ ...row, value: row.value / total })).sort(({ value: valueA }, { value: valueB }) => valueB - valueA);
  }
  return countryRepartition;
});

export const isViewedClassifiedsLoading = state => get(state.marketplace.stores.stats, `${VIEWED_CLASSIFIEDS}.loading`);
export const isViewedClassifiedsLoaded = state => get(state.marketplace.stores.stats, `${VIEWED_CLASSIFIEDS}.data`) || get(state.marketplace.stores.stats, `${VIEWED_CLASSIFIEDS}.error`);
export const getViewedClassifieds = state => get(state.marketplace.stores.stats, `${VIEWED_CLASSIFIEDS}.data`);
export const getPeriods = createSelector(isViewedClassifiedsLoaded, getViewedClassifieds, (loaded, viewedClassifieds) => {
  if (loaded === undefined || viewedClassifieds === undefined) {
    return [];
  }
  return viewedClassifieds.filter(({ count }, index) => index === viewedClassifieds.length - 1 || count > 0).map(({ start, end }) => ({ start, end }));
});

export const hasViewedClassifiedsError = state => get(state.marketplace.stores.stats, `${VIEWED_CLASSIFIEDS}.error`) !== undefined;

export const createBestClassifiedsKey = ({ startDate, endDate }) => `${BEST_CLASSIFIEDS}.${startDate}-${endDate}`;
export const isBestClassifiedsLoading = (state, { startDate, endDate = formatFns(new Date(), 'yyyy-MM-dd') }) => get(state.marketplace.stores.stats, `${createBestClassifiedsKey({ startDate, endDate })}.loading`);
export const isBestClassifiedsLoaded = (state, { startDate, endDate = formatFns(new Date(), 'yyyy-MM-dd') }) =>
  get(state.marketplace.stores.stats, `${createBestClassifiedsKey({ startDate, endDate })}.data`) || get(state.marketplace.stores.stats, `${createBestClassifiedsKey({ startDate, endDate })}.error`);
export const getBestClassifieds = (state, { startDate, endDate = formatFns(new Date(), 'yyyy-MM-dd') }) => get(state.marketplace.stores.stats, `${createBestClassifiedsKey({ startDate, endDate })}.data`);
