import { updatePreferredCurrency as updatePreferredCurrencyService, updatePreferredDimensionUnit as updatePreferredDimensionUnitService } from '../../services/account';
import { updateArtistLotsOrder, updateHideArtistFollowRecommendationsModaleUntil as updateHideArtistFollowRecommendationsModaleUntilService } from '../../services/preferences';

export const UPDATE_FRESHNESS_PREFERENCE = 'UPDATE_FRESHNESS_PREFERENCE';
export function updateFreshnessPreference({ freshness }) {
  return {
    type: UPDATE_FRESHNESS_PREFERENCE,
    freshness,
  };
}

export const UPDATE_LANG_PREFERENCE = 'UPDATE_LANG_PREFERENCE';
export function updateLangPreference({ lang }) {
  return {
    type: UPDATE_LANG_PREFERENCE,
    lang,
  };
}

export const UPDATE_CURRENCY_PREFERENCE = 'UPDATE_CURRENCY_PREFERENCE';
export function updateCurrencyPreference({ currency }) {
  return {
    type: UPDATE_CURRENCY_PREFERENCE,
    currency,
  };
}

export function dispatchCurrencyPreference(currencyIso3) {
  return async dispatch => {
    dispatch(updateCurrencyPreference({ currency: currencyIso3 }));
    updatePreferredCurrencyService({ currency: currencyIso3 });
  };
}

export const UPDATE_DIMENSION_PREFERENCE = 'UPDATE_DIMENSION_PREFERENCE';
export function updateDimensionPreference({ dimension }) {
  return {
    type: UPDATE_DIMENSION_PREFERENCE,
    dimension,
  };
}

export function dispatchDimensionPreference({ dimensionUnit }) {
  return async dispatch => {
    dispatch(updateDimensionPreference({ dimension: dimensionUnit }));
    updatePreferredDimensionUnitService({ dimensionUnit });
  };
}

export const UPDATE_ITEMS_PER_PAGE_PREFERENCE = 'UPDATE_ITEMS_PER_PAGE_PREFERENCE';
export function updateItemsPerPage({ itemsPerPage }) {
  return {
    type: UPDATE_ITEMS_PER_PAGE_PREFERENCE,
    itemsPerPage,
  };
}

export const UPDATE_LOTS_VIEW_FORMAT_PREFERENCE = 'UPDATE_LOTS_VIEW_FORMAT_PREFERENCE';
export function updateLotsViewFormatPreference({ lotsViewFormat }) {
  return {
    type: UPDATE_LOTS_VIEW_FORMAT_PREFERENCE,
    lotsViewFormat,
  };
}

export const UPDATE_ARTISTS_VIEW_FORMAT_PREFERENCE = 'UPDATE_ARTISTS_VIEW_FORMAT_PREFERENCE';
export function updateArtistsViewFormatPreference({ artistsViewFormat }) {
  return {
    type: UPDATE_ARTISTS_VIEW_FORMAT_PREFERENCE,
    artistsViewFormat,
  };
}

export const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE';
export function updatePreference({ currency, lang, dimension, artistLotsSectionOrder, hideArtistFollowRecommendationsModaleUntil }) {
  return {
    type: UPDATE_PREFERENCE,
    currency,
    lang,
    dimension,
    artistLotsSectionOrder,
    hideArtistFollowRecommendationsModaleUntil,
  };
}

export function updateArtistLotsSectionOrder({ artistLotsSectionOrder }) {
  return async dispatch => {
    const { data } = await updateArtistLotsOrder({ artistLotsSectionOrder });
    dispatch(updatePreference({ artistLotsSectionOrder: data.preferences.artistLotsSectionOrder }));
  };
}

export function updateHideArtistFollowRecommendationsModaleUntil() {
  return async dispatch => {
    const { data } = await updateHideArtistFollowRecommendationsModaleUntilService();
    dispatch(updatePreference({ hideArtistFollowRecommendationsModaleUntil: data.preferences.hideArtistFollowRecommendationsModaleUntil }));
  };
}
