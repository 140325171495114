import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const promotionSlice = createSlice({
  name: 'promotion',
  initialState: {},
  reducers: {},
});

export const getAutomaticallyApplied = state => get(state.promotion, 'automaticallyApplied');
export const getCode = state => get(state.promotion, 'code');
export const getBannerUrl = (state, { size }) => get(state.promotion, `banners.${size}`);
export const getBannerUrlSml = state => getBannerUrl(state, { size: 'sml' });
// export const getBannerUrlLg = state => getBannerUrl(state, { size: 'lg' });
// export const getBannerUrlMd = state => getBannerUrl(state, { size: 'md' });
// export const getBannerUrlXl = state => getBannerUrl(state, { size: 'xl' });

export default promotionSlice.reducer;
