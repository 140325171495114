import { areEntitiesLoading, getEntity } from '../../selectors/dbEntities';
import { get } from '../../../services/dbEntities/entity';

export const UPDATE_ENTITY = 'dbEntities/UPDATE_ENTITY';
export const updateEntity = ({ data, entityName, loading, error, forceUpdate }) => ({
  type: UPDATE_ENTITY,
  payload: {
    data,
    forceUpdate,
    entityName,
    loading,
    error,
  },
});

export const fetchEntity =
  ({ id, entityName, fetchUrl }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (areEntitiesLoading(state, { entityName }) || getEntity(state, { entityName, id })) return;
    try {
      dispatch(updateEntity({ entityName, loading: true }));
      const data = await get(fetchUrl, id);
      dispatch(updateEntity({ entityName, loading: false, data }));
    } catch (error) {
      dispatch(updateEntity({ entityName, loading: false, error }));
    }
  };
