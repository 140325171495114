import { createNextState } from '@reduxjs/toolkit';
import { UPDATE_SUBSCRIPTIONS_STRIPE } from '../../actions/subscriptions/stripe';

function getInitialState() {
  return {};
}

export default function switchesReducer(state = getInitialState(), { type, payload }) {
  const { data } = payload || {};
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_SUBSCRIPTIONS_STRIPE: {
        return { ...draft, ...data };
      }
      default:
        return draft;
    }
  });
}
