import { createNextState } from '@reduxjs/toolkit';
import { BEGIN, END } from '../../actions/ui/asyncStatus';

function getInitialState() {
  return {};
}

export default function asyncStatusReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case BEGIN:
        draft[payload.id] = {
          loading: true,
        };
        break;
      case END:
        if (payload.error) {
          draft[payload.id] = {
            loading: false,
            error: payload.error,
          };
        } else {
          delete draft[payload.id];
        }
        break;
      default:
        return draft;
    }
  });
}
