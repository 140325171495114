import { internalAxios } from '../../../utils/axios/internal';

export const getCountryRepartition = async () => {
  const response = await internalAxios.get('/api/marketplace/stats/country_repartition');
  return response.data;
};

export const getBestClassifieds = async ({ startDate, endDate }) => {
  const response = await internalAxios.get(`/api/marketplace/stats/best_classifieds?start_date=${startDate}&end_date=${endDate}`);
  return response.data;
};

export const getInquiriesStats = async () => {
  const response = await internalAxios.get('/api/marketplace/stats/inquiries');
  return response.data;
};

export const getPublishedClassifieds = async () => {
  const response = await internalAxios.get('/api/marketplace/stats/published_classifieds');
  return response.data;
};

export const getReceivedMessages = async () => {
  const response = await internalAxios.get('/api/marketplace/stats/received_messages');
  return response.data;
};

export const getViewedClassifieds = async () => {
  const response = await internalAxios.get('/api/marketplace/stats/viewed_classifieds');
  return response.data;
};
