import { combineReducers } from 'redux';
import stores from './stores';
import manage from './manage';
import messages from './messages';

export default combineReducers({
  stores,
  manage,
  messages,
});
