import { createNextState } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { UPDATE_RANKING_DATA } from '../../actions/indexes/ranking';

function getInitialState() {
  return {};
}

export default function rankingReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_RANKING_DATA: {
        const { idartist, currency, loading, error, data } = payload;
        set(draft, `${idartist}.${currency}`, {
          loading,
          error,
          data,
        });
        break;
      }

      default:
        return draft;
    }
  });
}
