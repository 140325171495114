import { getIdartist } from '../../selectors/indexes/artist';
import { getCurrency } from '../../selectors/preferences';
import { CURRENCIES } from '../../../constants/preferences';

import { summaryIndexService } from '../../../services/indexes/summary';
import { isSummaryDataLoading } from '../../selectors/indexes/summary';

export const UPDATE_SUMMARY_DATA = 'indexes/summary/UPDATE_SUMMARY_DATA';
export const updateSummaryData = ({ idartist, currency, loading, data, error }) => ({
  type: UPDATE_SUMMARY_DATA,
  payload: {
    idartist,
    currency,
    loading,
    data,
    error,
  },
});
export function summaryDataAction() {
  return async (dispatch, getState) => {
    const state = getState();
    const idartist = getIdartist(state);
    const currency = getCurrency(state);
    if (!idartist || !currency) return;

    if (isSummaryDataLoading(state) !== undefined) return;

    dispatch(updateSummaryData({ idartist, currency, loading: true }));
    try {
      const data = await summaryIndexService({ idartist, idcurrency: CURRENCIES[currency].id });
      dispatch(updateSummaryData({ idartist, currency, data, loading: false }));
    } catch (err) {
      dispatch(updateSummaryData({ idartist, currency, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}
