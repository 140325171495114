import * as selectors from '../../selectors/dbEntities';
import * as services from '../../../services/dbEntities/lotartistassociationtype';

export const UPDATE_LOTARTISTASSOCIATIONTYPES = 'dbEntities/UPDATE_LOTARTISTASSOCIATIONTYPES';
export const updateLotartistassociationtypes = ({ lotartistassociationtypes, loading, error }) => ({
  type: UPDATE_LOTARTISTASSOCIATIONTYPES,
  payload: {
    data: lotartistassociationtypes,
    loading,
    error,
  },
});

export const fetchLotartistassociationtype =
  ({ id }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (selectors.areLotartistassociationtypesLoading(state) || selectors.getLotartistassociationtype(state, { id })) return;
    try {
      dispatch(updateLotartistassociationtypes({ loading: true }));
      const lotartistassociationtypes = await services.getSome([id]);

      dispatch(updateLotartistassociationtypes({ loading: false, lotartistassociationtypes }));
    } catch (error) {
      dispatch(updateLotartistassociationtypes({ loading: false, error }));
    }
  };
