import { SHORTCUT_SETTINGS_ARTISTS, SHORTCUT_SETTINGS_CATEGORIES, SHORTCUT_SETTINGS_SECTIONS } from '../../constants/myartprice';
import { internalAxios } from '../../utils/axios/internal';

export const updatePreferences = async (section, value, params = {}) => {
  let baseUrl = '/api/myartprice/settings/';
  switch (section) {
    case SHORTCUT_SETTINGS_ARTISTS:
      baseUrl = `${baseUrl}update_artist_include_in_push`;
      break;
    case SHORTCUT_SETTINGS_CATEGORIES:
      baseUrl = `${baseUrl}update_pushcategoryunsubscribe`;
      break;
    case SHORTCUT_SETTINGS_SECTIONS:
      baseUrl = `${baseUrl}update_pushunsubscribe`;
      break;
    default:
      throw new Error(`Unknown section : ${section}`);
  }

  const response = await internalAxios.post(baseUrl, { ...params, ...{ v: value } });
  return response.data;
};

export const fetchSettings = async () => {
  const response = await internalAxios.get('/api/myartprice/settings/get_settings');
  return response.data;
};

export const fetchCategoriesParams = async () => {
  const response = await internalAxios.get('/api/myartprice/settings/categories_settings');
  return response.data;
};
