import axios from 'axios';
import { getCurrentScope } from '../../selectors/search';
import { fetchFacetsAndResults, fetchResults as fetchResultsService } from '../../../services/search';
import { update } from '../dbEntities';
import { updateUrlFacets } from './urlFacets';
import { getInfiniteScroll } from '../../selectors/search/info';
import { updateCount, updateSort, updatePage } from './info';
import { updateDraftFacets } from './draftFacets';
import { updateDefaultFacets as updateDefaultFacetsAction } from './defaultFacets';

export const SET_LOADING_RESULTS = 'search/results/SET_LOADING_RESULTS';
export const setLoadingResults = ({ loading, searchScope }) => ({
  type: SET_LOADING_RESULTS,
  payload: {
    loading,
    searchScope,
  },
});

export const SET_RESULTS_DATA = 'search/results/SET_RESULTS_DATA';
const setResultsData = ({ results, loading = undefined, searchScope, infiniteScroll = false }) => ({
  type: SET_RESULTS_DATA,
  payload: {
    infiniteScroll,
    results,
    searchScope,
    loading,
  },
});

export const CLEAR_RESULTS_DATA = 'search/results/CLEAR_RESULTS_DATA';
export const clearResultsData = ({ searchScope }) => ({
  type: CLEAR_RESULTS_DATA,
  payload: {
    searchScope,
  },
});

export function clearCurrentResultsData() {
  return (dispatch, getState) => {
    const searchScope = getCurrentScope(getState());
    dispatch(clearResultsData({ searchScope }));
  };
}

export function fetchResults({ params, searchScope, updateFacets = false, updateDefaultFacets = false, cancelPrevious = false, flush = false, infiniteScroll = null, forceUpdate = false }) {
  return async (dispatch, getState) => {
    dispatch(setLoadingResults({ loading: true, searchScope }));
    if (flush) {
      dispatch(clearResultsData({ searchScope }));
    }
    try {
      let searchData = {};
      if (updateFacets) {
        searchData = await fetchFacetsAndResults(params, searchScope, cancelPrevious);
        dispatch(updateUrlFacets({ facets: searchData.facets, params, searchScope }));
        dispatch(updateDraftFacets({ facets: searchData.facets, params, searchScope }));
        if (updateDefaultFacets) {
          dispatch(updateDefaultFacetsAction({ facets: searchData.facets, params, searchScope }));
        }
      } else {
        searchData = await fetchResultsService(params, searchScope, cancelPrevious);
      }

      const { lots, sales, artists, classifieds, stores, auctioneers, categories, countries, techniques, lotartistassociationtypes, ...rest } = searchData.results;

      // update dataStore with entities
      dispatch(update({ lots, sales, artists, classifieds, stores, auctioneers, categories, countries, techniques, lotartistassociationtypes }, forceUpdate));
      dispatch(updateSort({ sort: searchData.sort, searchScope }));
      dispatch(updateCount({ count: searchData.totalCount, searchScope }));
      dispatch(updatePage({ page: params.p, searchScope }));

      // update results (can be custom)
      switch (searchScope) {
        default: {
          dispatch(setResultsData({ results: rest, searchScope, loading: false, infiniteScroll: infiniteScroll ?? getInfiniteScroll(getState(), { searchScope }) }));
        }
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e);
        dispatch(setLoadingResults({ loading: false, searchScope }));
      }
    }
  };
}

export function fetchCurrentResults({ params, infiniteScroll = null }) {
  return (dispatch, getState) => {
    const searchScope = getCurrentScope(getState());
    return dispatch(fetchResults({ params, searchScope, infiniteScroll }));
  };
}
