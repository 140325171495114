import { internalAxios } from '../utils/axios/internal';

export const updateIdprofessionaltype = async ({ idprofessionaltype }) => {
  const response = await internalAxios.get(`/api/wallet/customer/update_idprofessionaltype/${idprofessionaltype}`);
  return response.data;
};

export const updateTvaIntra = async ({ tvaintra }) => {
  const response = await internalAxios.post('/api/wallet/customer/update_tvaintra.json', { tvaintra });
  return response.data;
};

export const getReduxContext = async () => {
  const response = await internalAxios.get(`/api/wallet/customer/get_redux_context`);
  return response.data;
};

export const getMarketplaceContext = async () => {
  const response = await internalAxios.get(`/api/wallet/customer/get_marketplace_context`);
  return response.data;
};

export const getEstimateContext = async () => {
  const response = await internalAxios.get(`/api/wallet/customer/get_estimate_context`);
  return response.data;
};

export const fetchUserContext = async idcustomer => {
  const response = await internalAxios.get('/api/wallet/customer/get_user_context', idcustomer);
  return response.data;
};
