import { getCountry, areCountriesLoading } from '../../selectors/dbEntities';
import { getSome, getAll } from '../../../services/dbEntities/country';

export const UPDATE_COUNTRIES = 'dbEntities/UPDATE_COUNTRIES';
export const updateCountries = ({ countries, loading, error }) => ({
  type: UPDATE_COUNTRIES,
  payload: {
    data: countries,
    loading,
    error,
  },
});

export const fetchAllCountries = () => async (dispatch, getState) => {
  const state = getState();
  if (areCountriesLoading(state)) return;
  try {
    dispatch(updateCountries({ loading: true }));
    const countries = await getAll();

    dispatch(updateCountries({ loading: false, countries }));
  } catch (error) {
    dispatch(updateCountries({ loading: false, error }));
  }
};

export const fetchCountry =
  ({ id }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (areCountriesLoading(state) || getCountry(state, { id })) return;
    try {
      dispatch(updateCountries({ loading: true }));

      const countries = await getSome([id]);

      dispatch(updateCountries({ loading: false, countries }));
    } catch (error) {
      dispatch(updateCountries({ loading: false, error }));
    }
  };
