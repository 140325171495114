import { internalAxios } from '../../utils/axios/internal';

export const sendValidationEmail = async ({ backTo }) => {
  try {
    await internalAxios.post('/api/wallet/customer/send_validation_email', { bt: backTo });
  } catch (error) {
    console.error(error);
  }
};

export const setNewsletterSubscription = async ({ section, value }) => {
  try {
    await internalAxios.post('/api/wallet/customer/change_value_for_newsletter', { section, value, format: 'json' });
  } catch (error) {
    console.error(error);
  }
};

export const updatePreferredDimensionUnit = async ({ dimensionUnit }) => {
  const response = await internalAxios.post('/api/wallet/customer/update_preferred_dimensions_unit?format=json', { dimension_unit: dimensionUnit });
  return response;
};

export const updatePreferredCurrency = async ({ currency }) => {
  const response = await internalAxios.post('/api/wallet/customer/update_preferred_currency?format=json', { currency });
  return response;
};
