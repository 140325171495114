import { get as lodashGet } from 'lodash';
import { internalAxios } from '../../utils/axios/internal';

export const get = async ({ filter, image, promocode, quantity }) => {
  const response = await internalAxios.get('/api/wallet/physicproduct/list.json', { params: { filter, image, promocode, quantity } });
  return response.data;
};

export const getCart = async ({ type, image, promocode, idphysicproducts, idphysicsale }) => {
  try {
    const response = await internalAxios.get('/api/wallet/physicproduct/cart.json', { params: { type, image, promocode, idphysicproducts, idphysicsale } });
    return {
      status: 'ok',
      ...response.data,
    };
  } catch (e) {
    return {
      status: 'ko',
      ...lodashGet(e, 'response.data', {}),
    };
  }
};

export const productLegals = async ({ promocode, type, image, idphysicproducts }) => {
  const response = await internalAxios.get(`/api/wallet/physicproduct/cgv.json?promocode=${promocode}&image=${image}&type=${type}&idphysicproducts=${JSON.stringify(idphysicproducts)}`);
  return response.data;
};
