/*  eslint-disable */
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getCurrency } from '../preferences';
import { getIdartist } from './artist';

export const createChartKey = ({ chartTab, idcategory = '', idcountry = '', minPrice, maxPrice }) => `${chartTab}-${idcategory}-${idcountry}-${Number.parseInt(minPrice, 10) || ''}-${Number.parseInt(maxPrice, 10) || ''}`;

const getChronological = state => state.indexes.chronological;

export const getChronologicalDisplayedChartTab = state => state.indexes.chronological.displayedChartTab;
export const getChronologicalOptions = state => state.indexes.chronological.options;

// Chronological data
export const isChronologicalDataLoading = createSelector(
  getChronological,
  getIdartist,
  getCurrency,
  getChronologicalDisplayedChartTab,
  getChronologicalOptions,
  (chronological, idartist, currency, chartTab, options) => get(chronological, `${idartist}.${currency}.${createChartKey({ chartTab, ...options })}.loading`),
);

export const getChronologicalData = createSelector(
  getChronological,
  getIdartist,
  getCurrency,
  getChronologicalDisplayedChartTab,
  getChronologicalOptions,
  (chronological, idartist, currency, chartTab, options) => get(chronological, `${idartist}.${currency}.${createChartKey({ chartTab, ...options })}.data`),
);
