import { getIdartist } from '../../selectors/indexes/artist';
import { getRankingData, isRankingDataLoading } from '../../selectors/indexes/ranking';
import { getCurrency } from '../../selectors/preferences';
import { CURRENCIES } from '../../../constants/preferences';
import { rankService } from '../../../services/indexes/ranking';

export const UPDATE_RANKING_DATA = 'indexes/ranking/UPDATE_RANKING_DATA';
export const updateRankingData = ({ idartist, currency, loading, data, error }) => ({
  type: UPDATE_RANKING_DATA,
  payload: {
    idartist,
    currency,
    loading,
    data,
    error,
  },
});
export const fetchRankingData = () => async (dispatch, getState) => {
  const state = getState();

  if (isRankingDataLoading(state) || getRankingData(state) !== undefined) return;
  const idartist = getIdartist(state);
  const currency = getCurrency(state);

  if (!idartist || !currency) return;

  dispatch(updateRankingData({ idartist, currency, loading: true }));
  try {
    const data = await rankService({ idartist, idcurrency: CURRENCIES[currency].id });
    dispatch(updateRankingData({ idartist, currency, loading: false, data }));
  } catch (err) {
    dispatch(updateRankingData({ idartist, currency, loading: false, error: { message: err.message, stack: err.stack } }));
  }
};
