export const START_OF_MOUSE_HOVER_SELECTED_PARAMS = 'marketplace/searchbars/ui/START_OF_MOUSE_HOVER_SELECTED_PARAMS';
export const startOfMouseHoverSelectedParam = overflownParams => ({
  type: START_OF_MOUSE_HOVER_SELECTED_PARAMS,
  payload: {
    overflownParams,
  },
});

export const END_OF_MOUSE_HOVER_SELECTED_PARAMS = 'marketplace/searchbars/ui/END_OF_MOUSE_HOVER_SELECTED_PARAMS';
export const endOfMouseHoverSelectedParam = () => ({
  type: END_OF_MOUSE_HOVER_SELECTED_PARAMS,
});
