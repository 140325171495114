import { getIdartist, getArtistOptions } from '../../selectors/indexes/artist';
import { getCurrency } from '../../selectors/preferences';
import { CURRENCIES } from '../../../constants/preferences';

import { artistDataService, globalIndexDataService, sAndP500DataService, cac40DataService, daxDataService, categoriesDataService } from '../../../services/indexes/priceIndex';

import {
  createChartKey,
  defaultMinYear,
  isArtistIndexDataLoading,
  isStockIndexDataLoading,
  isCategoryDataLoading,
  getPriceIndexOptions,
  getArtistIndexData,
  getStockIndexData,
  getCategoryData,
  isComparedArtistIndexDataLoading,
  getComparedArtistIndexData,
  getComparedArtistName,
  shouldDisplayComparedArtist,
} from '../../selectors/indexes/priceIndex';
import { artistInfosAction, shouldDisplayAction } from './artist';

export const UPDATE_PRICE_INDEX_OPTIONS = 'indexes/priceIndex/UPDATE_PRICE_INDEX_OPTIONS';
export const updatePriceIndexOptions = ({ options }) => ({
  type: UPDATE_PRICE_INDEX_OPTIONS,
  payload: {
    options,
  },
});

export const UPDATE_PRICE_INDEX_ARTIST_DATA = 'indexes/priceIndex/UPDATE_ARTIST_DATA';
export const updatePriceIndexArtistData = ({ idartist, currency, loading, data, error }) => ({
  type: UPDATE_PRICE_INDEX_ARTIST_DATA,
  payload: {
    idartist,
    currency,
    loading,
    data,
    error,
  },
});
export function fetchArtistData({ idartist }) {
  return async (dispatch, getState) => {
    const state = getState();
    const currency = getCurrency(state);
    if (!currency) return;

    dispatch(updatePriceIndexArtistData({ idartist, currency, loading: true }));
    try {
      const data = await artistDataService({
        idartist,
        idcurrency: CURRENCIES[currency].id,
      });

      dispatch(updatePriceIndexArtistData({ idartist, currency, loading: false, data }));
    } catch (err) {
      dispatch(updatePriceIndexArtistData({ idartist, currency, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export function currentArtistDataAction() {
  return async (dispatch, getState) => {
    const state = getState();
    const idartist = getIdartist(state);
    if (!idartist || isArtistIndexDataLoading(state) || getArtistIndexData(state) !== undefined) return;
    await dispatch(fetchArtistData({ idartist }));
  };
}

export function comparedArtistDataAction() {
  return async (dispatch, getState) => {
    const state = getState();
    const { idartist } = getPriceIndexOptions(state) || {};
    if (!idartist) return;
    if (getComparedArtistName(state) === undefined) {
      dispatch(artistInfosAction({ idartist }));
    }
    if (shouldDisplayComparedArtist(state) === undefined) {
      dispatch(shouldDisplayAction({ idartist }));
    }

    if (isComparedArtistIndexDataLoading(state) || getComparedArtistIndexData(state) !== undefined) return;
    await dispatch(fetchArtistData({ idartist }));
  };
}

export const UPDATE_PRICE_INDEX_STOCK_INDEX_DATA = 'indexes/priceIndex/UPDATE_PRICE_INDEX_STOCK_INDEX_DATA';
export const updateStockIndexData = ({ idartist, currency, stockIndex, loading, data, error }) => ({
  type: UPDATE_PRICE_INDEX_STOCK_INDEX_DATA,
  payload: {
    idartist,
    currency,
    stockIndex,
    loading,
    data,
    error,
  },
});
export function stockIndexDataAction() {
  return async (dispatch, getState) => {
    const state = getState();
    const idartist = getIdartist(state);
    const currency = getCurrency(state);
    if (!currency) return;

    const { stockIndex } = getPriceIndexOptions(state) || {};
    if (!stockIndex) return;

    if (isStockIndexDataLoading(state) || getStockIndexData(state)) return;
    dispatch(updateStockIndexData({ idartist, currency, stockIndex, loading: true }));
    try {
      const options = {
        idartist,
        idcurrency: CURRENCIES[currency].id,
      };
      let data;
      switch (stockIndex) {
        case 'globalIndex':
          data = await globalIndexDataService(options);
          break;
        case 'sp500':
          data = await sAndP500DataService(options);
          break;
        case 'cac40':
          data = await cac40DataService(options);
          break;
        case 'dax':
          data = await daxDataService(options);
          break;
        default:
          throw new Error('Stock index not correctly defined');
      }

      dispatch(updateStockIndexData({ idartist, currency, stockIndex, data, loading: false }));
    } catch (err) {
      dispatch(updateStockIndexData({ idartist, currency, stockIndex, loading: false, error: { message: err.message, stack: err.stack } }));
    }
  };
}

export const UPDATE_PRICE_INDEX_CATEGORIES_DATA = 'indexes/priceIndex/UPDATE_PRICE_INDEX_CATEGORIES_DATA';
export const updateCategoriesData = ({ idcategory, idartist, currency, chartKey, loading, data, error }) => ({
  type: UPDATE_PRICE_INDEX_CATEGORIES_DATA,
  payload: {
    idcategory,
    idartist,
    currency,
    chartKey,
    loading,
    data,
    error,
  },
});
export function categoriesDataAction() {
  return async (dispatch, getState) => {
    const state = getState();
    const idartist = getIdartist(state);
    const currency = getCurrency(state);
    if (!currency) return;

    const { minYearIndex } = getArtistOptions(state) || {};
    const { idcategory, minYear = minYearIndex || defaultMinYear } = getPriceIndexOptions(state) || {};
    if (!idcategory) return;

    const chartKey = createChartKey({ minYear, state });

    if (!(isCategoryDataLoading(state, { idcategory }) || getCategoryData(state, { idcategory }))) {
      dispatch(updateCategoriesData({ idcategory, idartist, currency, chartKey, loading: true }));
      try {
        const data = await categoriesDataService({ idcategory, idartist, minYear, idcurrency: CURRENCIES[currency].id });
        dispatch(updateCategoriesData({ idcategory, idartist, currency, chartKey, data, loading: false }));
      } catch (err) {
        dispatch(updateCategoriesData({ idcategory, idartist, currency, chartKey, loading: false, error: { message: err.message, stack: err.stack } }));
      }
    }
  };
}
