import { getCurrentScope, getUrlParams } from '../../selectors/search';
import { getCount } from '../../selectors/search/info';
import { updateDraftCount } from './info';

export const RESET_DRAFT_PARAMS = 'search/RESET_DRAFT_PARAMS';
export const resetDraftParams = ({ initialParams, searchScope }) => ({
  type: RESET_DRAFT_PARAMS,
  payload: {
    initialParams,
    searchScope,
  },
});

function resetDraftParamsAction({ searchScope }) {
  return async (dispatch, getState) => {
    dispatch(resetDraftParams({ initialParams: getUrlParams(getState(), { searchScope }), searchScope }));
    dispatch(updateDraftCount({ count: getCount(getState(), { searchScope }), searchScope }));
  };
}

export function resetCurrentDraftParams() {
  return (dispatch, getState) => {
    const searchScope = getCurrentScope(getState());
    dispatch(resetDraftParamsAction({ searchScope }));
  };
}

export const UPDATE_DRAFT_PARAMS = 'search/UPATE_DRAFT_PARAMS';
export const updateDraftParams = ({ params, searchScope }) => ({
  type: UPDATE_DRAFT_PARAMS,
  payload: {
    params,
    searchScope,
  },
});

export function updateCurrentDraftParams(params) {
  return (dispatch, getState) => {
    const searchScope = getCurrentScope(getState());
    dispatch(updateDraftParams({ params, searchScope }));
  };
}
