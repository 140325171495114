import { isLoading } from '../../selectors/subscriptions/stripe';
import { getCustomer as getCustomerService } from '../../../services/subscriptions/stripe';

export const UPDATE_SUBSCRIPTIONS_STRIPE = 'subscriptions/UPDATE_SUBSCRIPTIONS_STRIPE';
export const updateStripe = ({ data, loading, error }) => ({
  type: UPDATE_SUBSCRIPTIONS_STRIPE,
  payload: {
    data,
    loading,
    error,
  },
});

export const getCustomer = () => async (dispatch, getState) => {
  const state = getState();
  if (isLoading(state)) return;
  try {
    dispatch(updateStripe({ loading: true }));
    const customer = await getCustomerService();

    dispatch(updateStripe({ loading: false, data: customer }));
  } catch (error) {
    dispatch(updateStripe({ loading: false, error }));
  }
};
