import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const footerSlice = createSlice({
  name: 'footer',
  initialState: {
    lastAmciValue: undefined,
  },
  reducers: {},
});

/* Selectors */

export const getAmciLastValue = state => get(state.footer, 'lastAmciValue', 0.0);

export default footerSlice.reducer;
