import { get } from 'lodash';

export const getTopStores = state => get(state.marketplace.stores.top, 'result');
export const getStore = (state, { idstore }) => get(state.marketplace.stores.list[idstore], 'store');
export const getStoreLoading = (state, { idstore }) => get(state.marketplace.stores.list[idstore], 'loading');
export const getErrorForStore = (state, { idstore }) => get(state.marketplace.stores.list[idstore], 'error');
export const getUpdateStoreImage = (state, { idstore, imageSha1 }) => {
  if (state.marketplace.stores.list[idstore].store.images) {
    const image = state.marketplace.stores.list[idstore].store.images.find(img => img.id === imageSha1);
    if (image) {
      return image.loading;
    }
  }
  return false;
};
export const getErrorCodeForStoreImage = (state, { idstore, imageSha1 }) => {
  if (state.marketplace.stores.list[idstore].store.images) {
    const image = state.marketplace.stores.list[idstore].store.images.find(img => img.id === imageSha1);
    if (image) {
      return image.errorCode;
    }
  }
  return null;
};
